const getHistory = (length = 6, min = 10, max = 100, round = true) => {
  const value = Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    return {
      date: (new Date(Date.now()-(864e5*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ? Math.round(value + i * value * 0.1) : Number.parseFloat((value + i * value * 0.1).toFixed(2)),
    };
  });
};

const baseInfluencer = {
  // "ava": require(`~/src/assets/mocks/`),
  "location": `Russia`,
  "language": `russian`,
  "verified": true,
  "email": `test@example.com`,
  "phone": `+79999999999`,
  "KYC": true,
  "customer": true
};

const influencer = () => {

};

export default [
  {
    ...baseInfluencer,
    "id": 6830197954,
    "ava": require(`~/src/assets/mocks/avatars/big/6830197954876276742.jpeg`),
    "username":`sukhanovandrey`,
    "name":`Суханов Андрей`,

    "views": 35465840,
    "prevviews": 35423840,
    "likes": 35465840,
    "prevlikes": 35425840,
    "followers": 2387010,
    "prevfollowers": 2384931,

    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "gender": `male`,

    "shares": `448974`,
    "videos": `4412`,
    "er": `56.02%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`372792200`,
    "prevviews":`372184100`,
    "fans":`1300000`,
    "prevfans":`1300000`,
    "id": 683019795487,
    "username":`hukutyh`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1665476946356230~c5_1080x1080.jpeg`,
    "name":`HuKuTyH`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`117857200`,
    "prevviews":`117822900`,
    "fans":`1100000`,
    "prevfans":`1100000`,
    "id": 6830197954871,
    "username":`iamkris`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/tos-maliva-avt-0068/f0b57065a7563d0af86670bffb1a5627~c5_1080x1080.jpeg`,
    "name":`Я Крис 💖 I AM KRIS`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`7613875`,
    "prevviews":`7623058`,
    "fans":`46900`,
    "prevfans":`46900`,
    "id":6830197954872,
    "username":`galatea_fitness`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1657502874437637~c5_1080x1080.jpeg`,
    "name":`Оксана Злепко`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`1796391`,
    "prevviews":null,
    "fans":`17900`,
    "prevfans":null,
    "id":6830197954873,
    "username":`letoile_official`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/3f52992533b7c50bed82defa9f4d714a~c5_1080x1080.jpeg`,
    "name":`Л'Этуаль`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`15170667`,
    "prevviews":`15172382`,
    "fans":`136700`,
    "prevfans":`136700`,
    "id":6830197954874,
    "username":`sber_ru`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1633409026227205~c5_1080x1080.jpeg`,
    "name":`Сбербанк`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`8942748`,
    "prevviews":`8953268`,
    "fans":`41500`,
    "prevfans":`41600`,
    "id":6830197954875,
    "username":`inamora.ru`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1655713905107973~c5_1080x1080.jpeg`,
    "name":`Юля Инамора`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`130101900`,
    "prevviews":`130032900`,
    "fans":`2200000`,
    "prevfans":`2200000`,
    "id":6830197954876,
    "username":`kobyakov_vlad`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    "name":`КОБЯКОВ`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`10602809`,
    "prevviews":`10602709`,
    "fans":`102000`,
    "prevfans":`102000`,
    "id":6830197954877,
    "username":`dbunbich`,
    "ava":`https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1653381580583942~c5_1080x1080.jpeg`,
    "name":`Десертный Бунбич`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`197800200`,
    "prevviews":`196976900`,
    "fans":`1400000`,
    "prevfans":`1400000`,
    "id":6830197954878,
    "username":`igorkorolyov`,
    "ava":`https://p16-va-tiktok.ibyteimg.com/img/musically-maliva-obj/1664427955082246~c5_1080x1080.jpeg`,
    "name":`ya Igor, privet ❤️`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  },
  {
    "graphs":{
      "avg_views":[20, 30, 26, 27, 44, 11, 37],
      "videos":[2, 3, 6, 7, 4, 1, 7]
    },
    "views":`192407000`,
    "prevviews":`192403300`,
    "fans":`1800000`,
    "prevfans":`1800000`,
    "id":6830197954879,
    "username":`leadpencil`,
    "ava":`https://p16-va-tiktok.ibyteimg.com/img/musically-maliva-obj/1660766500207621~c5_1080x1080.jpeg`,
    "name":`Lead Pencil`,
    "gender": `male`,
    "location": `Russia`,
    "language": `russian`,
    "verified": true,
    "email": `test@example.com`,
    "phone": `+79999999999`,
    "KYC": true,
    "customer": true,
    "followers": `400000`,
    "likes": 35265840,
    "prevlikes": 35665840,
    "shares": `448974`,
    "videos": `4412`,
    "er": `15%`,
    "gr": 9.6
  }
];
