const getHistory = (length = 6, min = 10, max = 100, round = true) => {
  const value = Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    return {
      date: (new Date(Date.now()-(864e5*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ? Math.round(value + i * value * 0.1) : Number.parseFloat((value + i * value * 0.1).toFixed(2)),
    };
  });
};

const getHistoryInterval = (length = 7, interval = 864e5, min = 10, max = 100, round = true) => {
  const value = min;//Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    return {
      date: (new Date(Date.now()-(interval*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ?
        Math.round(value + i * value * Math.random().toFixed(4) / 1000) :
        Number.parseFloat((value + i * value * Math.random().toFixed(4) / 1000).toFixed(2)),
    };
  });
};

const getAvgHistory = (length = 7, min = 10, max = 100, round = true) => {
  const value = Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    const signed = [-1,1][Math.random()*2|0];
    const step = (Math.random() * (0.5 - 0.01) + 0.01);
    return {
      date: (new Date(Date.now()-(864e5*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ? Math.round(value + (value * step * signed)) : Number.parseFloat((value + (value * step * signed)).toFixed(2)),
    };
  });
};

const getTimestamps = (length = 15) => {
  return Array.from(new Array(length), (v, i) => {
    return {
      timestamp: new Date(Date.now()-(504e5*(length-i*Math.random()*(10e5 - 1e5) + 1e5))).toJSON(), // `2019-12-21T14:06:06Z`,
    };
  });
};

module.exports = {
  // 6830197954876276742: {
  month: {
    rating: 9.6,
    performanceScore: 7.5,
    audience: {
      score: 40,
      history: getHistoryInterval(30, 864e5,223010280, 223040280)
    },
    videosFreq: {
      current: 2.9,
      average: 23.62,
      score: 60,
      history: getAvgHistory(4, 1, 3)
    },
    engagement: {
      current: 56.02,
      average: 14
    },
    genders: {
      average: 14,
      male: 56,
      female: 44
    },
    inact: {
      labels: [
        `Pet enthusiasts`,
        `Outdoor Enthusiast`,
        `Childrens Product Shoppers`,
        `Interest in Healthy Living`,
        `Travelers`,
        `Sport Fans`,
        `Investment Seekers`,
        `Home Entertainment Fans`,
      ],
      dataSets: [
        [8, 6, 10, 6, 7, 8, 10, 7],
        [7, 9, 10, 5, 5, 9, 7, 6],
      ],
      dataTypes: [`Interests`, `Activities`],
    },
    strengthAreas: [
      {
        label: `Growth potential`,
        value: 9.6,
        diff: 1.5,
        history: getAvgHistory(6, 0.5, 10, false)
      },
      {
        label: `Post frequency`,
        value: 8.3,
        diff: -1.1,
        history: getAvgHistory(6, 0.5, 10, false)
      },
      {
        label: `Engagement`,
        value: 8.4,
        diff: -1.1,
        history: getAvgHistory(6, 0.5, 10, false)
      },
      {
        label: `Challenges`,
        value: 2.9,
        diff: 1.5,
        history: getAvgHistory(6, 0.5, 10, false)
      },
      {
        label: `Conversion rate`,
        value: 2.4,
        diff: 1.5,
        history: getAvgHistory(6, 0.5, 10, false)
      },
      {
        label: `Impact`,
        value: 3.5,
        diff: -1.1,
        history: getAvgHistory(6, 0.5, 10, false)
      },
    ],
    challenges: {
      labels: [
        `#staysafe`,
        `#playfortheworld`,
        `#staysafe1`,
        `#playfortheworld1`,
        `#staysafe2`,
        `#playfortheworld2`,
      ],
      dataSets: [[10, 20, 50, 40, 30, 20]],
    },
    viral: {
      score: 60,
      labels: [`Likes`, `Comments`, `Shares`],
      dataSets: [
        [
          {
            x: 140000,
            y: 21000,
          },
          {
            x: 150000,
            y: 22000,
          },
          {
            x: 160000,
            y: 23000,
          },
        ],
        [
          {
            x: 110000,
            y: 26000,
          },
          {
            x: 120000,
            y: 24000,
          },
          {
            x: 130000,
            y: 22000,
          },
        ],
        [
          {
            x: 190000,
            y: 31000,
          },
          {
            x: 210000,
            y: 32000,
          },
          {
            x: 200000,
            y: 41000,
          },
        ],
      ],
    },
    likes: {
      score: 60,
      labels: [`Low`, `Under average`, `Average`, `Above average`, `High`],
      dataSets: [[10, 20, 50, 40, 30]],
    },
    ages: [
      {
        label: `18-24`,
        value: 56,
      },
      {
        label: `25-34`,
        value: 33,
      },
      {
        label: `35+`,
        value: 11,
      }
    ],
    postsPerTime: getTimestamps(30)
  },
  quater: {

  }
  // }
};
