const state = {
  mode: ``,
  payload: ``,
  open: false
};
const getters = {
  isModalOpen: state => {
    return state.open;
  },
  getModalMode (state) {
    return state.mode;
  },
  getModalPayload (state) {
    return state.payload;
  }
};
const mutations = {
  closeModal: state => {
    state.open = false;
    state.mode = ``;
    state.payload = ``;
    document.querySelector(`html`).classList.remove(`modal-document`);
    document.querySelector(`body`).classList.remove(`modal-document`);
  },
  openModal: (state, payload) => {
    state.open = true;
    state.mode = payload.mode;
    state.payload = payload.data;
    document.querySelector(`html`).classList.add(`modal-document`);
    document.querySelector(`body`).classList.add(`modal-document`);
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
