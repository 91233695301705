<template>
  <div
    :class="classes"
    :style="{
      marginLeft: `-${gutter / 2}px`,
      marginRight: `-${gutter / 2}px`,
      marginBottom: `${bottomOffset}px`,
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: `CRRow`,
  props: {
    type: {
      type: String,
      default: ``,
    },
    justify: {
      type: String,
      default: ``,
    },
    align: {
      type: String,
      default: ``,
    },
    gutter: {
      type: Number,
      default: 0,
    },
    bottomOffset: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    classes() {
      return [
        `cr-row`,
        this.type ? `cr-row-${this.type}` : ``,
        this.justify ? `cr-row-${this.justify}` : ``,
        this.align ? `cr-row-align-${(this.align)}` : ``,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.cr-row {
  &-flex {
    display: flex;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-space-around {
    justify-content: space-around;
  }
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-align {
    &-center {
      align-items: center;
    }
  }
}
</style>
