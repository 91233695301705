<template>
  <header class="header">
    <router-link :to="{ name: 'Dashboard' }" tag="div" class="header-logo">
      <img
        class="full-size-image"
        src="../../../assets/images/theme/logo.svg"
        alt=""
      />
    </router-link>
    <div class="divider"></div>
    <div class="header-nav">
      <highlight
        :width="highlightWidth"
        :offset-x="highlightOffset"
      ></highlight>
      <router-link
        v-for="(item, key) in routes"
        :key="key"
        ref="routerLink"
        :to="{ name: item.to }"
        tag="div"
        class="nav-item uppercase"
      >
        <span>
          {{ item.label }}
        </span>
        <span v-if="item.to === 'Messages'" class="amountLabel"> 0 </span>
      </router-link>
    </div>
    <div class="header_actions">
      <router-link tag="div" :to="{ name: 'Upgrade' }">
        <c-button class="header_actions-upgrade">
          {{ $t("layout.upgrade") }}
        </c-button>
      </router-link>
      <el-dropdown trigger="click" @command="handleDropdownCommand">
        <div class="el-dropdown-link medium">
          <img
            class="mr-5"
            src="../../../assets/images/components/header/hi.svg"
            alt=""
          />
          <span class="capital">{{ $t("layout.hello") }},</span> &nbsp;
          <span class="bold"> {{ $store.state.user.email }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="settings">
            <span class="capital">
              {{ $t("layout.settings") }}
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <span class="capital">
              {{ $t("layout.logout") }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import highlight from './highlight';
import CButton from '~/src/components/base/button';
export default {
  name: `TheHeader`,
  components: {
    highlight,
    CButton,
  },
  data() {
    return {
      highlightWidth: 0,
      highlightOffset: 20,
    };
  },
  computed: {
    routes() {
      return [
        {
          to: `Dashboard`,
          label: this.$t(`layout.dashboard`),
        },
        {
          to: `Discovery`,
          label: this.$t(`layout.discovery`),
        },
        {
          to: `Campaigns`,
          label: this.$t(`layout.campaigns`),
        },
        {
          to: `Tracking`,
          label: this.$t(`layout.tracking`),
        },
        {
          to: `Messages`,
          label: this.$t(`layout.messages`),
        },
      ];
    },
  },
  // TODO: remove when add this part of site
  watch: {
    $route() {
      const name = this.$route.name;
      this.$nextTick((_) => {
        this.calcHightlight();
      });
    },
  },
  mounted() {
    // TODO: remove when add this part of site
    const name = this.$route.name;
    document.querySelector(`#app`).classList.remove(`unauth`);
    this.$nextTick((_) => {
      setTimeout((_) => this.calcHightlight(), 400);
    });
  },
  methods: {
    calcHightlight() {
      this.$refs.routerLink.forEach((routeLink) => {
        if (routeLink.$el.classList.contains(`router-link-active`)) {
          this.highlightWidth = routeLink.$el.offsetWidth;
          this.highlightOffset = routeLink.$el.offsetLeft;
        }
      });
    },
    async handleDropdownCommand(command) {
      switch (true) {
      case command === `settings`:
        this.$router.push({ name: `Settings` });
        break;
      case command === `logout`:
        try {
          await this.$store.dispatch(`auth/logout`);
          this.$router.push({ name: `SignIn` });
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "~/src/assets/styles/variables";
.header {
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0 120px;
  width: 100%;
  height: 60px;
  background: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 8000;
  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    &-upgrade {
      margin-right: 15px;
    }
  }
  .header-logo {
    cursor: pointer;
    width: 100px;
    height: 30px;
  }
  .divider {
    margin: 0 60px;
    height: 15px;
    border-right: 1px solid $gray;
  }
  .header-nav {
    display: flex;
    height: 100%;
    position: relative;
    .nav-item {
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: center;
      margin: 0 20px;
      //padding: 0px 20px;
      border-bottom: 0 solid $pink;
      font-size: 13px;
      font-family: Muli-Bold, "Open Sans", sans-serif;
      font-weight: 800;
      color: $black;
      white-space: nowrap;
      transition: all linear 0.1s;
      &:hover {
        border-bottom: 2px solid $pink;
      }
      &.router-link-active {
        color: $pink;
      }
    }
  }
  .el-dropdown {
    margin-left: 20px;
    .el-dropdown-link {
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
}

.amountLabel {
  background-color: $pink;
  color: #ffffff;
  max-width: 20px;
  max-height: 20px;
  border-radius: 20px;
  margin-left: 10px;
  height: 100%;
  width: 20px;
  text-align: center;
  line-height: 20px;
}
</style>
