<template>
  <el-table
    ref="table"
    class="cr-table pt-20"
    style="width: 100%"
    :data="tableData"
    stripe
    row-class-name="cr-table-row"
    header-row-class-name="cr-table-header"
  >
    <el-table-column :label="$t('common.type')" min-width="100">
      <template slot-scope="data">
        <router-link :to="{ name: 'Campaign_view', params: { uuid: data.row.id } }">
          <div :class="getBrandClass(data.row.type)">
            {{ data.row.type_text }}
          </div>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column :label="$t('CampaignPages.campaign_name')" min-width="220">
      <template slot-scope="data">
        <campaign-name
          :title="data.row.campaign_name"
          :date="data.row.campaign_date"
          :messages="data.row.messages"
        ></campaign-name>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.status')"
      min-width="120"
    >
      <template slot-scope="data">
        <c-tag
          size="small"
          :type="$t('CampaignPages.statuses.' + data.row.status + '.type')"
        >
          <b>{{ $t("CampaignPages.statuses." + data.row.status + ".text") }}</b>
        </c-tag>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.posted')"
      min-width="100"
    >
      <template slot-scope="data">
        <div class="progress-wrapper">
          <c-progress-posted
            :percentage="data.row.posted.plan ? (data.row.posted.actual / data.row.posted.plan) * 100 : 0"
            :plan="data.row.posted.plan"
            :actual="data.row.posted.actual"
            :amount="data.row.posted.amount"
          ></c-progress-posted>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('CampaignPages.viral_status')"
      min-width="120"
    >
      <template slot-scope="data">
        <div class="progress-wrapper">
          <c-progress-viral
            :percentage="data.row.viral.plan ? (data.row.viral.actual / data.row.viral.plan) * 100 : 0"
          >
          </c-progress-viral>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.views')"
      min-width="120"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ data.row.views || 0 }}</span>
          <svg-icon
            v-if="data.row.prevviews"
            style="margin-left: 10px"
            :src="getArrow(data.row.prevviews, data.row.views)"
          ></svg-icon>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.comments')"
      min-width="120"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ data.row.comments || 0 }}</span>
          <svg-icon
            v-if="data.row.prevcomments"
            style="margin-left: 10px"
            :src="getArrow(data.row.prevcomments, data.row.comments)"
          ></svg-icon>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.roi')"
      min-width="70"
    >
      <template slot-scope="data">
        <div>{{ data.row.roi }}%</div>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      :label="$t('common.cpe')"
      min-width="70"
    >
      <template slot-scope="data">
        <div>{{ data.row.cpe }}%</div>
      </template>
    </el-table-column>
    <el-table-column
      align="right"
      header-align="right"
      :label="$t('CampaignPages.total_spend')"
      prop="name"
      min-width="110"
    >
      <template slot-scope="data">
        <div class="cr-table-cell">
          ${{ data.row.total_spend }}
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import CTag from '~/src/components/base/tag';
import cProgressPosted from './progressPosted';
import cProgressViral from './progressViral';
import campaignName from './campaignName';
import getValuedifference from '~/src/utils/getValueDifference';
export default {
  name: `TableShort`,
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    SvgIcon,
    CTag,
    campaignName,
    cProgressPosted,
    cProgressViral,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBrandClass(type) {
      let className = `brand_type brand_type-`;
      switch (type) {
      case 1:
        className += `pink`;
        break;
      case 2:
        className += `mint`;
        break;
      case 3:
        className += `orante`;
        break;
      }
      return className;
    },
    getArrow(prev, current) {
      const direction =
        this.getValuedifference(prev, current) < 0 ? `down` : `up`;
      return `theme/arrow_${direction}.svg`;
    },
    getValuedifference: getValuedifference,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-table {
  min-height: 135px;
  overflow: auto;
  margin-bottom: 20px;
  .brand_type {
    word-break: break-word;
    &-pink {
      color: $pink;
    }
    &-mint {
      color: $mint-dark;
    }
    &-orange {
      color: $orange-dark;
    }
  }
  &::before {
    background: none;
  }
  .progress-wrapper {
    display: flex;
    align-items: flex-start;
    min-height: 60px;
  }
  .percent-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
    img {
      width: 100%;
      max-width: 10px;
      margin-right: 5px;
    }
    .up {
      color: $green;
    }
    .down {
      color: $pink;
    }
  }
  /deep/ {
    .el-table {
      &__row {
        td {
          padding: 0px 0px;
        }
      }
    }
    .cr-table {
      &-header {
        th {
          padding: 18px 0;
          font-size: 15px;
          font-weight: normal;
          color: $gray;
          div.cell {
            white-space: nowrap;
            line-height: 1;
          }
        }
        td {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
