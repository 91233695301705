<template>
  <c-row type="flex">
    <div class="stat_wrapper stat_wrapper-overview">
      <statistic-bottle :percentage="5">
        <h3 class="stat-title">
          {{ $t("CampaignPages.view.overview.title") }}
        </h3>
        <c-row
          type="flex"
          align="center"
          justify="space-between"
          :bottom-offset="0"
        >
          <div class="stat_body">
            <div class="stat-item">
              {{ $t("CampaignPages.view.overview.posted") }}
            </div>
            <div class="stat-item">
              <b>
                {{ stat.overview.posted_actual }} {{ $t("common.of") }}
                {{ stat.overview.posted_plan }}
              </b>
            </div>
            <div class="stat-item">
              {{ stat.overview.total_media }}
              {{ $t("CampaignPages.view.overview.media_total") }}
            </div>
          </div>
          <div class="stat_body">
            <div class="stat-item">
              {{ $t("CampaignPages.view.overview.started") }}
            </div>
            <div class="stat-item">
              <b>
                {{ formatDate(stat.overview.started, false) }}
              </b>
            </div>
            <div class="stat-item">
              {{ $t("CampaignPages.view.overview.budget") }}
            </div>
            <div class="stat-item">
              <b> {{ stat.overview.budget }}$ </b>
            </div>
          </div>
        </c-row>
      </statistic-bottle>
    </div>
    <div class="stat_wrapper stat_wrapper-audience">
      <statistic-bottle :percentage="5">
        <h3 class="stat-title">
          {{ $t("CampaignPages.view.audience.title") }}
        </h3>
        <c-row type="flex" align="center" :bottom-offset="0" style="flex: 1">
          <div class="stat_body">
            <div class="stat-item">
              {{ $t("CampaignPages.view.audience.quality") }}
            </div>
            <div class="stat-item stat-item-big">
              <b>
                {{ formatAmount(stat.audience.quality) }}
              </b>
            </div>
          </div>
        </c-row>
      </statistic-bottle>
    </div>
    <div class="stat_wrapper stat_wrapper-engagement">
      <statistic-bottle :percentage="5">
        <h3 class="stat-title">
          {{ $t("CampaignPages.view.engagement.title") }}
        </h3>
        <c-row type="flex" align="center" :bottom-offset="0">
          <div class="stat_body">
            <div class="stat-item">
              {{ $t("common.total") }}
            </div>
            <div class="stat-item stat-item-big">
              {{
                formatAmount(
                  stat.engagement.likes +
                    stat.engagement.messages +
                    stat.engagement.shares
                )
              }}
            </div>
            <c-row type="flex" :bottom-offset="0">
              <c-row type="flex" :bottom-offset="0">
                <svg-icon src="icons/like.svg"></svg-icon>
                <div>{{ formatAmount(stat.engagement.likes) }}</div>
              </c-row>
              <c-row type="flex" :bottom-offset="0" style="margin-left: 5px">
                <svg-icon src="campaign/message.svg"></svg-icon>
                <div>{{ formatAmount(stat.engagement.messages) }}</div>
              </c-row>
              <c-row type="flex" :bottom-offset="0" style="margin-left: 5px">
                <svg-icon src="icons/share.svg"></svg-icon>
                <div>{{ formatAmount(stat.engagement.shares) }}</div>
              </c-row>
            </c-row>
          </div>
        </c-row>
      </statistic-bottle>
    </div>
    <div class="stat_wrapper stat_wrapper-spend">
      <statistic-bottle :percentage="5">
        <h3 class="stat-title">
          {{ $t("CampaignPages.view.spend.title") }}
        </h3>
        <c-row type="flex" align="center" :bottom-offset="0">
          <div class="stat_body">
            <div class="stat-item">
              {{ $t("common.cpe") }}
            </div>
            <div class="stat-item stat-item-big">
              <b> ${{ stat.spend.cpe }} </b>
            </div>
            <div class="stat-item">
              ${{ formatAmount(stat.spend.total) }} {{ $t("common.total") }}
            </div>
          </div>
        </c-row>
      </statistic-bottle>
    </div>
    <div class="stat_wrapper stat_wrapper-last">
      <statistic-bottle :percentage="5"></statistic-bottle>
    </div>
  </c-row>
</template>
<script>
import statisticBottle from './../components/statisticBottle';
import CRow from '~/src/components/base/utils/row';
import formatDate from '~/src/utils/formatDate';
import formatAmount from '~/src/utils/formatAmount';
import svgIcon from '~/src/components/base/utils/svgIcon';

export default {
  name: `CampaignStat`,
  components: {
    statisticBottle,
    CRow,
    svgIcon,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    stat() {
      return this.$store.state.campaigns.info.campaign;
    },
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.stat {
  &-item {
    &-big {
      font-size: 20px;
    }
  }
  &-title {
    margin: 0px;
  }
  &_body {
    line-height: 26px;
    font-size: $fontSize-medium;
  }
  &_wrapper {
    position: relative;
    padding: 0px 25px 10px 25px;
    border-right: 1px solid $gray-1;
    width: 100%;
    flex: 1;
    &-overview {
      min-width: 240px;
    }
    &-audience {
      min-width: 170px;
    }
    &-engagement {
      min-width: 240px;
    }
    &-spend {
      min-width: 200px;
    }
    &-last {
      border-right: 0px;
    }
  }
}
</style>
