<template>
  <div>
    <c-row>
      <c-radio-group v-model="checkedPeriod">
        <c-radio-button label="hours">
          {{ $t("dashboard.stat.hours") }}
        </c-radio-button>
        <c-radio-button label="week">
          {{ $t("dashboard.stat.week") }}
        </c-radio-button>
        <c-radio-button label="month">
          {{ $t("dashboard.stat.month") }}
        </c-radio-button>
      </c-radio-group>
    </c-row>
    <c-row>
      <c-card :title="$t('common.followers')">
        <chart-line
          :dates="chartData.dates"
          :data-sets="chartData.dataSet"
          :labels="chartData.labels"
          background
          smooth
          style="width: 100%; height: 180px"
        ></chart-line>
      </c-card>
    </c-row>
    <c-row>
      <the-gamefication
        :followers="parseInt(influencerInfo.followers)"
      ></the-gamefication>
    </c-row>
    <c-row type="flex" :gutter="30">
      <c-col :flex="1">
        <c-card
          :title="
            (averageViews.dataSet[0][averageViews.dataSet[0].length - 1] || 0) +
              ''
          "
          :subtitle="$t('dashboard.stat.views')"
        >
          <chart-line
            :dates="averageViews.dates"
            :data-sets="averageViews.dataSet"
            :labels="[$t('common.views')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="
            (averageLikes.dataSet[0][averageLikes.dataSet[0].length - 1] || 0) +
              ''
          "
          :subtitle="$t('dashboard.stat.likes')"
        >
          <chart-line
            :dates="averageLikes.dates"
            :data-sets="averageLikes.dataSet"
            :labels="[$t('common.likes')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="
            (agerageComments.dataSet[0][
              agerageComments.dataSet[0].length - 1
            ] || 0) + ''
          "
          :subtitle="$t('dashboard.stat.comments')"
        >
          <chart-line
            :dates="agerageComments.dates"
            :data-sets="agerageComments.dataSet"
            :labels="[$t('common.comments')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="
            (averageShares.dataSet[0][averageShares.dataSet[0].length - 1] ||
              0) + ''
          "
          :subtitle="$t('dashboard.stat.shares')"
        >
          <chart-line
            :dates="averageShares.dates"
            :data-sets="averageShares.dataSet"
            :labels="[$t('common.shares')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
    </c-row>
    <c-card :title="$t('tracking.statistics')">
      <c-table-stat :table-data="tableStat">
      </c-table-stat>
    </c-card>
  </div>
</template>
<script>
import CCard from './../components/card';
import ChartLine from '~/src/components/charts/lines';
import CRow from '~/src/components/base/utils/row';
import CCol from '~/src/components/base/utils/column';
import CTableStat from '~/src/views/Dashboard/components/stat_table';
import CRadioButton from '~/src/components/base/radio/radioButton';
import CRadioGroup from '~/src/components/base/radio/radioGroup';
import TheGamefication from './../components/gamefication';
import { mapGetters, mapState } from 'vuex';
export default {
  name: `Statistic`,
  components: {
    ChartLine,
    CCard,
    CRow,
    CCol,
    CTableStat,
    CRadioButton,
    CRadioGroup,
    TheGamefication,
  },
  data() {
    return {
      checkedPeriod: `week`,
    };
  },
  computed: {
    ...mapState({
      influencerInfo: (state) => state.dashboard.data,
    }),
    tableStat() {
      const d = [];
      this.followers.dates.forEach((item, idx) => {
        const obj = {
          time: item,
          followers: this.followers.dataSet[0][idx],
          avg_views: this.averageViews.dataSet[0][idx],
          avg_likes: this.averageLikes.dataSet[0][idx],
          avg_comments: this.agerageComments.dataSet[0][idx],
          avg_shares: this.averageShares.dataSet[0][idx],
        };
        d.push(obj);
      });

      return d.reverse();
    },
    ...mapGetters({
      chartData: `dashboard/getCommonLineStat`,
      followers: `dashboard/getFollowers`,
      averageViews: `dashboard/getAvgViews`,
      averageLikes: `dashboard/getAvgLikes`,
      agerageComments: `dashboard/getAvgComments`,
      averageShares: `dashboard/getAvgShares`,
    }),
  },
  watch: {
    checkedPeriod: function () {
      this.fetchData();
    },
  },
  mounted() {
    this.$nextTick((_) => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.$store.dispatch(`dashboard/fetchStat`, {
        id: this.$route.params.uuid,
        period: this.checkedPeriod,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
