/* eslint-disable */
const percent = (value, percent) => value * percent;
const videos = [{
  ava: `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
  date: new Date(Date.now()-9e7), //`2020-07-13T08:29:17.506Z`,
  username: `kobyakov_vlad`,
  views: 10602809,
  prevviews: 10602809,
  likes: 30435161,
  prevlikes: 29435161,
  comments: 151,
  prevcomments: 10400,
  link: ``,
  duets: [
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
  ],
  totalDuets: 14,
}];

const v = [
  {
    "id": "6923538109317516545",
    "desc": "Result in the next video😍 or already in my inst 😳 #tutorial #sukhanovandrey",
    "createTime": 1612011837,
    "video": {
      "id": "6923538109317516545",
      "height": 960,
      "width": 540,
      "duration": 23,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/af9c0cc246184d489f5753a4fe11682b?x-expires=1616691600&x-signature=Pwb0M660Z4rRbH8GRkNLESFSOaw%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/46898f6b817e46e7bf17e5a85cbf8e1c_1612011840?x-expires=1616691600&x-signature=88kNrCKw3eQTm5o6SuR93%2B%2Fj1Ec%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/f3bb42ad53a845939ac26ddd1b060343_1612011839?x-expires=1616691600&x-signature=NvIOr9jLvk74PK4iOHeVx1yW9YA%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/881b3fccf7ee49ed9cb3129aebf4b8dc/?a=1988&br=1710&bt=855&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692378&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=MzM6NHBudWhvMzMzOzczM0ApODk1NTY6NWVmNzM2PGk2Omdyby9iY2QwZl5gLS1iMTRzc2A2MDNhLmNiXzEyLWI0MWA6Yw%3D%3D&signature=f3aa0707039030ff51c61ddde4f670ba&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/881b3fccf7ee49ed9cb3129aebf4b8dc/?a=1988&br=1710&bt=855&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692378&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=MzM6NHBudWhvMzMzOzczM0ApODk1NTY6NWVmNzM2PGk2Omdyby9iY2QwZl5gLS1iMTRzc2A2MDNhLmNiXzEyLWI0MWA6Yw%3D%3D&signature=f3aa0707039030ff51c61ddde4f670ba&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/46898f6b817e46e7bf17e5a85cbf8e1c_1612011840~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=oQXHOvAhRwnB%2B9f5aUC4vCsJCzo%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/46898f6b817e46e7bf17e5a85cbf8e1c_1612011840~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=vjyjzDNNACWQiNRkqfUwSeUQ134%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/af9c0cc246184d489f5753a4fe11682b?x-expires=1616691600&x-signature=Pwb0M660Z4rRbH8GRkNLESFSOaw%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6764036183212165121",
      "title": "Dusk Till Dawn",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/4c42dcee0a44eadda68e0be9e83c8b85.m4a",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/a013a62ed2b340d1a566d4548d061e34.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/a013a62ed2b340d1a566d4548d061e34.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/a013a62ed2b340d1a566d4548d061e34.jpeg",
      "authorName": "Madilyn Bailey",
      "original": false,
      "duration": 60,
      "album": "Dusk Till Dawn"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 436900,
      "shareCount": 1206,
      "commentCount": 490,
      "playCount": 5900000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 52,
        "end": 61,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 62,
        "end": 77,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6922356935291440386",
    "desc": "Result! it is beautiful? #result #sukhanovandrey",
    "createTime": 1611736824,
    "video": {
      "id": "6922356935291440386",
      "height": 960,
      "width": 540,
      "duration": 7,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/46ed44364ded464b89bc32e8ad0b1a9d_1611736828?x-expires=1616691600&x-signature=AzFzEeX1%2BTkFjtOKy69bUzHcvdY%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/17d5966c60c442b1b149482a1e7f05ca_1611736827?x-expires=1616691600&x-signature=4iBS8vusMR0nkA76Lhdh5CIys2M%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/e293494599ff4bd4a56c0dddd66e1326_1611736828?x-expires=1616691600&x-signature=6QmJ%2BMR2sEzT4c1iReNPIO%2Fym%2Bg%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/f88e0e0cccc04dfc8e5a75070fc08b57/?a=1988&br=3042&bt=1521&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692362&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2kzcDZ2O3dsMzMzaDczM0ApODdkZThpOjs5Nzk8OGhoO2duYy1hM202YTVgLS0yMTRzczUxMmAwLi1hM2IzXy1fYTU6Yw%3D%3D&signature=6292cd92c042e5a226aa93be652200f7&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/f88e0e0cccc04dfc8e5a75070fc08b57/?a=1988&br=3042&bt=1521&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692362&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2kzcDZ2O3dsMzMzaDczM0ApODdkZThpOjs5Nzk8OGhoO2duYy1hM202YTVgLS0yMTRzczUxMmAwLi1hM2IzXy1fYTU6Yw%3D%3D&signature=6292cd92c042e5a226aa93be652200f7&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/17d5966c60c442b1b149482a1e7f05ca_1611736827~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=jnnf3G62O6yYg8jOwFVBVBzK7oQ%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/17d5966c60c442b1b149482a1e7f05ca_1611736827~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=FfBk9WShM8FGbP3Y5XlPB%2BmwNTo%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/46ed44364ded464b89bc32e8ad0b1a9d_1611736828?x-expires=1616691600&x-signature=M11dqcjWxt79olM%2BIfQ5GRCHVXs%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6881645240780950273",
      "title": "original sound",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6881645201622928130.mp3",
      "coverThumb": "https://p77-sign-sg.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_100x100.jpeg?x-expires=1616756400&x-signature=CEUV97t2d82l3LzZV%2F0a6n30goY%3D",
      "coverMedium": "https://p77-sign-sg.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_720x720.jpeg?x-expires=1616756400&x-signature=IzvX1TpF3t4UAU2W7JsM4LT95oA%3D",
      "coverLarge": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_1080x1080.jpeg?x-expires=1616756400&x-signature=NaPCnFpte75dCX%2Fq%2BcAHy%2BbK%2FiY%3D",
      "authorName": "Anna Catify",
      "original": true,
      "duration": 17,
      "album": ""
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 82200,
      "shareCount": 551,
      "commentCount": 236,
      "playCount": 1500000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 25,
        "end": 32,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 33,
        "end": 48,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6922080912142241025",
    "desc": "Do you want to see the result? 😍 хочешь результат? 😳 #tutorial #sukhanovandrey #fyp",
    "createTime": 1611672556,
    "video": {
      "id": "6922080912142241025",
      "height": 960,
      "width": 540,
      "duration": 15,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ffa338aca04c4bf68c2b36fbd79585cc?x-expires=1616691600&x-signature=zrj6ur5HP2HHxr8iGKHWmxUlsEs%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/2e61ef89062a456f9b449107179518df_1611672558?x-expires=1616691600&x-signature=1Y4aCpLdpnEljNtd20U1weaEsJ0%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c9f166f72b6d4d288db683be0417afa4_1611672558?x-expires=1616691600&x-signature=%2FbNzDZJJC1QZ32wzJlqOkZ4mfnA%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/dc26751dcc1d4fbd9baee6c5d3120bef/?a=1988&br=3214&bt=1607&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692370&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajo5NHBudXI1MzMzOzczM0ApZjxnNTM0N2U1N2k3O2Y2ZmdyYjNiY2RvazVgLS1iMTRzczBhXzViLjE2MjViX2FgLmM6Yw%3D%3D&signature=4818a7ccc857ccb795f05c95e88a1517&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/dc26751dcc1d4fbd9baee6c5d3120bef/?a=1988&br=3214&bt=1607&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692370&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajo5NHBudXI1MzMzOzczM0ApZjxnNTM0N2U1N2k3O2Y2ZmdyYjNiY2RvazVgLS1iMTRzczBhXzViLjE2MjViX2FgLmM6Yw%3D%3D&signature=4818a7ccc857ccb795f05c95e88a1517&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/2e61ef89062a456f9b449107179518df_1611672558~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=2mqFMaky8NuMKhCjYMTkvnrcxxI%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/2e61ef89062a456f9b449107179518df_1611672558~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=MNBHvRsQ8fyg2NYo6LPI31QO2b8%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ffa338aca04c4bf68c2b36fbd79585cc?x-expires=1616691600&x-signature=zrj6ur5HP2HHxr8iGKHWmxUlsEs%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6881645240780950273",
      "title": "original sound",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6881645201622928130.mp3",
      "coverThumb": "https://p77-sign-sg.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_100x100.jpeg?x-expires=1616756400&x-signature=CEUV97t2d82l3LzZV%2F0a6n30goY%3D",
      "coverMedium": "https://p77-sign-sg.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_720x720.jpeg?x-expires=1616756400&x-signature=IzvX1TpF3t4UAU2W7JsM4LT95oA%3D",
      "coverLarge": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/67ac7b389db87f25731d87eedbcbaed4~c5_1080x1080.jpeg?x-expires=1616756400&x-signature=NaPCnFpte75dCX%2Fq%2BcAHy%2BbK%2FiY%3D",
      "authorName": "Anna Catify",
      "original": true,
      "duration": 17,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "229207",
        "title": "fyp",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 32300,
      "shareCount": 42,
      "commentCount": 96,
      "playCount": 674000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 55,
        "end": 64,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 65,
        "end": 80,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 81,
        "end": 85,
        "hashtagName": "fyp",
        "hashtagId": "229207",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6916846336872631554",
    "desc": "Pt. 2 Thanks for 2 million ❤️ #sukhanovandrey #result",
    "createTime": 1610453787,
    "video": {
      "id": "6916846336872631554",
      "height": 960,
      "width": 540,
      "duration": 12,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c42f53b4d12e4f4cbf4e00792b3954f1?x-expires=1616691600&x-signature=tPg6CodYWMl6i0wUeyv%2F72w7dyY%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/72e9d43ac5ab4194a83bb48ba5668d8a_1610453790?x-expires=1616691600&x-signature=hbk1bUxbdrsfw1nxLVNB7JJrA%2B8%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/09574d103b3e4801bb80f92ef943ba97_1610453790?x-expires=1616691600&x-signature=oL4KitMbpJuHa2ZEJMjRkVP4B3o%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/6ed4e1040a5241629a04e71d0b9cf55e/?a=1988&br=1842&bt=921&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692366&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2dkPG84eThzeTMzZTczM0ApNWVpZjw3aWQzNzU1NDY2OGdvaWtgNW1zM3JfLS0wMTRzcy8zNDA1XzJfLzZjMWI2LS06Yw%3D%3D&signature=95fdaf5664b40f2aa5d5fbff666d2248&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/6ed4e1040a5241629a04e71d0b9cf55e/?a=1988&br=1842&bt=921&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692366&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2dkPG84eThzeTMzZTczM0ApNWVpZjw3aWQzNzU1NDY2OGdvaWtgNW1zM3JfLS0wMTRzcy8zNDA1XzJfLzZjMWI2LS06Yw%3D%3D&signature=95fdaf5664b40f2aa5d5fbff666d2248&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/72e9d43ac5ab4194a83bb48ba5668d8a_1610453790~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=R%2Bd7m5%2FwrCz%2BNDBY%2B4KZCCUNyeE%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/72e9d43ac5ab4194a83bb48ba5668d8a_1610453790~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=zwVs4Qn9nXtgKrZM%2FM7A6VY81Gc%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c42f53b4d12e4f4cbf4e00792b3954f1?x-expires=1616691600&x-signature=tPg6CodYWMl6i0wUeyv%2F72w7dyY%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6842582526297393154",
      "title": "How You Like That",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/03461b33e4aff4360ba3c0ff9155744b.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "authorName": "BLACKPINK",
      "original": false,
      "duration": 31,
      "album": ""
    },
    "challenges": [
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 41300,
      "shareCount": 63,
      "commentCount": 78,
      "playCount": 558500
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 30,
        "end": 45,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 46,
        "end": 53,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6916552499075763458",
    "desc": "Что бы увидеть результат, нажми дуэт+реверс! 🤫😍🔥 #sukhanovandrey #tutorial #2M",
    "createTime": 1610385373,
    "video": {
      "id": "6916552499075763458",
      "height": 960,
      "width": 540,
      "duration": 16,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/115c3cbf1d7f4baf9ae0b3276b69c204?x-expires=1616691600&x-signature=piStmZGhIhupkkgQ8ATaFkdHsPQ%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/52ad49432aec432f931ba9f05c037715_1610385376?x-expires=1616691600&x-signature=j80Wpt23J%2Bso4U7Q2Dy2YYzRB9o%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/933c44f443c444d6a2e3ed4cedd73776_1610385375?x-expires=1616691600&x-signature=%2BYMXlaLHzIuA7moDXlVjcVoOqnw%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/0652ed8df4ac43b1aaa399579526f99f/?a=1988&br=1728&bt=864&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2hkM2w4eW87eTMzNDczM0ApZzU5aWU0ODs8Nzo8NzhkOmcxNi4yNW1rY3JfLS1iMTRzcy1iMTQ0MTUzYTJjYDReL2I6Yw%3D%3D&signature=0ce20f38c3f4bcbd2e7a17133e80f32f&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/0652ed8df4ac43b1aaa399579526f99f/?a=1988&br=1728&bt=864&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2hkM2w4eW87eTMzNDczM0ApZzU5aWU0ODs8Nzo8NzhkOmcxNi4yNW1rY3JfLS1iMTRzcy1iMTQ0MTUzYTJjYDReL2I6Yw%3D%3D&signature=0ce20f38c3f4bcbd2e7a17133e80f32f&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/52ad49432aec432f931ba9f05c037715_1610385376~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=TrmTTjFGJ8KWb4WDO7%2FEqy2FpmY%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/52ad49432aec432f931ba9f05c037715_1610385376~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=KqYYU6f5mO%2BY8Y41vc3wxAglEFE%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/115c3cbf1d7f4baf9ae0b3276b69c204?x-expires=1616691600&x-signature=ys8t5LmGL5cAz%2Fl5PAZI5Cqc3Iw%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6916552491194698497",
      "title": "original sound",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6916552441764940545.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 16,
      "album": ""
    },
    "challenges": [
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "4476300",
        "title": "2m",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 24100,
      "shareCount": 52,
      "commentCount": 43,
      "playCount": 360600
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 52,
        "end": 67,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 68,
        "end": 77,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 78,
        "end": 81,
        "hashtagName": "2m",
        "hashtagId": "4476300",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "stickersOnItem": [
      {
        "stickerType": 4,
        "stickerText": [
          "Reverse\nthe clip ",
          "To see the result use duet+reverse!"
        ]
      }
    ],
    "isAd": false
  },
  {
    "id": "6915767402034760962",
    "desc": "Pt. 2 THANK YOU FOR 2M 😍 Result of the previous video 🔥 check the tutorial 🙋🏻‍♂️ #sukhanovandrey #fyp",
    "createTime": 1610202577,
    "video": {
      "id": "6915767402034760962",
      "height": 960,
      "width": 540,
      "duration": 9,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/7ab4df8d72cb48fc8ffb2e8935795904?x-expires=1616691600&x-signature=aNp9g9N5deH5NxpiIG3IoCHbMAs%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ed99c96eb65b4fbd90e878957e865ec1_1610202579?x-expires=1616691600&x-signature=2bNWxEB1qCc9LPgy6JoQzcm8XTc%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a52a67f51955421485a8f39ed73322a4_1610202580?x-expires=1616691600&x-signature=%2FLua0FTlegnm8AAOYIzROgnSYhY%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/eb51d86f3cf44c09a928dd3f35457300/?a=1988&br=2242&bt=1121&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2tqOjk4ZDx1eTMzOjczM0ApNjQ8Zzk2ZDxnN2g4ZWlpaWdlaWA0LTFvb3BfLS1gMTRzc2AvXi4uXl9iMDIwNmIzYF46Yw%3D%3D&signature=6816452d86ee89a84e3a833cb62d644f&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/eb51d86f3cf44c09a928dd3f35457300/?a=1988&br=2242&bt=1121&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2tqOjk4ZDx1eTMzOjczM0ApNjQ8Zzk2ZDxnN2g4ZWlpaWdlaWA0LTFvb3BfLS1gMTRzc2AvXi4uXl9iMDIwNmIzYF46Yw%3D%3D&signature=6816452d86ee89a84e3a833cb62d644f&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/ed99c96eb65b4fbd90e878957e865ec1_1610202579~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=Hz2P%2BkGWPkbOGUB40RhuKivL74U%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/ed99c96eb65b4fbd90e878957e865ec1_1610202579~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=0FjaEbEfcstg976GCrMPerMe%2FfA%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/7ab4df8d72cb48fc8ffb2e8935795904?x-expires=1616691600&x-signature=tX3Gs49NlXk1WeakPYWW2F%2Bwxpo%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6842582526297393154",
      "title": "How You Like That",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/03461b33e4aff4360ba3c0ff9155744b.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "authorName": "BLACKPINK",
      "original": false,
      "duration": 31,
      "album": ""
    },
    "challenges": [
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "229207",
        "title": "fyp",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 36100,
      "shareCount": 34,
      "commentCount": 84,
      "playCount": 443600
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 85,
        "end": 100,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 101,
        "end": 105,
        "hashtagName": "fyp",
        "hashtagId": "229207",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6915413242277891329",
    "desc": "Pt. 1 It's incredible! Do you want the RESULT? 😍 #tutorial #sukhanovandrey",
    "createTime": 1610120118,
    "video": {
      "id": "6915413242277891329",
      "height": 960,
      "width": 540,
      "duration": 14,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/b96a07c631904198a943660eefed53e5?x-expires=1616691600&x-signature=zdPK3llTQZGkdu3nT0dHiu9SQoo%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/3eb7e3b63e204ac6853cd9f781240ff8_1610120121?x-expires=1616691600&x-signature=RvTQ%2FXZQUociPpgLqFIULj1csWE%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/892044c342d244a4ae6c3f8011d73663_1610120120?x-expires=1616691600&x-signature=tG%2FQrKgpDsBWVYYRbixVW899lqQ%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/cc958760099a45c2ba56251ddbf00e3d/?a=1988&br=1868&bt=934&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692369&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3FwbGo2ODo6eTMzZjczM0ApZTo6PDpkNWRnNzxmOmk0O2dvc2lyMTQza3BfLS0zMTRzcy8zMTM2M2BgLjEtLS9eYl46Yw%3D%3D&signature=f86f28e8e576d5604d7a0a06f4f1b100&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/cc958760099a45c2ba56251ddbf00e3d/?a=1988&br=1868&bt=934&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692369&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3FwbGo2ODo6eTMzZjczM0ApZTo6PDpkNWRnNzxmOmk0O2dvc2lyMTQza3BfLS0zMTRzcy8zMTM2M2BgLjEtLS9eYl46Yw%3D%3D&signature=f86f28e8e576d5604d7a0a06f4f1b100&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/3eb7e3b63e204ac6853cd9f781240ff8_1610120121~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=QvhX2jLc3xijwqhkIVSru11Ai7Y%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/3eb7e3b63e204ac6853cd9f781240ff8_1610120121~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=p4hrH2INlf0Ie5B%2BoRsTtuC3DuI%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/b96a07c631904198a943660eefed53e5?x-expires=1616691600&x-signature=FAp9gCplB1L73E9BZf0LyxRpR0c%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6915413215971429121",
      "title": "оригинальный звук",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6915413196099275521.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 14,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 182200,
      "shareCount": 417,
      "commentCount": 226,
      "playCount": 3200000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 50,
        "end": 59,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 60,
        "end": 75,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6903150509301976322",
    "desc": "Magic trick 🤯 idea: @dashachepizhko  #tutorial #sukhanovandrey",
    "createTime": 1607264978,
    "video": {
      "id": "6903150509301976322",
      "height": 960,
      "width": 540,
      "duration": 23,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/f985ada6a9a0438da84af1290ab4f52d?x-expires=1616691600&x-signature=FyCl1bT6%2BPcUK5I51cI%2FG3faYp8%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ba1931da97754ce98e39b8d94193a8e1_1607265180?x-expires=1616691600&x-signature=vKIGhB8075udkqYCeDYWlLwI6Ks%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/3fcd913b5cde41f8b67dbca1f0985faa_1607265190?x-expires=1616691600&x-signature=Tv0SQ1GJ7jAaDVldf4nA03er04c%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/0a7ecea0b5a24741bdb03cf8168f3ec2/?a=1988&br=3112&bt=1556&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692378&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anlnczRsOGxoeTMzZzczM0ApOzMzNGhmaDtnNzU4Ojs1NGc0ZXNqZ20uaTNfLS0wMTRzc2M1XzQ0NGIuLTUuMjIuMy06Yw%3D%3D&signature=26f0a47c8ab948243784033977070a97&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/0a7ecea0b5a24741bdb03cf8168f3ec2/?a=1988&br=3112&bt=1556&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692378&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anlnczRsOGxoeTMzZzczM0ApOzMzNGhmaDtnNzU4Ojs1NGc0ZXNqZ20uaTNfLS0wMTRzc2M1XzQ0NGIuLTUuMjIuMy06Yw%3D%3D&signature=26f0a47c8ab948243784033977070a97&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/ba1931da97754ce98e39b8d94193a8e1_1607265180~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=0qFH4VsFP22SE0rz4PCqvYtC6m0%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/ba1931da97754ce98e39b8d94193a8e1_1607265180~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=0CyYP4oGHtlbwlivz1oS%2BJpOAn0%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/f985ada6a9a0438da84af1290ab4f52d?x-expires=1616691600&x-signature=w493DXVnzt9ISYAlv%2FvADqUS0vY%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6800996740322297858",
      "title": "Savage",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/77fbf27f7f18df0d24e8820692d1102b.m4a",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/7430b40854434b41acc0088c0b6d2e2e.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/7430b40854434b41acc0088c0b6d2e2e.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/7430b40854434b41acc0088c0b6d2e2e.jpeg",
      "authorName": "Megan Thee Stallion",
      "original": false,
      "duration": 60,
      "album": "Suga"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 111600,
      "shareCount": 221,
      "commentCount": 105,
      "playCount": 1900000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 21,
        "end": 36,
        "hashtagName": "",
        "hashtagId": "",
        "type": 0,
        "userId": "6777370044901999622",
        "isCommerce": false,
        "userUniqueId": "dashachepizhko",
        "secUid": "MS4wLjABAAAA7XMxl99dxeLRlV5PbICoCzelZ-4PulgbzzYh9X67Nq2Y-ePRn_Vm7INoYv4JAgFz"
      },
      {
        "awemeId": "",
        "start": 38,
        "end": 47,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 48,
        "end": 63,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6902370235387612417",
    "desc": "The result you were waiting for, magic cup 😳 волшебная кружка 🤯 #result",
    "createTime": 1607083306,
    "video": {
      "id": "6902370235387612417",
      "height": 960,
      "width": 540,
      "duration": 10,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/2fd93300d81a44a29a4d7e86dbf0c195?x-expires=1616691600&x-signature=BZE3Lkx3j9JuDms8GHxmGyABayA%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/b9061b454889480a84620d49524d421d_1607083309?x-expires=1616691600&x-signature=n7lbl8aptUhCBrsmzBREwMtMdps%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/dc01fc8d72d940d29a37b257bba0a2c6_1607083309?x-expires=1616691600&x-signature=G2eWghE12MBKGdhroDA3PYHiSPg%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/ca5285b7fcfc496ea7fea88b6e29fb4a/?a=1988&br=2736&bt=1368&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amRtczRsODw1eTMzZzczM0ApM2lnPDs8Zzs6NzxpOzw4N2cxMG9qZ202XjJfLS0wMTRzc2NfMi4xYjYtNS80XmNhMS06Yw%3D%3D&signature=71e94994aae8b5401008f4a7b4819641&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/ca5285b7fcfc496ea7fea88b6e29fb4a/?a=1988&br=2736&bt=1368&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amRtczRsODw1eTMzZzczM0ApM2lnPDs8Zzs6NzxpOzw4N2cxMG9qZ202XjJfLS0wMTRzc2NfMi4xYjYtNS80XmNhMS06Yw%3D%3D&signature=71e94994aae8b5401008f4a7b4819641&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/b9061b454889480a84620d49524d421d_1607083309~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=z%2BLrQYMVhnQzRBAAWe9S3rCJWP8%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/b9061b454889480a84620d49524d421d_1607083309~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=XOZdah2x7eUaTpCs0CDvMXLxxmM%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/2fd93300d81a44a29a4d7e86dbf0c195?x-expires=1616691600&x-signature=BZE3Lkx3j9JuDms8GHxmGyABayA%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6877838929601497858",
      "title": "Shower",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/dadec52c54d37b44eb18d198022124c9.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/a99e93a661934aa6ab549938bb936bcf.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/a99e93a661934aa6ab549938bb936bcf.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/a99e93a661934aa6ab549938bb936bcf.jpeg",
      "authorName": "Becky G",
      "original": false,
      "duration": 10,
      "album": "Shower"
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 123100,
      "shareCount": 134,
      "commentCount": 1747,
      "playCount": 1300000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 66,
        "end": 73,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6902025822106832130",
    "desc": "Tutorial, do you want to see the result? Хотите увидеть результат? #tutorial #fyp #sukhanovandrey",
    "createTime": 1607003116,
    "video": {
      "id": "6902025822106832130",
      "height": 960,
      "width": 540,
      "duration": 18,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/6cf291ea0bd94f77b851483cf18b53be?x-expires=1616691600&x-signature=d68FGiXkhS9KCHUzJA1oXCrC3P0%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/8ba84c45fb5f4ebd8854c2ace9b9b551_1607003118?x-expires=1616691600&x-signature=78%2F9VeW%2BRrkPymMtDHz%2BFgshe%2Bg%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/049dcdde604b4def99f54e352f9dfa9e_1607003119?x-expires=1616691600&x-signature=nyrdI7caeyBfQNrQT2ziHaaVh4o%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/442a7b84525d407794e8debf83ed07d9/?a=1988&br=2934&bt=1467&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692373&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=am00bDc3cHJoeTMzOTczM0ApOTU0ZjhmNjs0NzppNWRpZWcub18ycmJeazFfLS1gMTRzczEtXy0wLi5gYF5gX2AvNDU6Yw%3D%3D&signature=05ac280d883e660282b2fece569feb92&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/442a7b84525d407794e8debf83ed07d9/?a=1988&br=2934&bt=1467&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692373&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=am00bDc3cHJoeTMzOTczM0ApOTU0ZjhmNjs0NzppNWRpZWcub18ycmJeazFfLS1gMTRzczEtXy0wLi5gYF5gX2AvNDU6Yw%3D%3D&signature=05ac280d883e660282b2fece569feb92&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/8ba84c45fb5f4ebd8854c2ace9b9b551_1607003118~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=IPCYeL9poCVD0zxls5K5iemPf7c%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/8ba84c45fb5f4ebd8854c2ace9b9b551_1607003118~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=U456idXxAzxbRhzAf%2FNPAPzWv0U%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/6cf291ea0bd94f77b851483cf18b53be?x-expires=1616691600&x-signature=Wb16cCLnmx85dBBQvuXJbBo1z%2BU%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6902025803152902914",
      "title": "оригинальный звук",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6902025779731892993.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 18,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "229207",
        "title": "fyp",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 2200000,
      "shareCount": 3150,
      "commentCount": 2766,
      "playCount": 41700000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 67,
        "end": 76,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 77,
        "end": 81,
        "hashtagName": "fyp",
        "hashtagId": "229207",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 82,
        "end": 97,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6898641448183336193",
    "desc": "#result  Это безумие? 😳 is this crazy? 😳 #sukhanovandrey",
    "createTime": 1606215131,
    "video": {
      "id": "6898641448183336193",
      "height": 960,
      "width": 540,
      "duration": 8,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/779a0d139dd34583958879276e7b1b29?x-expires=1616691600&x-signature=MoLYRK6YlZf0wBwIhOyuJxbZPIk%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/e75414dc619241d3ab62b839a75be5b5_1606215133?x-expires=1616691600&x-signature=jTD4YHTuppCzvkLhP%2BSVjhh9yjY%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/d85f4826135746a89b34e22d85d07bbe_1606215133?x-expires=1616691600&x-signature=drhgfmmIzPEGgXsKJRr2mC6iohs%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/5afcd5c7904449d593dd3d5f6d5c3ebe/?a=1988&br=4456&bt=2228&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692363&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anV4ZDw6Zm9oeDMzNzczM0ApZzxmZjw5O2VkNzo4Mzw6M2djaS1obzVpX3JfLS0vMTRzc19jYmI1L2MwYTZgNDNfNC46Yw%3D%3D&signature=d2bdfe61d8710cef0a203df9dbe125c4&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/5afcd5c7904449d593dd3d5f6d5c3ebe/?a=1988&br=4456&bt=2228&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692363&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anV4ZDw6Zm9oeDMzNzczM0ApZzxmZjw5O2VkNzo4Mzw6M2djaS1obzVpX3JfLS0vMTRzc19jYmI1L2MwYTZgNDNfNC46Yw%3D%3D&signature=d2bdfe61d8710cef0a203df9dbe125c4&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/e75414dc619241d3ab62b839a75be5b5_1606215133~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=ttZh2HANzZpO55oK6lFHUlJzODk%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/e75414dc619241d3ab62b839a75be5b5_1606215133~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=YQIXrdusbsoIDu6M8cqIJDKgcKc%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/779a0d139dd34583958879276e7b1b29?x-expires=1616691600&x-signature=MoLYRK6YlZf0wBwIhOyuJxbZPIk%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6817120527187020549",
      "title": "Still Don't Know My Name",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/317dc28eb17033af031965a17acf987d.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "authorName": "Labrinth",
      "original": false,
      "duration": 41,
      "album": "Euphoria (Original Score from the HBO Series)"
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 79000,
      "shareCount": 667,
      "commentCount": 297,
      "playCount": 751200
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 7,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 43,
        "end": 58,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6898327570538958081",
    "desc": "Backstage 😳 #tutorial do you want to see the result? Хотите результат?😍 #sukhanovandrey",
    "createTime": 1606142050,
    "video": {
      "id": "6898327570538958081",
      "height": 960,
      "width": 540,
      "duration": 15,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/800803e57a254a93933a15d0a39aefa2_1606142052?x-expires=1616691600&x-signature=%2FIY5KGmjJbvpubse2UnCgGARZD0%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/129d3a86d4e442ca8ef02c85fc9aa30e_1606142052?x-expires=1616691600&x-signature=uQSXCDUDWM4bBA6VS1wqwZhH1gA%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a913fc55947544e9a0ca808a9c44953f_1606142052?x-expires=1616691600&x-signature=SuRWR1C%2BgFqzhfvtCUbJIgkCPZE%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/b8bd3a7c92374175a7137a7f09b50971/?a=1988&br=3178&bt=1589&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692370&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3g2bGtpbXB2eDMzOzczM0ApO2c2ODloOWRlN2Q8NTlpZGdtajQzazVtZHFfLS0uMTRzczJiLjIxNC5gNjYyNS9eMDM6Yw%3D%3D&signature=611c5aa336d9ad11e1dcd82d4fd15dd1&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/b8bd3a7c92374175a7137a7f09b50971/?a=1988&br=3178&bt=1589&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692370&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3g2bGtpbXB2eDMzOzczM0ApO2c2ODloOWRlN2Q8NTlpZGdtajQzazVtZHFfLS0uMTRzczJiLjIxNC5gNjYyNS9eMDM6Yw%3D%3D&signature=611c5aa336d9ad11e1dcd82d4fd15dd1&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/129d3a86d4e442ca8ef02c85fc9aa30e_1606142052~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=K4Uylo19%2BGwR6Azh5qpV3wRw7b0%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/129d3a86d4e442ca8ef02c85fc9aa30e_1606142052~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=SSTnadqpoTLOrs8OlelIGEJkEzc%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/800803e57a254a93933a15d0a39aefa2_1606142052?x-expires=1616691600&x-signature=%2BnNJjP%2FyJE4W8R9Q2BEZ6%2FyKGxI%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6898327577916607234",
      "title": "оригинальный звук",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6898327532496603905.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 15,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 110500,
      "shareCount": 90,
      "commentCount": 113,
      "playCount": 1600000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 13,
        "end": 22,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 74,
        "end": 89,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6897159477137640705",
    "desc": "#result ! Which will you choose?) что бы ты сьел?😁",
    "createTime": 1605870083,
    "video": {
      "id": "6897159477137640705",
      "height": 960,
      "width": 540,
      "duration": 13,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1d94938c560c46c28b6ebc2d2c78edfd_1605870085?x-expires=1616691600&x-signature=nYwhoomluZY0ITSCVhnOs%2BqIqhE%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/df43574c6c03472990e714b7b43a9485_1605870084?x-expires=1616691600&x-signature=EbDp5cZy%2FuDRNnlHRStdncAp9nM%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/6335a002d68a46b5a2d8e948400ccb6a_1605870084?x-expires=1616691600&x-signature=R0xH34qkp6fGk81rNWWqabCuDWo%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/5162e0a2b4164bae965f83195f1d58cd/?a=1988&br=2228&bt=1114&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2Zvbzx1ZHl0eDMzOzczM0ApZjo1aWY4Ozw7N2Y2PDY8NmcvX2FkMW82MG9fLS01MTRzcy0wNl9hYWEuM15hXi81YjM6Yw%3D%3D&signature=8995fb8cf179a11b26066ae8bd60760d&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/5162e0a2b4164bae965f83195f1d58cd/?a=1988&br=2228&bt=1114&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2Zvbzx1ZHl0eDMzOzczM0ApZjo1aWY4Ozw7N2Y2PDY8NmcvX2FkMW82MG9fLS01MTRzcy0wNl9hYWEuM15hXi81YjM6Yw%3D%3D&signature=8995fb8cf179a11b26066ae8bd60760d&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/df43574c6c03472990e714b7b43a9485_1605870084~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=rAZwhBZOokVQjoDuu5azSEuSxhc%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/df43574c6c03472990e714b7b43a9485_1605870084~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=qx4vNsFABz%2FWY3Q70679n7ZdSG4%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1d94938c560c46c28b6ebc2d2c78edfd_1605870085?x-expires=1616691600&x-signature=7pTBJLXgLP8Lc56C0zXsWRjbYJo%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6691366574592396038",
      "title": "Woah (feat. D3Mstreet)",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/1daadedc15bd3aa203a4620bac0e1f94.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-v-2102/66136bd201e44750a35867f8e4727c4d.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-v-2102/66136bd201e44750a35867f8e4727c4d.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-v-2102/66136bd201e44750a35867f8e4727c4d.jpeg",
      "authorName": "KRYPTO9095",
      "original": false,
      "duration": 16,
      "album": "Woah (feat. D3Mstreet)"
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 43000,
      "shareCount": 118,
      "commentCount": 105,
      "playCount": 1400000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 7,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6896844158775102722",
    "desc": "Backstage! #tutorial hungry?) Result soon in my profile!",
    "createTime": 1605796667,
    "video": {
      "id": "6896844158775102722",
      "height": 960,
      "width": 540,
      "duration": 26,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/944b8e5062634bedaf11a8f3f0b44a3c?x-expires=1616691600&x-signature=FuKItKSDOJG0x0c3p5UruLb96tI%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1287645aac0e4ab7aab7a2b80fd010de_1605796668?x-expires=1616691600&x-signature=SrgA1htnrnNY8Cmpvi7heFwHbYM%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/8310eea78a0f4b8795debf0f6a1e75a0_1605796669?x-expires=1616691600&x-signature=QY4Ma4B777yHtnTz8leEnu9%2Fohw%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/d1180ebe72ea418fb0cb4bf0e1631e16/?a=1988&br=1750&bt=875&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692381&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajx5Nm5lNGY7eDMzZjczM0ApaDxpPDQ8aWVnN2RmOzo3OmdfajVic2ByM29fLS0xMTRzczYvNWMtLV4zNGBjLi1fMl46Yw%3D%3D&signature=d8ca045be5c42a9c1d1a0309714bc7f3&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/d1180ebe72ea418fb0cb4bf0e1631e16/?a=1988&br=1750&bt=875&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692381&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajx5Nm5lNGY7eDMzZjczM0ApaDxpPDQ8aWVnN2RmOzo3OmdfajVic2ByM29fLS0xMTRzczYvNWMtLV4zNGBjLi1fMl46Yw%3D%3D&signature=d8ca045be5c42a9c1d1a0309714bc7f3&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/1287645aac0e4ab7aab7a2b80fd010de_1605796668~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=SVBotV6YCTBUa0Iug7aKGrXB0bA%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/1287645aac0e4ab7aab7a2b80fd010de_1605796668~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=TKeO4O9ohF14K%2FijspVnFsa6PiA%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/944b8e5062634bedaf11a8f3f0b44a3c?x-expires=1616691600&x-signature=FuKItKSDOJG0x0c3p5UruLb96tI%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6896844163388672769",
      "title": "оригинальный звук",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6896844119704931073.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 26,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 105400,
      "shareCount": 112,
      "commentCount": 124,
      "playCount": 1700000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 11,
        "end": 20,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6895378941268299009",
    "desc": "This is how I did my last video! Результат уже в профиле! #tutorial #sukhanovandrey",
    "createTime": 1605455519,
    "video": {
      "id": "6895378941268299009",
      "height": 960,
      "width": 540,
      "duration": 21,
      "ratio": "540p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1bb70164905c469b92ae55fcefbbb3dc_1605455522?x-expires=1616691600&x-signature=oOvSD4XAFC7i%2BeAGgdPbG3iRN0c%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/7b4897b7085a42b8898cf4d6149fab28_1605455520?x-expires=1616691600&x-signature=7y56IHIgdggTxM9XXHs1UWir0%2F8%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/9f629e82866445a6aee89f526a2c0404_1605455521?x-expires=1616691600&x-signature=6l1STM9F8WP2EdxoBKo559Dk19E%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/a5fdb3e56e524b46bf6151f339b5ba3d/?a=1988&br=672&bt=336&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692376&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=amY1dDdleDhueDMzODczM0ApZmc5Mzc4OTw3NzU5Njg5Z2drMG1zLW1kcWxfLS0tMTRzc2E0LzQtNjQ2XjM1Ni8tNDM6Yw%3D%3D&signature=2cf1ac0347123e2e9e136a65634cf19c&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/a5fdb3e56e524b46bf6151f339b5ba3d/?a=1988&br=672&bt=336&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692376&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=amY1dDdleDhueDMzODczM0ApZmc5Mzc4OTw3NzU5Njg5Z2drMG1zLW1kcWxfLS0tMTRzc2E0LzQtNjQ2XjM1Ni8tNDM6Yw%3D%3D&signature=2cf1ac0347123e2e9e136a65634cf19c&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/7b4897b7085a42b8898cf4d6149fab28_1605455520~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=m1uVgOlS6DIw3kvtyYtpfrqP4nk%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/7b4897b7085a42b8898cf4d6149fab28_1605455520~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=My5E4amK1aPsEnP3oEoKqlQoAX8%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1bb70164905c469b92ae55fcefbbb3dc_1605455522?x-expires=1616691600&x-signature=m4KGWfR4XfIZWznlmjDtomxZIkY%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6895378956200037121",
      "title": "оригинальный звук",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6895378908972518145.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 21,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 82000,
      "shareCount": 97,
      "commentCount": 147,
      "playCount": 1400000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 58,
        "end": 67,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 68,
        "end": 83,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6895348773313072385",
    "desc": "Результат #result кровать меняет цвет 😱#sukhanovandrey",
    "createTime": 1605448495,
    "video": {
      "id": "6895348773313072385",
      "height": 960,
      "width": 540,
      "duration": 11,
      "ratio": "540p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/0684612166e7456fab4272044cc211fa_1605448496?x-expires=1616691600&x-signature=Hm2Ep%2B5DOnoM7aPkKmEiMj5Q208%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/207dde79f9f7426a845ca0a45724c04d_1605448497?x-expires=1616691600&x-signature=uFbzNEYxBX6oTs1zPXP22Nuy71U%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/43aeab3d7549469aa7510666ff08e30f_1605448497?x-expires=1616691600&x-signature=sOR1xgOVSDK7kj0Rhy6khl2DjAQ%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/448ecd37f5d14c60878c6cb9343c2146/?a=1988&br=866&bt=433&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692366&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=amlldHJ2eGRteDMzNjczM0ApZjU4ZmU4ZWVoN2QzZjxlaGdqYm1yXjJfM2xfLS0tMTRzczA1XmAwNDEuNTY0YTU0MC46Yw%3D%3D&signature=77ee60f97f554174a37dc0a7912ed687&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/448ecd37f5d14c60878c6cb9343c2146/?a=1988&br=866&bt=433&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692366&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=amlldHJ2eGRteDMzNjczM0ApZjU4ZmU4ZWVoN2QzZjxlaGdqYm1yXjJfM2xfLS0tMTRzczA1XmAwNDEuNTY0YTU0MC46Yw%3D%3D&signature=77ee60f97f554174a37dc0a7912ed687&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/207dde79f9f7426a845ca0a45724c04d_1605448497~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=uLoSd0SnF5vB5eGDyJLuhFpjSJM%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/207dde79f9f7426a845ca0a45724c04d_1605448497~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=ECmUHMq81crwTn5%2FfmF4S7KG7aU%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/0684612166e7456fab4272044cc211fa_1605448496?x-expires=1616691600&x-signature=UQnqRfio%2BDR59DuI11FHeWJNxKc%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6842582526297393154",
      "title": "How You Like That",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/03461b33e4aff4360ba3c0ff9155744b.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tiktok-obj/image/a5477aaecd6428337eb9f7c400cde3e9.jpg.jpeg",
      "authorName": "BLACKPINK",
      "original": false,
      "duration": 31,
      "album": ""
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 30900,
      "shareCount": 23,
      "commentCount": 78,
      "playCount": 334500
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 10,
        "end": 17,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 40,
        "end": 55,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6893158896387738881",
    "desc": "За кадром 😱 результат уже в моем профиле 😍 #tutorial #sukhanovandrey",
    "createTime": 1604938625,
    "video": {
      "id": "6893158896387738881",
      "height": 960,
      "width": 540,
      "duration": 24,
      "ratio": "540p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a83fcf6822a3433f9054d248e10094a4?x-expires=1616691600&x-signature=8bgqbDpjI6626R%2BTLVgQQcl4txA%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/dcd18b94b2ca45acad0dde2d50f79941_1604938626?x-expires=1616691600&x-signature=ryONFyVWvpRZ6p0tWFIdkXvk2pg%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a4ce65a12b0e41bb9a36dca493d2fe5d_1604938627?x-expires=1616691600&x-signature=FtBbQszB%2BdKGHTdQ9WIjV5CxbGY%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e00640269dde45cdae5d503d668fdfc0/?a=1988&br=626&bt=313&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692379&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=MzM5cnJuZ3ZweDMzaDczM0ApNTs3aDw8NDxlNzc4Z2YzaWdiXzZnNms2amhfLS1fMTRzcy01NS01MV4zMWAzLzMyXzM6Yw%3D%3D&signature=2516fdc4247839c98153d54482244f4e&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e00640269dde45cdae5d503d668fdfc0/?a=1988&br=626&bt=313&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692379&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=MzM5cnJuZ3ZweDMzaDczM0ApNTs3aDw8NDxlNzc4Z2YzaWdiXzZnNms2amhfLS1fMTRzcy01NS01MV4zMWAzLzMyXzM6Yw%3D%3D&signature=2516fdc4247839c98153d54482244f4e&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/dcd18b94b2ca45acad0dde2d50f79941_1604938626~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=8%2BRR7wuHEGhchostE7DLL6WVpIY%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/dcd18b94b2ca45acad0dde2d50f79941_1604938626~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=tFitu%2Bxf6sggpGeF21VNGRhmGPI%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a83fcf6822a3433f9054d248e10094a4?x-expires=1616691600&x-signature=8bgqbDpjI6626R%2BTLVgQQcl4txA%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6893158907364035330",
      "title": "оригинальный звук",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6893158932248840962.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 24,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 77900,
      "shareCount": 75,
      "commentCount": 71,
      "playCount": 761400
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 45,
        "end": 54,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 55,
        "end": 70,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6893136487471254786",
    "desc": "Результат 🤯 do you like?  тебе нравится?  #timewarpscan #sukhanovandrey",
    "createTime": 1604933407,
    "video": {
      "id": "6893136487471254786",
      "height": 960,
      "width": 540,
      "duration": 13,
      "ratio": "540p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/abe6e2257df44efb8948b74374cd9c94?x-expires=1616691600&x-signature=aFNRZCywXiWaLwBlvyanYMla3c0%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/a68a640a4f0248559f64604c6ee0eba4_1604933409?x-expires=1616691600&x-signature=WpdW7aRaVLrVDeDzAOJ78%2BY%2FsHw%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/d457093f5931470493dafee246bc7cfb_1604933409?x-expires=1616691600&x-signature=vXVJezN8Ic197c48KY7IXHFzA9A%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/14a72ff89d0a47178c971f02edbbc9c6/?a=1988&br=616&bt=308&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=M3l4cjZ3cTlveDMzNDczM0ApNTo3ZTw7NGU5NzpoZWk7aWdqcTFyaWBlYmhfLS1gMTRzczUtNDUvMDAtNTZfNjIvXzA6Yw%3D%3D&signature=80c435425ed265dd1e4c9505f42a5d31&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/14a72ff89d0a47178c971f02edbbc9c6/?a=1988&br=616&bt=308&cd=0%7C0%7C0&ch=0&cr=0&cs=0&cv=1&dr=0&ds=6&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=5&rc=M3l4cjZ3cTlveDMzNDczM0ApNTo3ZTw7NGU5NzpoZWk7aWdqcTFyaWBlYmhfLS1gMTRzczUtNDUvMDAtNTZfNjIvXzA6Yw%3D%3D&signature=80c435425ed265dd1e4c9505f42a5d31&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/a68a640a4f0248559f64604c6ee0eba4_1604933409~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=vP7gFQPO%2B8KIIdStDsdwGMaLe9M%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/a68a640a4f0248559f64604c6ee0eba4_1604933409~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=Y459QxNUhxQlj%2BxFCIYAFiozEN8%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/abe6e2257df44efb8948b74374cd9c94?x-expires=1616691600&x-signature=V8SGUqvHtyKG5Z8vzyXkH6aVQ5E%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6884661096662173698",
      "title": "BOO!",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/5e88e6aad68572e53cb4b56dd955c6e8.m4a",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/bf5ed395c3dc4430b6c5ddb3d80ef23d.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/bf5ed395c3dc4430b6c5ddb3d80ef23d.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/bf5ed395c3dc4430b6c5ddb3d80ef23d.jpeg",
      "authorName": "Championxiii",
      "original": false,
      "duration": 30,
      "album": "BOO!"
    },
    "challenges": [
      {
        "id": "1677732212525058",
        "title": "timewarpscan",
        "desc": "It's finally here. Try out our new #TimeWarpScan Creative Effect.",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/830abea2690a0807446141f129f472bb",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/830abea2690a0807446141f129f472bb",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/830abea2690a0807446141f129f472bb",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/8ea88bfe57d0ea5e517efbc3c39b669c",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/8ea88bfe57d0ea5e517efbc3c39b669c",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/8ea88bfe57d0ea5e517efbc3c39b669c",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 27900,
      "shareCount": 16,
      "commentCount": 103,
      "playCount": 334900
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 43,
        "end": 56,
        "hashtagName": "timewarpscan",
        "hashtagId": "1677732212525058",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 57,
        "end": 72,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6891303804319354114",
    "desc": "За кадром 🤯 результат уже в моем профиле! #tutorial #sukhanovandrey",
    "createTime": 1604506702,
    "video": {
      "id": "6891303804319354114",
      "height": 960,
      "width": 540,
      "duration": 18,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/9b57965f2d674a6e842612cf6ca20e52_1604506705?x-expires=1616691600&x-signature=jTPCFWg%2BzG9qTJadUXxI%2B4D09Zc%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/3e3a58fb67d74087afeb1f2c01c2ecef_1604506705?x-expires=1616691600&x-signature=B7Uy44U5G5Oqo4%2FVQLbex%2B1gL0M%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/4b55394a4748418486efbeb108c1b939_1604506705?x-expires=1616691600&x-signature=tSlGqJAMGnJtM5QmrKwSguDDPQs%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e54b3fa6c7e3430580b9a5b90d52d6d2/?a=1988&br=2256&bt=1128&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692373&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3k0ZDh5dmpneDMzNTczM0ApN2lkNDs8ZztkNzlmNjM8NGdxcW0xNl81NWVfLS1iMTRzc2JiYzZfLV8tLTQuLV5eMF86Yw%3D%3D&signature=d2ec0449b1423ec6bf3b61d1b3b4ca5b&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e54b3fa6c7e3430580b9a5b90d52d6d2/?a=1988&br=2256&bt=1128&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692373&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3k0ZDh5dmpneDMzNTczM0ApN2lkNDs8ZztkNzlmNjM8NGdxcW0xNl81NWVfLS1iMTRzc2JiYzZfLV8tLTQuLV5eMF86Yw%3D%3D&signature=d2ec0449b1423ec6bf3b61d1b3b4ca5b&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/3e3a58fb67d74087afeb1f2c01c2ecef_1604506705~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=CPPaN6sE%2BKtzXRnf5DSd6LKADA4%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/3e3a58fb67d74087afeb1f2c01c2ecef_1604506705~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=ascbCThVTXTi8Y%2Bo0REZGxG7y8w%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/9b57965f2d674a6e842612cf6ca20e52_1604506705?x-expires=1616691600&x-signature=5Q4m0qP9Fp5%2BPXcZ%2BvSqHZP5onA%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6891303806882171649",
      "title": "оригинальный звук",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6891303742239410945.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 18,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 111900,
      "shareCount": 186,
      "commentCount": 131,
      "playCount": 2800000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 43,
        "end": 52,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 53,
        "end": 68,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6891276724697386242",
    "desc": "Результат #result #sukhanovandrey",
    "createTime": 1604500397,
    "video": {
      "id": "6891276724697386242",
      "height": 960,
      "width": 540,
      "duration": 10,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/b008369e384a4ed38e7cd775fe112d44_1604500399?x-expires=1616691600&x-signature=skN71hG8OhkI7JqsYUVNebHtZhI%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/34153d3a0b4a49ef8582311ed392e950_1604500400?x-expires=1616691600&x-signature=C0plsy7yZLLTsz1oevK76Bnmfns%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/e0f70c9eecc44731bf82658aac426f02_1604500399?x-expires=1616691600&x-signature=R75i0X87vN3zcoexPgApUnqjB3k%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/88d36356aa2341c6b06e1a274bd51840/?a=1988&br=2404&bt=1202&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=Mzk4dW9naTtleDMzODczM0ApNmc6ZDg6PGQzNzw6ZzY4NmdqZWUuLXNpa2VfLS1iMTRzczE0Xy0tLmMwYS8uLmFfLV46Yw%3D%3D&signature=6dd68c33fa550e138556eb1c33b8f5e3&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/88d36356aa2341c6b06e1a274bd51840/?a=1988&br=2404&bt=1202&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=Mzk4dW9naTtleDMzODczM0ApNmc6ZDg6PGQzNzw6ZzY4NmdqZWUuLXNpa2VfLS1iMTRzczE0Xy0tLmMwYS8uLmFfLV46Yw%3D%3D&signature=6dd68c33fa550e138556eb1c33b8f5e3&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/34153d3a0b4a49ef8582311ed392e950_1604500400~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=LAdB8IrQnbg8hX5sRzEjyrDtn8s%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/34153d3a0b4a49ef8582311ed392e950_1604500400~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=lkuAGV1yRYd1KE2WDNUGweyDuho%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/b008369e384a4ed38e7cd775fe112d44_1604500399?x-expires=1616691600&x-signature=skN71hG8OhkI7JqsYUVNebHtZhI%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6619213250301004550",
      "title": "original sound",
      "playUrl": "https://sf77-ies-music-va.tiktokcdn.com/obj/musically-maliva-obj/1616019009273877",
      "coverThumb": "https://p77-sign-sg.tiktokcdn.com/tos-maliva-avt-0068/0b1e54339a3b3c5719f032a992600ae7~c5_100x100.jpeg?x-expires=1616756400&x-signature=rURIw7kRgeI3e9DYVSGdW8LveIo%3D",
      "coverMedium": "https://p77-sign-sg.tiktokcdn.com/tos-maliva-avt-0068/0b1e54339a3b3c5719f032a992600ae7~c5_720x720.jpeg?x-expires=1616756400&x-signature=VDfTzjeFZ9tcGUOsZ6kBAuITASU%3D",
      "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/0b1e54339a3b3c5719f032a992600ae7~c5_1080x1080.jpeg?x-expires=1616756400&x-signature=O%2BNVbTRUwWaQMLEfgRdk4Jk49bs%3D",
      "authorName": "♥︎Mikaela♥︎",
      "original": true,
      "duration": 38,
      "album": "mikaela.badran"
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 35600,
      "shareCount": 60,
      "commentCount": 123,
      "playCount": 690900
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 10,
        "end": 17,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 18,
        "end": 33,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6886092616224558337",
    "desc": "#tutorial как сделать съедобный стакан 😳 how to make an edible glass 😱",
    "createTime": 1603293378,
    "video": {
      "id": "6886092616224558337",
      "height": 960,
      "width": 540,
      "duration": 16,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ec6af495856341ba99a381190dabe917_1603293380?x-expires=1616691600&x-signature=gGSOo9NJr1JGvCINMWtiAQgh3vQ%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/8f11fa7a40ec40558385ae90d76dc089_1603293380?x-expires=1616691600&x-signature=MAZQfDMT7JvJE6b6Bc4HKOU4ZZ4%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/1870172f71914e28bda5843f103f4fba_1603293382?x-expires=1616691600&x-signature=fhr25kP0QuVnK03U%2B3hMG2rGMuw%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/327208c983944f77a2bdf030182825c4/?a=1988&br=3006&bt=1503&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=Mzk8c2Q3b2k4eDMzZzczM0ApOzk5NGZlOzs2N2k0Nzc4O2dwMWdtaW02LjVfLS1hMTRzc2FeY19fXjJjMDMwXzAyNGM6Yw%3D%3D&signature=2f5e8b3ee2400dca78d0616e6a79cdc8&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/327208c983944f77a2bdf030182825c4/?a=1988&br=3006&bt=1503&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=Mzk8c2Q3b2k4eDMzZzczM0ApOzk5NGZlOzs2N2k0Nzc4O2dwMWdtaW02LjVfLS1hMTRzc2FeY19fXjJjMDMwXzAyNGM6Yw%3D%3D&signature=2f5e8b3ee2400dca78d0616e6a79cdc8&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/8f11fa7a40ec40558385ae90d76dc089_1603293380~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=qrKXTNgftld2fGqqBqXSssAtsVw%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/8f11fa7a40ec40558385ae90d76dc089_1603293380~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=p8RQmrOnnnFP423Mu7TivNQt8Hk%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ec6af495856341ba99a381190dabe917_1603293380?x-expires=1616691600&x-signature=PLYGFwnZlkdV%2FLeWWAK4MCnTEjc%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6863214295757490950",
      "title": "The Banjo Beat, Pt. 1",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/0f584cb7892da90cb0f1ff1b18a9d821.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "authorName": "Ricky Desktop",
      "original": false,
      "duration": 17,
      "album": "The Banjo Beat, Pt. 1"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 112600,
      "shareCount": 369,
      "commentCount": 174,
      "playCount": 1300000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 9,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6880529859626437889",
    "desc": "#tutorial Мне сказали, под этот трек можно попасть в рек 😳 #sukhanovandrey",
    "createTime": 1601998198,
    "video": {
      "id": "6880529859626437889",
      "height": 960,
      "width": 540,
      "duration": 16,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c45c92e70f3a464eaed0f4d0f9e71ff2_1601998200?x-expires=1616691600&x-signature=tFnMNXUfwYUD5OJ%2FlCJGOiq1bD8%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c9a82194bf4a4d7584dbba315b71026d_1601998200?x-expires=1616691600&x-signature=iQa5mrXadapQAwUO35C%2FK8HxS5E%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/fd42c6b36a024f9ea7977af4c24ad304_1601998201?x-expires=1616691600&x-signature=a1wbtNJZIr8mejnho4Vdhvrny1g%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/786cd15ff06744cdba49beac48c7bd48/?a=1988&br=3106&bt=1553&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anhnM2g7ZnU7dzMzMzczM0ApMzlpZzQ5Ojw8NzRmOjk6OWcvLy41X3FwbnJfLS0tMTRzczM1LjVfNWAuYmAuLmMxNjI6Yw%3D%3D&signature=ed42a8d52b922353cb5301c3c1c3e4b9&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/786cd15ff06744cdba49beac48c7bd48/?a=1988&br=3106&bt=1553&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692371&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anhnM2g7ZnU7dzMzMzczM0ApMzlpZzQ5Ojw8NzRmOjk6OWcvLy41X3FwbnJfLS0tMTRzczM1LjVfNWAuYmAuLmMxNjI6Yw%3D%3D&signature=ed42a8d52b922353cb5301c3c1c3e4b9&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/c9a82194bf4a4d7584dbba315b71026d_1601998200~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=kwaVxUDztHs1lfY3NJ7X%2FOi%2B4ww%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/c9a82194bf4a4d7584dbba315b71026d_1601998200~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=sFNdYD9SnRlIdeliyNvw4FhCeWc%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c45c92e70f3a464eaed0f4d0f9e71ff2_1601998200?x-expires=1616691600&x-signature=S4Hq296zCbzZ4Npljp4%2BtPAGV%2Fo%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6871655178097920770",
      "title": "оригинальный звук",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6871655089472408322.mp3",
      "coverThumb": "https://p58-sign-sg.tiktokcdn.com/tos-maliva-avt-0068/0169cd1892ebc2dc07d379023280e752~c5_100x100.jpeg?x-expires=1616756400&x-signature=A4j%2BrpLXNpSfepjuF1stjN9JQKw%3D",
      "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/0169cd1892ebc2dc07d379023280e752~c5_720x720.jpeg?x-expires=1616756400&x-signature=AVst%2B5vYwLlxFAppDNxtM98x1HQ%3D",
      "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/0169cd1892ebc2dc07d379023280e752~c5_1080x1080.jpeg?x-expires=1616756400&x-signature=vAf7LgpJs5PPbeVLARrKrquFwgM%3D",
      "authorName": "FANAMAN",
      "original": true,
      "duration": 18,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 56300,
      "shareCount": 34,
      "commentCount": 97,
      "playCount": 558100
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 9,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 60,
        "end": 75,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6879784844994317570",
    "desc": "Thank you for coming to  #MyTedTalkChallenge #MyTedTalk  @sadsummersongs #sukhanovandrey",
    "createTime": 1601824735,
    "video": {
      "id": "6879784844994317570",
      "height": 960,
      "width": 540,
      "duration": 14,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/cac04b99696e4ccaa9ccdb764747a4a6?x-expires=1616691600&x-signature=9%2Bw7mdM6GfCnd3M7MjlhDyBbDA4%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/4b09fab5624c4f88818ec939398d1f0b_1601824738?x-expires=1616691600&x-signature=5Zha7QPvj8ZZKOQm72o4ie7OUtA%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/5e3c269edfe24454a68be799459242f3_1601824739?x-expires=1616691600&x-signature=AzOX52UnYaCiOpk0rMTU5IErldU%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/44eb9f67711a482cbf0c3f351dae813f/?a=1988&br=2326&bt=1163&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692369&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2U8Nzk1dm94dzMzMzczM0ApNjhmZTg1OmQ4N2RoNjpoZ2cyMGVgMGNzY3BfLS00MTRzc2EuMC0wXjUtNDJeL2M0NTA6Yw%3D%3D&signature=2ab8125dfe9768cdd1cb377aebb3136c&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/44eb9f67711a482cbf0c3f351dae813f/?a=1988&br=2326&bt=1163&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692369&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M2U8Nzk1dm94dzMzMzczM0ApNjhmZTg1OmQ4N2RoNjpoZ2cyMGVgMGNzY3BfLS00MTRzc2EuMC0wXjUtNDJeL2M0NTA6Yw%3D%3D&signature=2ab8125dfe9768cdd1cb377aebb3136c&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/4b09fab5624c4f88818ec939398d1f0b_1601824738~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=Rn81vGcpgWfrJOJtat9iPQW%2FaQs%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/4b09fab5624c4f88818ec939398d1f0b_1601824738~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=ghpfIc1tuiUKPLnbB%2FE4zyh8WGM%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/cac04b99696e4ccaa9ccdb764747a4a6?x-expires=1616691600&x-signature=9%2Bw7mdM6GfCnd3M7MjlhDyBbDA4%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6875875877968709633",
      "title": "TED Talk",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/fcfeea1cc3cb64a2914828431ad4e25a.m4a",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/85bfdc5cd8a34508bbc2a2f0b95eb51c.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/85bfdc5cd8a34508bbc2a2f0b95eb51c.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/85bfdc5cd8a34508bbc2a2f0b95eb51c.jpeg",
      "authorName": "Sad Summer",
      "original": false,
      "duration": 60,
      "album": "TED Talk"
    },
    "challenges": [
      {
        "id": "1679328472505345",
        "title": "mytedtalkchallenge",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1607206298088614",
        "title": "mytedtalk",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 31300,
      "shareCount": 68,
      "commentCount": 48,
      "playCount": 445000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 57,
        "end": 72,
        "hashtagName": "",
        "hashtagId": "",
        "type": 0,
        "userId": "6873279369478407173",
        "isCommerce": false,
        "userUniqueId": "sadsummersongs",
        "secUid": "MS4wLjABAAAA-QsQh8TUMlkbbjMfzy3qF2di1wl5dDreR9LI_kp2l02mA56IkYM_N0wWSMhS05VZ"
      },
      {
        "awemeId": "",
        "start": 25,
        "end": 44,
        "hashtagName": "mytedtalkchallenge",
        "hashtagId": "1679328472505345",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 45,
        "end": 55,
        "hashtagName": "mytedtalk",
        "hashtagId": "1607206298088614",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 73,
        "end": 88,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "stickersOnItem": [
      {
        "stickerType": 4,
        "stickerText": [
          "pour water",
          "put the glass\nin water",
          "fix",
          "put in the\nfreezer",
          "take out of the\nfreezer",
          "ice glass"
        ]
      }
    ],
    "isAd": false
  },
  {
    "id": "6878324248046374145",
    "desc": "Как я сделал свой последний TikTok 😳 how I did my last TikTok 😱 #tutorial #cake #sukhanovandrey",
    "createTime": 1601484663,
    "video": {
      "id": "6878324248046374145",
      "height": 960,
      "width": 540,
      "duration": 10,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/acee3971d42840f49b4b8917210c8ce0_1601484665?x-expires=1616691600&x-signature=D5Gpr1kYcxJlmDsAfDE5KH2IsTw%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/19352fd91af34638a2d4f3ad3f2d1164_1601484666?x-expires=1616691600&x-signature=BWozCOxvV6D%2BsCr4XX%2FqAuO%2FmkQ%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/69f73c95954a45689b7ceba66e32c7a6_1601484666?x-expires=1616691600&x-signature=UhPkmqJ4ClKaIchlvuTrIa7BUY4%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/2ea6616bc9f14bd8bf45363de38a1ebd/?a=1988&br=2904&bt=1452&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amk4Nzk1dnZldzMzMzczM0ApZTNpM2hnNTs6NzdnNGQ3Nmc0NGZgMGNrYm5fLS00MTRzc2NjMzFiNDVfMDY2NmEzMl86Yw%3D%3D&signature=95cd125daa7cfab58c34a375cf752c0b&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/2ea6616bc9f14bd8bf45363de38a1ebd/?a=1988&br=2904&bt=1452&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692365&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amk4Nzk1dnZldzMzMzczM0ApZTNpM2hnNTs6NzdnNGQ3Nmc0NGZgMGNrYm5fLS00MTRzc2NjMzFiNDVfMDY2NmEzMl86Yw%3D%3D&signature=95cd125daa7cfab58c34a375cf752c0b&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/19352fd91af34638a2d4f3ad3f2d1164_1601484666~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=IUdPAfb7B4aUULireX3iK1M74Io%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/19352fd91af34638a2d4f3ad3f2d1164_1601484666~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=TbERvrh8r52xxYxphCSpY2va%2BEQ%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/acee3971d42840f49b4b8917210c8ce0_1601484665?x-expires=1616691600&x-signature=D5Gpr1kYcxJlmDsAfDE5KH2IsTw%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6863214295757490950",
      "title": "The Banjo Beat, Pt. 1",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/0f584cb7892da90cb0f1ff1b18a9d821.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "authorName": "Ricky Desktop",
      "original": false,
      "duration": 17,
      "album": "The Banjo Beat, Pt. 1"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "8101",
        "title": "cake",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 1600000,
      "shareCount": 1476,
      "commentCount": 2080,
      "playCount": 31700000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 66,
        "end": 75,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 76,
        "end": 81,
        "hashtagName": "cake",
        "hashtagId": "8101",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 82,
        "end": 97,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6877194421587217666",
    "desc": "Have you seen such a cake? 😳 Вы видели такой торт? 😱 #cake #sukhanovandrey",
    "createTime": 1601221605,
    "video": {
      "id": "6877194421587217666",
      "height": 960,
      "width": 540,
      "duration": 9,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/14c4fb8244ee46af8a7e979d23f45ca0_1601221608?x-expires=1616691600&x-signature=Wot1BRbU%2FWYsBZIUHFusj5svc6w%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/173992eda2fd4552ad09cf8354356f9d_1601221608?x-expires=1616691600&x-signature=%2FIMMpIb%2FyNImUXXXni96GMN9bLw%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/74a0e16cb00a440cadd5f2b6c5193a5d_1601221608?x-expires=1616691600&x-signature=eMP%2B7CWm%2FOQ68aKV0s29Yu%2F2P3s%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e221749c35874cb1957141e1c3330e93/?a=1988&br=2516&bt=1258&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anZsbXF0b3FldzMzMzczM0ApODk4Njo3aTtpNzUzZDtkaWdrZGFsc2VwNGxfLS00MTRzc14xNC41YS0xNGE2Nl9fMTI6Yw%3D%3D&signature=028e3ada2dd69b81cb0dcb00e0080678&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/e221749c35874cb1957141e1c3330e93/?a=1988&br=2516&bt=1258&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=anZsbXF0b3FldzMzMzczM0ApODk4Njo3aTtpNzUzZDtkaWdrZGFsc2VwNGxfLS00MTRzc14xNC41YS0xNGE2Nl9fMTI6Yw%3D%3D&signature=028e3ada2dd69b81cb0dcb00e0080678&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/173992eda2fd4552ad09cf8354356f9d_1601221608~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=EN%2FO317MVdstTRbNgJSAwnuXwo8%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/173992eda2fd4552ad09cf8354356f9d_1601221608~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=BW8NhKcZUrITz4G2GnB98CLASds%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/14c4fb8244ee46af8a7e979d23f45ca0_1601221608?x-expires=1616691600&x-signature=uenqlglnwEMTtKujaGW5TdfbOaI%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6757244841278588929",
      "title": "Jocelyn Flores",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tos-alisg-v-0000/d18f403e66dd49f6a5c421dfbd381a0a",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/f6b4962a875b459ba6bc71209326e973.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/f6b4962a875b459ba6bc71209326e973.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/f6b4962a875b459ba6bc71209326e973.jpeg",
      "authorName": "XXXTENTACION",
      "original": false,
      "duration": 30,
      "album": "17"
    },
    "challenges": [
      {
        "id": "8101",
        "title": "cake",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 170100,
      "shareCount": 265,
      "commentCount": 694,
      "playCount": 4500000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 55,
        "end": 60,
        "hashtagName": "cake",
        "hashtagId": "8101",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 61,
        "end": 76,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6875347053946440962",
    "desc": "#tutorial как я сделал свой последний TikTok 😱 how I did my last TikTok 😳 #sukhanovandrey",
    "createTime": 1600791483,
    "video": {
      "id": "6875347053946440962",
      "height": 960,
      "width": 540,
      "duration": 13,
      "ratio": "720p",
      "cover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/891c937265fa4a7099a72632fb020ce1_1600791486?x-expires=1616691600&x-signature=hWSp7TUaAA%2FLEdwLfQ5lSfqoizs%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/d30455a1a4354402ac035a335b3337b8_1600791486?x-expires=1616691600&x-signature=nrIoWLpzn5q%2BPxB0PT36ac%2B728A%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/d8f2d72b972f4b6a83b69c26a18c2a23_1600791486?x-expires=1616691600&x-signature=fEmmIpGvH2kcndxN2wp9a7T1SWA%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/3ced0aa4385248948b50d96402862e2d/?a=1988&br=2212&bt=1106&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=MzZvaDtpdmc1dzMzNjczM0ApOzM2aTo1ZWRnN2ZpNGQ4aWczcWMwbV8wNGlfLS1iMTRzczFiYTJeMl5fYjEwYTVhNGM6Yw%3D%3D&signature=44ec56fb6d31f33ea45fe561eb94c33a&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/3ced0aa4385248948b50d96402862e2d/?a=1988&br=2212&bt=1106&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=MzZvaDtpdmc1dzMzNjczM0ApOzM2aTo1ZWRnN2ZpNGQ4aWczcWMwbV8wNGlfLS1iMTRzczFiYTJeMl5fYjEwYTVhNGM6Yw%3D%3D&signature=44ec56fb6d31f33ea45fe561eb94c33a&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/d30455a1a4354402ac035a335b3337b8_1600791486~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=hduhP4mJilHt0igcBE9OpOprJQY%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/d30455a1a4354402ac035a335b3337b8_1600791486~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=4nAmj%2B4dBUZNgz%2Fs9F%2B5DdSV54g%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/891c937265fa4a7099a72632fb020ce1_1600791486?x-expires=1616691600&x-signature=hWSp7TUaAA%2FLEdwLfQ5lSfqoizs%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6863214295757490950",
      "title": "The Banjo Beat, Pt. 1",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/0f584cb7892da90cb0f1ff1b18a9d821.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "authorName": "Ricky Desktop",
      "original": false,
      "duration": 17,
      "album": "The Banjo Beat, Pt. 1"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 43200,
      "shareCount": 28,
      "commentCount": 83,
      "playCount": 835800
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 9,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 76,
        "end": 91,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6875311615449795842",
    "desc": "Gravity😅 хотите увидеть туториал? want to see the #tutorial ? 😍 #sukhanovandrey",
    "createTime": 1600783231,
    "video": {
      "id": "6875311615449795842",
      "height": 960,
      "width": 540,
      "duration": 9,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ffb5e61c38d64566b2eda2a7808f3a4c_1600783234?x-expires=1616691600&x-signature=QEvfVDLbsCVYMdHrHSZOrdfIyC4%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/f3ce4249c123431ab46211c06fcd8b50_1600783233?x-expires=1616691600&x-signature=WFGheH%2FeeDPMMJ%2BpXhBcWj%2FpIGY%3D",
      "dynamicCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/9108026dd07844ceab9b8e01222e3778_1600783234?x-expires=1616691600&x-signature=1ihxlP4nkxNYBgjX4K00Ji5ET%2Bs%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/baa66673f6f743c9a355fb0db2e9978f/?a=1988&br=2186&bt=1093&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M25raDtpdnczdzMzNjczM0ApPGVkZWQ1ZWU3Nzs4Zjg7OGdqbWMwbV9fM2lfLS1iMTRzc180Yl9eYy01MzM1Xl9eMGE6Yw%3D%3D&signature=ffdc418ce4e31ab0960a7a4c84e1fa44&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/baa66673f6f743c9a355fb0db2e9978f/?a=1988&br=2186&bt=1093&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M25raDtpdnczdzMzNjczM0ApPGVkZWQ1ZWU3Nzs4Zjg7OGdqbWMwbV9fM2lfLS1iMTRzc180Yl9eYy01MzM1Xl9eMGE6Yw%3D%3D&signature=ffdc418ce4e31ab0960a7a4c84e1fa44&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/f3ce4249c123431ab46211c06fcd8b50_1600783233~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=tN1SNxLdF2es2h7ciVoO4UaObMY%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/f3ce4249c123431ab46211c06fcd8b50_1600783233~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=9dFZJZIrB95D4j2Ua27TeoP6n5E%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/ffb5e61c38d64566b2eda2a7808f3a4c_1600783234?x-expires=1616691600&x-signature=QEvfVDLbsCVYMdHrHSZOrdfIyC4%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6863214295757490950",
      "title": "The Banjo Beat, Pt. 1",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/0f584cb7892da90cb0f1ff1b18a9d821.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/c1331f1d7bf14e539c0000ad64472aeb.jpeg",
      "authorName": "Ricky Desktop",
      "original": false,
      "duration": 17,
      "album": "The Banjo Beat, Pt. 1"
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 77700,
      "shareCount": 64,
      "commentCount": 335,
      "playCount": 1400000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 51,
        "end": 60,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 66,
        "end": 81,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6874940990533176577",
    "desc": "#tutorial как я сделал свой последний TikTok 😳 how I did my last TikTok 😱 #sukhanovandrey",
    "createTime": 1600696940,
    "video": {
      "id": "6874940990533176577",
      "height": 960,
      "width": 540,
      "duration": 13,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/e080e9297a2742d492b03acf30dfa0be_1600696942?x-expires=1616691600&x-signature=WliMvhSm8d6Cjwg9SdImzEPqfZ0%3D",
      "originCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/651d24efa68441d39f9086204852c9c3_1600696942?x-expires=1616691600&x-signature=I1gbFp%2BZRD1mfDWFadBpFTKTWpg%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c69abfae7c0a4c03b1fcea8cbedac309_1600696942?x-expires=1616691600&x-signature=%2BDoikSm5c1Bxp3MnrqKUgdRii4g%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/88acfb492adc4e28b519f9f537b640a6/?a=1988&br=3540&bt=1770&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3l5bmtocHFldzMzZjczM0ApPDg4Zjg7aDxlN2Y8ZmRpM2dzYmJeMGIxMWhfLS0vMTRzc2FgNTZiYWEwYWJiYDVhYl86Yw%3D%3D&signature=1e9ce1ce2bcedd7df80f9180d29b037c&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/88acfb492adc4e28b519f9f537b640a6/?a=1988&br=3540&bt=1770&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692368&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=M3l5bmtocHFldzMzZjczM0ApPDg4Zjg7aDxlN2Y8ZmRpM2dzYmJeMGIxMWhfLS0vMTRzc2FgNTZiYWEwYWJiYDVhYl86Yw%3D%3D&signature=1e9ce1ce2bcedd7df80f9180d29b037c&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/651d24efa68441d39f9086204852c9c3_1600696942~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=Lb00bIv7iw6jgY6FQrVUIOddHT4%3D",
        "https://p77-sign-sg.tiktokcdn.com/tos-alisg-p-0037/651d24efa68441d39f9086204852c9c3_1600696942~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=gU4MGVPT5FFMQujZBUMG0WqY3V8%3D"
      ],
      "reflowCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/e080e9297a2742d492b03acf30dfa0be_1600696942?x-expires=1616691600&x-signature=WliMvhSm8d6Cjwg9SdImzEPqfZ0%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6874941016734993153",
      "title": "оригинальный звук",
      "playUrl": "https://sf16-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6874940969184267010.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 13,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 1200000,
      "shareCount": 925,
      "commentCount": 1525,
      "playCount": 11600000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 9,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 76,
        "end": 91,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6874923812182347009",
    "desc": "Это безумно? 😳 Is this crazy? 😳 #result #sukhanovandrey",
    "createTime": 1600692939,
    "video": {
      "id": "6874923812182347009",
      "height": 960,
      "width": 540,
      "duration": 9,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c081b5c40e57479fa58c099fee5639b8_1600692941?x-expires=1616691600&x-signature=3LNb0GjuiEMJD41EVxEyYqUsUOU%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/fae2e83684594a13a7baa7e99c0d7d41_1600692942?x-expires=1616691600&x-signature=sEfzZPTUCzuu2G7HcEJEbTmhUxs%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c8d715d465114f9d9912072b9cd0f8b7_1600692942?x-expires=1616691600&x-signature=Lca70h5Gr%2BWHzw09ceMokX%2F8eYE%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/c5cbb450316e48a785d5863d33742ade/?a=1988&br=7542&bt=3771&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amQ7Om44PGpkdzMzNTczM0ApOWc4NmdmZzxnNzg2ZDQ2ZmdnMi8zcGBhMmhfLS1hMTRzczZfNC9fLmJhYV9gNS00YzQ6Yw%3D%3D&signature=e3d5ec36d94735fc998f434f018ae721&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/c5cbb450316e48a785d5863d33742ade/?a=1988&br=7542&bt=3771&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692364&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=amQ7Om44PGpkdzMzNTczM0ApOWc4NmdmZzxnNzg2ZDQ2ZmdnMi8zcGBhMmhfLS1hMTRzczZfNC9fLmJhYV9gNS00YzQ6Yw%3D%3D&signature=e3d5ec36d94735fc998f434f018ae721&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/fae2e83684594a13a7baa7e99c0d7d41_1600692942~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=LmOV1orbPvy0pnFIohBViTpt5bU%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/fae2e83684594a13a7baa7e99c0d7d41_1600692942~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=2rXT2wT9vxTSyPdHHrT9HSBLp1s%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c081b5c40e57479fa58c099fee5639b8_1600692941?x-expires=1616691600&x-signature=1pweneyQjEq%2F3C4MfcakbDfRsyE%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6817120527187020549",
      "title": "Still Don't Know My Name",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/317dc28eb17033af031965a17acf987d.mp3",
      "coverThumb": "https://p77-sg.tiktokcdn.com/aweme/100x100/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "coverMedium": "https://p77-sg.tiktokcdn.com/aweme/200x200/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "coverLarge": "https://p77-sg.tiktokcdn.com/aweme/720x720/tos-alisg-i-0000/873df954d4314ff1b1f075fddb142ab4.jpeg",
      "authorName": "Labrinth",
      "original": false,
      "duration": 41,
      "album": "Euphoria (Original Score from the HBO Series)"
    },
    "challenges": [
      {
        "id": "282179",
        "title": "result",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      },
      {
        "id": "1664061526471702",
        "title": "sukhanovandrey",
        "desc": "",
        "profileThumb": "",
        "profileMedium": "",
        "profileLarger": "",
        "coverThumb": "",
        "coverMedium": "",
        "coverLarger": "",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 501300,
      "shareCount": 2668,
      "commentCount": 664,
      "playCount": 2800000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 34,
        "end": 41,
        "hashtagName": "result",
        "hashtagId": "282179",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      },
      {
        "awemeId": "",
        "start": 42,
        "end": 57,
        "hashtagName": "sukhanovandrey",
        "hashtagId": "1664061526471702",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  },
  {
    "id": "6872685436800683266",
    "desc": "#tutorial как я сделал свой последний TikTok 😳 How I did my last TikTok 😱",
    "createTime": 1600171776,
    "video": {
      "id": "6872685436800683266",
      "height": 960,
      "width": 540,
      "duration": 12,
      "ratio": "720p",
      "cover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/72531fa06e2c480ba3216b54d0b8078e?x-expires=1616691600&x-signature=cUQzoH4ZCHSn4tsofHNmUVKVFy4%3D",
      "originCover": "https://p77-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/af37dfbe28bd478081555283bccfba05_1600171779?x-expires=1616691600&x-signature=ayZwiwZ4T3mz5SdqC2hJsSseXKA%3D",
      "dynamicCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/9d69051e4cf44e0d9ccb4523aa4143e6_1600171779?x-expires=1616691600&x-signature=fRRTpV4QCii3jkBBYPfpPrA1OoA%3D",
      "playAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/f17d19d8d6aa41d8928d9305cb91576d/?a=1988&br=2864&bt=1432&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692367&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajZoODc1dnlkdzMzaTczM0ApZTs4aGc3aDs4N2dnNzgzN2drYmIvMGM0cWRfLS1hMTRzcy5hXy1hNmEtNWExMDZgMjQ6Yw%3D%3D&signature=b31191469d70cb02fabe44906903bc74&tk=tt_webid_v2&vl=&vr=",
      "downloadAddr": "https://v16-web.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/f17d19d8d6aa41d8928d9305cb91576d/?a=1988&br=2864&bt=1432&cd=0%7C0%7C1&ch=0&cr=0&cs=0&cv=1&dr=0&ds=3&er=&expire=1616692367&l=20210325111235010190219223070DB960&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&policy=2&qs=0&rc=ajZoODc1dnlkdzMzaTczM0ApZTs4aGc3aDs4N2dnNzgzN2drYmIvMGM0cWRfLS1hMTRzcy5hXy1hNmEtNWExMDZgMjQ6Yw%3D%3D&signature=b31191469d70cb02fabe44906903bc74&tk=tt_webid_v2&vl=&vr=",
      "shareCover": [
        "",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/af37dfbe28bd478081555283bccfba05_1600171779~tplv-tiktok-play.jpeg?x-expires=1616691600&x-signature=ry8XIzicYHf3%2Fy%2BC43TB3M9aS%2F0%3D",
        "https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/af37dfbe28bd478081555283bccfba05_1600171779~tplv-tiktok-play2.jpeg?x-expires=1616691600&x-signature=WNpxk0fUmSY%2FfW7AgIlWQ3UMjyU%3D"
      ],
      "reflowCover": "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/72531fa06e2c480ba3216b54d0b8078e?x-expires=1616691600&x-signature=TqbjST96DKHrebBH94SEqGwhCHg%3D"
    },
    "author": {
      "id": "6753136164272227333",
      "uniqueId": "sukhanovandrey",
      "nickname": "Суханов Андрей",
      "avatarThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "avatarMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "avatarLarger": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "signature": "Участник: House88 \nreklama@house88.ru\n\nThe result is already in my Instagram!",
      "verified": true,
      "secUid": "MS4wLjABAAAA7qybG0pVO2LN9Zh9Zpo6GW8JFCRG2gk7_DjkChzBrzXIvwgDSU4l31hxVTfOBHK3",
      "secret": false,
      "ftc": false,
      "relation": 0,
      "openFavorite": false,
      "commentSetting": 0,
      "duetSetting": 0,
      "stitchSetting": 0,
      "privateAccount": false
    },
    "music": {
      "id": "6872685444376021762",
      "title": "оригинальный звук",
      "playUrl": "https://sf77-ies-music-sg.tiktokcdn.com/obj/tiktok-obj/6872685388591401730.mp3",
      "coverThumb": "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=vA65ePAQrwodtVQuyujUVGednAY%3D",
      "coverMedium": "https://p58-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=CDNtVjaYinTIwNSI%2FA3hGwBxJZI%3D",
      "coverLarge": "https://p58-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/807863f72003732ba82c7469c9a03c0e.jpeg?x-expires=1616756400&x-signature=xmoyb%2FXLKunRhtHBbtBEQbTMXK8%3D",
      "authorName": "Суханов Андрей",
      "original": true,
      "duration": 12,
      "album": ""
    },
    "challenges": [
      {
        "id": "8166",
        "title": "tutorial",
        "desc": "#AprendeEnTikTok cómo hacer lo que sea! Muéstranos un #Tutorial que tenemos que aprender! 🙌",
        "profileThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "profileLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/859c6a1c7bbf55914a21a2c879ddd5a2",
        "coverThumb": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverMedium": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "coverLarger": "https://p77-va.tiktokcdn.com/obj/musically-maliva-obj/eca4f0ac54dc4ed1b2cf76ee6566cdc0",
        "isCommerce": false
      }
    ],
    "stats": {
      "diggCount": 130700,
      "shareCount": 164,
      "commentCount": 203,
      "playCount": 7100000
    },
    "duetInfo": {
      "duetFromId": "0"
    },
    "originalItem": false,
    "officalItem": false,
    "textExtra": [
      {
        "awemeId": "",
        "start": 0,
        "end": 9,
        "hashtagName": "tutorial",
        "hashtagId": "8166",
        "type": 1,
        "userId": "",
        "isCommerce": false,
        "userUniqueId": "",
        "secUid": ""
      }
    ],
    "secret": false,
    "forFriend": false,
    "digged": false,
    "itemCommentStatus": 0,
    "showNotPass": false,
    "vl1": false,
    "itemMute": false,
    "authorStats": {
      "followingCount": 12,
      "followerCount": 2300000,
      "heartCount": 35400000,
      "videoCount": 147,
      "diggCount": 1290,
      "heart": 35400000
    },
    "privateItem": false,
    "duetEnabled": true,
    "stitchEnabled": true,
    "shareEnabled": true,
    "isAd": false
  }
].map(item => ({
  // ...item,
  date: item.createTime * 1000,
  views: item.stats.playCount,
  prevviews: item.stats.playCount - percent(item.stats.playCount, Math.random().toFixed(4) / 100),
  likes: item.stats.diggCount,
  prevlikes: item.stats.diggCount - percent(item.stats.diggCount, Math.random().toFixed(4) / 100),
  comments: item.stats.commentCount,
  prevcomments: item.stats.commentCount - percent(item.stats.commentCount, Math.random().toFixed(4) / 100),
  duets: [
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
    `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/d3b98d27d7ca4b92c3c6981bca21999e~c5_1080x1080.jpeg`,
  ],
  totalDuets: Math.round(Math.random() * (10 - 3) + 3),
  username: item.author.uniqueId,
  ava: item.video.cover,
  link: `https://www.tiktok.com/@${item.author.uniqueId}/video/${item.video.id}`
}));

module.exports = {
  // 6830197954876276742: {
    latest: v.sort((a, b) => b.date - a.date).splice(0, 10),
    popular: v.sort((a, b) => b.views - a.views).splice(0, 10)
  // }
};
console.log(module.exports);
