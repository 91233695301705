<template>
  <div class="duets">
    <div
      v-for="(item, key) in images"
      :key="key"
      class="duets_item"
      :style="getImgStyle(key, images.length)"
    >
      <img
        v-if="item.type === 'img'"
        :src="item.src"
        alt=""
        class="duets-img"
      />
      <div v-else class="duets-total">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `Duets`,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    images() {
      return [
        ...this.data.slice(0, 3).map((item) => {
          return {
            src: item,
            type: `img`,
          };
        }),
        {
          type: `total`,
          value: this.total ? this.total - 3 > 100 ? `99+` : this.total - 3 : 0,
        },
      ];
    },
  },
  methods: {
    getImgStyle(key, length) {
      //Stupid calculations
      const imgWidth = 31;
      const imagesWidth = imgWidth * length;
      const containerWidth = 93;
      const diff = imagesWidth - containerWidth;
      const offset = (imagesWidth / length - diff / (length - 1)) * key;
      return {
        left: `${offset}px`,
        zIndex: key + 2,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.duets {
  position: relative;
  height: 71px;
  display: flex;
  align-items: center;
  &_item {
    position: absolute;
    width: 31px;
    height: 31px;
    border-radius: 31px;
    border: 2px solid #ffffff;
    overflow: hidden;
  }
  &-img {
    max-width: 100%;
    width: 100%;
  }
  &-total {
    font-size: 13px;
    width: 100%;
    height: 100%;
    color: $pink;
    background-color: #e3e7ed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
