<template>
  <div class="tag_manager">
    <c-input
      v-model="inputTag"
      class="tag_manager-input"
      max-width="100%"
      :placeholder="$t('WelcomePage.example_category')"
      @enter="handleEnter"
    ></c-input>
    <div v-if="tags.length" class="tag_manager-list">
      <transition-group name="el-zoom-in-center">
        <c-tag
          v-for="(item, key) in tags"
          :key="key + item"
          closable
          @close="deleteTag(key)"
        >
          {{ item }}
        </c-tag>
      </transition-group>
    </div>
  </div>
</template>
<script>
import CInput from '~/src/components/base/input';
import CTag from '~/src/components/base/tag';
export default {
  name: `TagManager`,
  components: { CInput, CTag },
  data() {
    return {
      inputTag: ``,
      tags: [],
    };
  },
  methods: {
    handleEnter() {
      this.tags.push(this.inputTag);
      this.inputTag = ``;
      this.emitTags();
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
      this.emitTags();
    },
    emitTags() {
      this.$emit(`change`, this.tags);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.tag_manager {
  margin: 5px 0px 30px 0px;
  &-list {
    padding: 10px 0px;
  }
}
</style>
