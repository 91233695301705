<template>
  <div class="simpleList">
    <div v-for="(item, key) in data" :key="key" class="simpleList_item">
      <div class="simpleList-label">
        <div
          class="simpleList-point"
          :style="{ backgroundColor: colors[key] }"
        ></div>
        <div>{{ item.label }}</div>
      </div>
      <div class="simpleList-value">
        {{ item.value }}%
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `CSimpleList`,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
};
</script>
<style lang="scss" scoped>
.simpleList {
  width: 100%;
  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
  }
  &-label {
    display: flex;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-point {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  &-value {
    font-size: 20px;
  }
}
</style>
