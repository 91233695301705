<template>
  <div>
    <bar
      v-if="type === 'bar'"
      :height="height"
      :options="options"
      :chart-data="chartData"
    ></bar>
    <horizontal-bar
      v-if="type === 'horizontalBar'"
      :options="options"
      :chart-data="chartData"
      :height="height"
    ></horizontal-bar>
  </div>
</template>
<script>
import Bar from './../graphs/bar.js';
import HorizontalBar from './../graphs/horizontalBar.js';
const barStyles = {
  barPercentage: 0.5,
  barThickness: 59,
  maxBarThickness: 59,
  minBarLength: 2,
  backgroundColor: [],
  borderWidth: 0,
};

export default {
  name: `CBar`,
  components: {
    Bar,
    HorizontalBar,
  },
  props: {
    type: {
      type: String,
      default: `bar`,
    },
    dataSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    chartData() {
      const dataSet = [];
      const labels = this.labels;
      this.dataSets.forEach((data, idx) => {
        const max = Math.max(...data);

        dataSet[idx] = {
          order: idx,
          data: data,
          ...barStyles,
        };

        if (this.type === `horizontalBar`) {
          dataSet[idx].barThickness = 26;
          dataSet[idx].borderWidth = {
            top: 1,
            right: 0,
            bottom: 0,
            left: 0,
          };
        }

        data.forEach((d) => {
          dataSet[idx].backgroundColor.push(
            d === max ? `rgba(255, 191, 211, 0.9)` : `rgba(191, 253, 251, 0.86)`
          );
        });
      });
      return {
        datasets: dataSet,
        labels: labels,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
