<template>
  <div :class="classes" :style="{ padding: padding }">
    <div class="container_inner">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: `CRContainer`,
  props: {
    half: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: ``,
    },
  },
  computed: {
    classes() {
      return [
        `container`,
        this.half ? `container-half` : ``,
        this.dark ? `container-dark` : ``,
        this.fullWidth ? `container-full` : ``,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.container {
  &-full {
    max-width: 100%;
    .container_inner {
      max-width: 100%;
      width: 100%;
    }
  }
  &-half {
    padding: 0px;
    max-width: $containerWidth / 2;
  }
  &-dark {
    background-color: rgba($gray, 0.15);
  }
  &_inner {
    margin: auto;
    width: calc(100% - #{$containerOffset});
    max-width: $containerWidth;
    @media screen and (max-width: 1170px) {
      width: calc(100% - #{$containerOffset / 2});
    }
    @media screen and (max-width: 900px) {
      width: calc(100% - #{$containerOffset / 4});
    }
  }
}
</style>
