<template>
  <div>
    <c-row type="flex" justify="space-between">
      <c-select
        v-model="selectedPeriod"
        :options="periods"
        icon-src="icons/calendar.svg"
        max-width="140px"
      ></c-select>
      <comparing-score
        style="min-width: 250px"
        :data="[
          { label: 'Global Rating', value: 9.6, type: 'excellent' },
          { label: 'Performance Score', value: 7.5, type: 'poor' },
        ]"
      ></comparing-score>
    </c-row>
    <c-row type="flex" :gutter="40">
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.audience.title')"
          :subtitle="$t('dashboard.audit.audience.subtitle')"
        >
          <template #header>
            <c-card-status :score="80"></c-card-status>
            <c-tooltip :content="$t('dashboard.audit.audience.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-line
            ref="chart1"
            :dates="audience.dates"
            :data-sets="audience.dataSet"
            :labels="[$t('common.growth')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.videofreq.title')"
          :subtitle="
            replaceData($t('dashboard.audit.videofreq.subtitle'), [
              videoFreq.current,
              videoFreq.average,
            ])
          "
        >
          <template #header>
            <c-card-status :score="40"></c-card-status>
            <c-tooltip :content="$t('dashboard.audit.videofreq.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-line
            ref="chart2"
            :dates="videoFreq.dates"
            :data-sets="videoFreq.dataSet"
            :labels="[$t('common.posted')]"
            background
            :ticks="false"
            :grid="false"
            style="width: 100%; height: 140px"
          ></chart-line>
        </c-card>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="40">
      <c-col :flex="1">
        <c-row type="flex" :bottom-offset="0">
          <c-col :flex="1">
            <c-card
              :title="$t('dashboard.audit.strength.title')"
              :subtitle="$t('dashboard.audit.strength.subtitle')"
            >
              <template #header>
                <c-tooltip :content="$t('dashboard.audit.strength.helper')">
                  <svg-icon
                    style="margin-left: 10px"
                    src="icons/exclamation.svg"
                  ></svg-icon>
                </c-tooltip>
              </template>
              <c-plain-list ref="chart8" :data="strengthAreas"></c-plain-list>
            </c-card>
          </c-col>
        </c-row>
      </c-col>
      <c-col :flex="1">
        <c-row>
          <c-card
            :title="$t('dashboard.audit.engagement.title')"
            :subtitle="
              replaceData($t('dashboard.audit.engagement.subtitle'), [
                engagementRate.current + '%',
                engagementRate.average + '%',
              ])
            "
          >
            <template #header>
              <c-card-status :score="70"></c-card-status>
              <c-tooltip :content="$t('dashboard.audit.engagement.helper')">
                <svg-icon
                  style="margin-left: 10px"
                  src="icons/exclamation.svg"
                ></svg-icon>
              </c-tooltip>
            </template>
            <c-progress
              color="#4CF6F0"
              max-width="100%"
              show-value
              :percentage="engagementRate.current"
            ></c-progress>
          </c-card>
        </c-row>
        <c-card
          :title="$t('dashboard.audit.inact.title')"
          :subtitle="$t('dashboard.audit.inact.subtitle')"
        >
          <template #header>
            <c-tooltip :content="$t('dashboard.audit.inact.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-radar
            ref="chart2"
            :data-sets="intact.dataSets"
            :data-types="intact.dataTypes"
            :labels="intact.labels"
            :height="330"
            style="width: 100%; height: 100%"
          ></chart-radar>
        </c-card>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="40">
      <c-col :flex="1">
        <c-card :title="$t('dashboard.audit.gender.title')">
          <c-pie
            ref="chart3"
            :height="180"
            :colors="[`rgba(255, 0, 80, .6)`, `rgba(64, 201, 245, .6)`]"
            style="display: flex; justify-content: center"
            :data="[
              {
                label: `${$t('common.male')} (${genders.male}%)`,
                value: genders.male,
              },
              {
                label: `${$t('common.female')} (${genders.female}%)`,
                value: genders.female,
              },
            ]"
          ></c-pie>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card :title="$t('dashboard.audit.age.title')">
          <span>
            <c-pie
              ref="chart4"
              :height="180"
              :colors="[
                `rgba(64, 201, 245, 1)`,
                `rgba(64, 201, 245, 0.6)`,
                `rgba(64, 201, 245, .2)`,
              ]"
              style="display: flex; justify-content: center"
              :data="ages"
            ></c-pie>
          </span>
        </c-card>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="40">
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.likes.title')"
          :subtitle="$t('dashboard.audit.likes.subtitle')"
        >
          <template #header>
            <c-tooltip :content="$t('dashboard.audit.likes.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-bar
            ref="chart5"
            :labels="likes.labels"
            :data-sets="likes.dataSets"
            :height="200"
          ></chart-bar>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.viral.title')"
          :subtitle="$t('dashboard.audit.viral.subtitle')"
        >
          <template #header>
            <c-tooltip :content="$t('dashboard.audit.viral.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-scatter
            ref="chart6"
            :labels="viral.labels"
            :data-sets="viral.dataSets"
            :height="200"
          ></chart-scatter>
        </c-card>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="40">
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.challenges.title')"
          :subtitle="$t('dashboard.audit.challenges.subtitle')"
        >
          <template #header>
            <c-tooltip :content="$t('dashboard.audit.challenges.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-bar
            ref="chart7"
            type="horizontalBar"
            :labels="challenges.labels"
            :data-sets="challenges.dataSets"
          ></chart-bar>
        </c-card>
      </c-col>
      <c-col :flex="1">
        <c-card
          :title="$t('dashboard.audit.video.title')"
          :subtitle="$t('dashboard.audit.video.subtitle')"
        >
          <template #header>
            <c-tooltip :content="$t('dashboard.audit.chavideollenges.helper')">
              <svg-icon
                style="margin-left: 10px"
                src="icons/exclamation.svg"
              ></svg-icon>
            </c-tooltip>
          </template>
          <chart-heatmap :data="postsPerTime"></chart-heatmap>
        </c-card>
      </c-col>
    </c-row>
  </div>
</template>
<script>
import CRow from '~/src/components/base/utils/row';
import CCol from '~/src/components/base/utils/column';
import CSelect from '~/src/components/base/select';
import CCard from './../components/card';
import ChartLine from '~/src/components/charts/lines';
import ChartBar from '~/src/components/charts/bar';
import ChartRadar from '~/src/components/charts/radar';
import ChartScatter from '~/src/components/charts/scatter';
import ChartHeatmap from '~/src/components/charts/heatmap';
import CProgress from '~/src/components/base/progress';
import CCardStatus from './../components/card_status';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import ComparingScore from './../components/comparing_score';
import CPlainList from './../components/plain_list';
import { mapGetters, mapState } from 'vuex';
import cTooltip from '~/src/components/base/utils/tooltip';
import CPie from '~/src/components/charts/pie';
export default {
  name: `Audit`,
  components: {
    CRow,
    CCol,
    CSelect,
    CCard,
    ChartLine,
    ChartBar,
    ChartRadar,
    ChartScatter,
    ChartHeatmap,
    CProgress,
    CCardStatus,
    SvgIcon,
    ComparingScore,
    CPlainList,
    cTooltip,
    CPie,
  },
  data() {
    return {
      selectedPeriod: `month`,
    };
  },
  computed: {
    periods() {
      return [
        {
          value: `month`,
          label: `month`,
        },
        {
          value: `quarter`,
          label: `quarter`,
        },
      ];
    },
    ...mapGetters({
      audience: `dashboard/getAudienceGrowth`,
      videoFreq: `dashboard/getVideoFreq`,
    }),
    ...mapState({
      totalScore: (state) => {
        return {
          rating: state.dashboard.audit.rating,
          performance: state.dashboard.audit.performanceScore,
        };
      },
      strengthAreas: (state) => state.dashboard.audit.strengthAreas,
      postsPerTime: (state) => state.dashboard.audit.postsPerTime,
      challenges: (state) => state.dashboard.audit.challenges,
      viral: (state) => state.dashboard.audit.viral,
      likes: (state) => state.dashboard.audit.likes,
      ages: (state) => {
        return state.dashboard.audit.ages.map((item) => {
          const it = Object.assign({}, item);
          it.label = `${item.label} (${item.value}%)`;
          return it;
        });
      },

      genders: (state) => state.dashboard.audit.genders,
      engagementRate: (state) => state.dashboard.audit.engagement,
      intact: (state) => state.dashboard.audit.inact,
    }),
  },
  watch: {
    selectedPeriod() {
      this.fetchData();
    },
  },
  methods: {
    replaceData(str, data = []) {
      let s = str;
      data.forEach((item, idx) => {
        s = s.replace(`$${idx + 1}`, item);
      });
      return s;
    },
    async fetchData() {
      await this.$store.dispatch(`dashboard/fetchAudit`, {
        id: this.$route.params.uuid,
        period: this.selectedPeriod,
      });

      for (let i = 1; i <= 8; i += 1) {
        const chart = this.$refs[`chart${i}`].$children[0];
        if (chart.render) {
          chart.render();
        } else if (this.$refs[`chart${i}`].render) {
          this.$refs[`chart${i}`].render();
        }
      }
    },
  },
};
</script>
