<template>
  <div class="comparing">
    <div
      v-for="(item, key) in data"
      :key="key"
      class="comparing_item"
      :class="`comparing_item-${item.type}`"
    >
      <div class="comparing_item_body">
        <div class="comparing_item-value">
          {{ item.value }}
        </div>
        <div class="comparing_item-label">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `ComparingScore`,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.comparing {
  display: flex;
  align-items: center;
  &_item {
    flex: 1;
    padding: 5px;
    background-color: rgba(93, 173, 236, 0.06);
    min-height: 50px;
    text-align: center;
    &:not(:last-of-type) {
      .comparing_item_body {
        border-right: 2px solid rgba(#a1aabd, .15);
      }
    }
    &:first-of-type {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    &:last-of-type {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    &-excellent {
      .comparing_item-value {
        color: #0ee865;
      }
    }
    &-poor {
      .comparing_item-value {
        color: #e8ad0e;
      }
    }
    &-value {
      font-size: 20px;
      font-weight: 700;
    }
    &-label {
      font-size: 12px;
    }
  }
}
</style>
