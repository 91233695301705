<template>
  <el-checkbox
    :value="value"
    class="cr-checkbox"
    @change="(val) => $emit('change', val)"
    @input="(val) => $emit('input', val)"
  >
    <slot></slot>
  </el-checkbox>
</template>

<script>
import { Checkbox } from 'element-ui';
export default {
  name: `CRCheckbox`,
  components: {
    "el-checkbox": Checkbox,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-checkbox {
  margin-bottom: 10px;
  & /deep/ .el-checkbox {
    &__label {
      font-size: $fontSize-medium;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__input {
      &.is-checked,
      &.is-checked,
      &.is-focus {
        .el-checkbox__inner {
          background-color: $white;
          border-color: $pink;
        }
        & + {
          .el-checkbox__label {
            color: $black;
          }
        }
      }
    }
    &__inner {
      width: 20px;
      height: 20px;
      &::after {
        border-color: $pink;
        height: 11px;
        left: 6px;
        top: 1px;
        width: 5px;
      }
    }
  }
}
</style>
