<template>
  <div class="unauth-wrapper">
    <div class="overflow-hidden">
      <div class="unauth-logo">
        <img
          src="../../assets/images/auth/cliprate_text_logo.svg"
          alt=""
        />
        <div class="unauth-description capital-block">
          {{ $t('login.find') }}
        </div>
      </div>
      <img
        class="unauth-picture"
        src="../../assets/images/auth/authPicture.png"
        alt=""
      />
    </div>
    <div class="form">
      <div class="language-wrapper">
        <img
          :class="[$store.getters.getUserLanguage === 'ru' ? 'active' : '']"
          src="../../assets/images/language/ru.svg"
          alt=""
          @click="setLanguage('ru')"
        />
        <img
          :class="[$store.getters.getUserLanguage === 'en' ? 'active' : '']"
          src="../../assets/images/language/en.svg"
          alt=""
          @click="setLanguage('en')"
        />
      </div>
      <div class="form-header">
        <div
          :class="[$route.name === 'SignIn' ? 'active' : '']"
          class="header-item capital-block"
        >
          {{ $t('login.entrance') }}
        </div>
      </div>
      <div class="form-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `Unauthorization`,
  mounted () {
    document.querySelector(`#app`).classList.add(`unauth`);
  },
  methods: {
    setLanguage (lang) {
      this.$i18n.locale = lang;
      this.$store.commit(`user/setUserLanguage`, lang);
    }
  }
};
</script>
