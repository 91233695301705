<template>
  <div>
    <the-title :title="campaignInfo.title"></the-title>
    <the-campaign-stat></the-campaign-stat>
    <the-audience-stat></the-audience-stat>
    <c-divider></c-divider>
    <c-tabs v-model="activeTab" type="card">
      <tab-pane :label="$t('CampaignPages.view.list')" name="list" disabled>
        <div
          style="
            width: 100%;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          List stub
        </div>
      </tab-pane>
      <tab-pane :label="$t('CampaignPages.view.timeline')" name="timeline">
        <performance-timeline-stat></performance-timeline-stat>
      </tab-pane>
    </c-tabs>
    <c-divider></c-divider>
    <viral-stat></viral-stat>
  </div>
</template>
<script>
import CDivider from '~/src/components/base/utils/divider';
import TheTitle from './title';
import TheCampaignStat from './campaign_stat';
import TheAudienceStat from './audience_stat';
import CTabs from '~/src/components/base/tabs/tabs';
import { TabPane } from 'element-ui';
import PerformanceTimelineStat from './performante_timeline_stat';
import ViralStat from './viral_stat';
export default {
  name: `TheViewCampaign`,
  components: {
    TheTitle,
    TheCampaignStat,
    TheAudienceStat,
    CDivider,
    CTabs,
    TabPane,
    PerformanceTimelineStat,
    ViralStat,
  },
  data() {
    return {
      activeTab: `timeline`,
    };
  },
  computed: {
    campaignInfo() {
      return this.$store.state.campaigns.info;
    },
  },
  async created() {
    await this.$store.dispatch(`campaigns/fetchInfo`, this.$route.params.uuid);
  },
  mounted() {
    this.$emit(`update:throwRouteName`, this.campaignInfo.title.replace(/\s/gi,``));
  }
};
</script>
