<template>
  <div>
    <c-divider
      :text="$t('CampaignPages.step3.collaboration_descr')"
    ></c-divider>
    <collaboration-form
      ref="collab"
      :type-list="typeList"
      :measurment-list="measurmentList"
    ></collaboration-form>
    <c-divider :text="$t('CampaignPages.step3.creators_info')"></c-divider>
    <influencer-form
      ref="influe"
      :location-list="locationList"
      :gender-list="genderList"
      :language-list="languageList"
    ></influencer-form>
    <c-divider :text="$t('common.audience')"></c-divider>
    <audience-form
      ref="audience"
      :ages-list="agesList"
      :gender-list="genderList"
      :country-list="countryList"
    ></audience-form>
    <c-divider text="TikTok"></c-divider>
    <tik-tok-form ref="tiktok" :rate-list="rateList"></tik-tok-form>
    <sized-box height="100"></sized-box>
    <c-row type="flex" justify="end" align="center" :bottom-offset="0">
      <c-button type="text" text-type="dark" max-width="120px" @click="cancel">
        {{ $t("common.cancel") }}
      </c-button>
      <c-button max-width="110px" @click="submit">
        {{ $t("common.publish") }}
      </c-button>
    </c-row>
  </div>
</template>

<script>
import CButton from '~/src/components/base/button';
import SizedBox from '~/src/components/base/utils/sizedBox';
import CDivider from '~/src/components/base/utils/divider';
import CRow from '~/src/components/base/utils/row';
import InfluencerForm from '~/src/views/Forms_folder/Influencer_form';
import AudienceForm from '~/src/views/Forms_folder/Audience_form';
import TikTokForm from '~/src/views/Forms_folder/TikTok_form';
import CollaborationForm from '~/src/views/Forms_folder/Collaboration_form';
import isValidForms from '~/src/utils/isValidForms';
import { mapState } from 'vuex';
export default {
  name: `CampaignStep3`,
  components: {
    CButton,
    SizedBox,
    CDivider,
    CRow,
    InfluencerForm,
    AudienceForm,
    TikTokForm,
    CollaborationForm,
  },
  computed: mapState({
    locationList: (state) => state.filters.locationList,
    genderList: (state) => state.filters.genderList,
    countryList: (state) => state.filters.countryList,
    languageList: (state) => state.filters.languageList,
    rateList: (state) => state.filters.rateList,
    agesList: (state) => state.filters.agesList,
    typeList: (state) => state.filters.typeList,
    measurmentList: (state) => state.filters.measurmentList,
  }),
  methods: {
    cancel() {
      this.$emit(`next`, { page: `step2` });
    },
    async submit() {
      const isValid = await isValidForms(Object.values(this.$refs));
      if (!isValid) return;
      const data = {};
      Object.keys(this.$refs).forEach((key) => {
        data[key] = this.$refs[key].form;
      });
      this.$store.dispatch(`campaigns/addDraft`, {
        type: `creators`,
        ...data,
      });
      this.$nextTick(async (_) => {
        await this.$store.dispatch(`campaigns/publish`);
        this.$router.push({ name: `Campaigns` });
      });
    },
  },
};
</script>
<style lang="sass" scoped>
</style>
