<template>
  <div>
    <cr-checkbox
      :value="value"
      style="margin-bottom: 0px"
      @input="(val) => $emit('input', val)"
    >
      <cr-tag
        :icon="icon"
        size="small"
        transparent
        :disabled="!value"
        :active="value"
      >
        <slot></slot>
      </cr-tag>
    </cr-checkbox>
  </div>
</template>

<script>
import CrCheckbox from './checkbox';
import CrTag from './tag';
export default {
  name: `CRCheckboxTag`,
  components: {
    CrCheckbox,
    CrTag,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: ``,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
</style>
