<template>
  <el-date-picker
    class="cr-datePicker"
    :class="classes"
    :style="{ maxWidth: maxWidth }"
    :value="value"
    v-bind="$attrs"
    :type="type"
    prefix-icon="cr-calendar-icon"
    :clearable="clearable"
    :range-separator="rangeSeparator"
    :start-placeholder="$t('common.start_date')"
    :end-placeholder="$t('common.end_date')"
    @input="handleInput"
  >
  </el-date-picker>
</template>

<script>
import { DatePicker } from 'element-ui';
export default {
  name: `CRDatePicker`,
  components: {
    "el-date-picker": DatePicker,
  },
  props: {
    maxWidth: {
      type: String,
      default: ``,
    },
    value: {
      type: [String, Number, Date, Array],
      default: ``,
    },
    reverseIcon: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: `default`,
    },
    type: {
      type: String,
      default: `date`
    },
    rangeSeparator: {
      type: String,
      default: `–`
    }
  },
  computed: {
    classes() {
      return [
        this.reverseIcon ? `cr-datePicker-reverse` : ``,
        this.clearable ? `cr-clearable` : ``,
        `cr-datePicker-${this.theme}`,
      ];
    },
  },
  methods: {
    handleInput(val) {
      this.$emit(`input`, val);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-datePicker {
  &.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: $gray;
    /deep/ {
      .el-input {
        &__icon {
          line-height: 25px;
        }
      }
      .el-range-separator {
        line-height: 25px;
        color: $gray;
      }
    }
  }
  &.cr-datePicker {
    &-default {
      /deep/ .el-input {
        &__inner {
          border-color: $gray;
        }
      }
    }
    &-dark {
      /deep/ .el-input {
        &__inner {
          border-color: $black;
        }
      }
    }
  }
  /deep/ .el-input {
    &__icon {
      line-height: 30px;
    }
    &__inner {
      outline: none;
      padding: 3px 10px;
      width: 100%;
      height: 30px;
      line-height: 30px;
      border: 1px solid;
      border-radius: $border-radius;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid $pink;
      }
      &::placeholder {
        color: $gray;
        font-size: 14px;
      }
      &-square {
        border-radius: 0px;
      }
    }
  }
  &-reverse {
    /deep/ {
      .el-input {
        &__prefix {
          left: auto;
          right: 5px;
        }
      }
      .cr-calendar-icon {
        display: inline-block;
        &::before {
          content: "";
          background-image: url("./../../assets/images/icons/calendar.svg");
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
