<template>
  <div
    class="cr-cube-grid"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <div class="cr-cube cr-cube1"></div>
    <div class="cr-cube cr-cube2"></div>
    <div class="cr-cube cr-cube3"></div>
    <div class="cr-cube cr-cube4"></div>
    <div class="cr-cube cr-cube5"></div>
    <div class="cr-cube cr-cube6"></div>
    <div class="cr-cube cr-cube7"></div>
    <div class="cr-cube cr-cube8"></div>
    <div class="cr-cube cr-cube9"></div>
  </div>
</template>
<script>
export default {
  name: `CRLoader`,
  props: {
    height: {
      type: Number,
      default: 10,
    },
    width: {
      type: Number,
      default: 10,
    },
  },
};
</script>
<style lang="scss" scoped>
.cr-cube-grid {
}

.cr-cube-grid .cr-cube {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  -webkit-animation: cr-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: cr-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.cr-cube-grid .cr-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cr-cube-grid .cr-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cr-cube-grid .cr-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.cr-cube-grid .cr-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cr-cube-grid .cr-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cr-cube-grid .cr-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cr-cube-grid .cr-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.cr-cube-grid .cr-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cr-cube-grid .cr-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes cr-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes cr-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
</style>
