<template>
  <div>
    <c-divider
      :text="$t('CampaignPages.step2.campaign_info.title')"
    ></c-divider>
    <c-form ref="form" :model="form" :rules="rules" label-position="top">
      <c-row type="flex" :gutter="38" justify="start">
        <c-col>
          <c-form-item
            prop="campaign.name"
            :label="$t('CampaignPages.step2.campaign_info.form.name')"
          >
            <c-input
              v-model="form.campaign.name"
              style="width: 350px"
              min-width="400px"
              :placeholder="`${$t('common.challenges')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item
            prop="campaign.type"
            :label="$t('CampaignPages.step2.campaign_info.form.type')"
          >
            <c-select
              v-model="form.campaign.type"
              style="width: 350px"
              min-width="400px"
              :options="typeList"
              :placeholder="$t('common.type')"
            >
            </c-select>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item
            prop="campaign.measurment"
            :label="$t('CampaignPages.step2.campaign_info.form.measurement')"
          >
            <c-select
              v-model="form.campaign.measurment"
              :options="measurmentList"
              :placeholder="$t('common.measurment')"
            >
            </c-select>
          </c-form-item>
        </c-col>
      </c-row>
      <c-row type="flex" :gutter="38">
        <c-col>
          <c-form-item
            prop="campaign.description"
            :label="$t('CampaignPages.step2.campaign_info.form.description')"
          >
            <c-textarea
              v-model="form.campaign.description"
              style="width: 450px"
              max-width="450px"
              :placeholder="`#${$t('common.challenges')}`"
            >
            </c-textarea>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item
            prop="campaign.hashtag"
            :label="$t('CampaignPages.step2.campaign_info.form.hashtag')"
          >
            <c-input
              v-model="tagManager"
              min-width="400px"
              :placeholder="`#${$t('common.challenges')}`"
              @enter="submitTag"
            ></c-input>
            <div>
              <c-tag
                v-for="(item, key) in form.campaign.hashtags"
                :key="key"
                closable
                size="small"
                @close="deleteTag(key)"
              >
                {{ item }}
              </c-tag>
            </div>
          </c-form-item>
        </c-col>
      </c-row>
      <c-divider
        :text="$t('CampaignPages.step2.contact_info.title')"
      ></c-divider>
      <c-row type="flex" :gutter="38">
        <c-col>
          <c-form-item
            prop="contacts.name"
            :label="$t('CampaignPages.step2.contact_info.form.contact')"
          >
            <c-input
              v-model="form.contacts.name"
              style="width: 350px"
              min-width="400px"
              :placeholder="`#${$t('common.challenges')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item
            prop="contacts.publisher"
            :label="$t('CampaignPages.step2.contact_info.form.publisher')"
          >
            <c-input
              v-model="form.contacts.publisher"
              style="width: 350px"
              min-width="400px"
              :placeholder="`${$t('common.challenges')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
        <c-button type="text" text-type="pink" disabled>
          +{{ $t("common.more_info") }}
        </c-button>
      </c-row>
      <c-divider :text="$t('CampaignPages.step2.brand_info.title')"></c-divider>
      <c-row type="flex" :gutter="38">
        <c-col>
          <c-form-item
            prop="brand.name"
            :label="$t('CampaignPages.step2.brand_info.form.name')"
          >
            <c-input
              v-model="form.brand.name"
              style="width: 350px"
              min-width="400px"
              :placeholder="`${$t('common.any')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item
            prop="brand.website"
            :label="$t('CampaignPages.step2.brand_info.form.website')"
          >
            <c-input
              v-model="form.brand.website"
              style="width: 350px"
              min-width="400px"
              :placeholder="`${$t('common.any')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
      </c-row>
      <c-row>
        <c-col>
          <c-form-item
            prop="brand.description"
            :label="$t('CampaignPages.step2.brand_info.form.description')"
          >
            <c-input
              v-model="form.brand.description"
              style="width: 350px"
              min-width="400px"
              :placeholder="`#${$t('common.challenges')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
      </c-row>
      <c-row type="flex" :gutter="38">
        <c-col>
          <c-form-item
            prop="brand.product"
            :label="$t('CampaignPages.step2.brand_info.form.product')"
          >
            <c-input
              v-model="form.brand.product"
              style="width: 350px"
              min-width="400px"
              :placeholder="`#${$t('common.any')}`"
            >
            </c-input>
          </c-form-item>
        </c-col>
        <c-col>
          <c-form-item prop="brand.industry" :label="$t('common.industry')">
            <c-select
              v-model="form.brand.industry"
              style="width: 350px"
              min-width="400px"
              :options="industryList"
              :placeholder="$t('common.type')"
            >
            </c-select>
          </c-form-item>
        </c-col>
      </c-row>
      <c-divider></c-divider>
      <c-button
        disabled
        type="pink"
        text-type="dark"
        plain
        icon-src="icons/addSquare.svg"
        max-width="200px"
      >
        {{ $t("CampaignPages.advanced") }}
      </c-button>
      <sized-box height="100px"></sized-box>
      <c-row type="flex" justify="end" align="center" :bottom-offset="0">
        <c-button
          type="text"
          text-type="dark"
          max-width="120px"
          @click="cancel"
        >
          {{ $t("common.cancel") }}
        </c-button>
        <c-button max-width="100px" @click="submit">
          {{ $t("common.next") }}
        </c-button>
      </c-row>
    </c-form>
  </div>
</template>

<script>
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CButton from '~/src/components/base/button';
import CInput from '~/src/components/base/input';
import CSelect from '~/src/components/base/select';
import SizedBox from '~/src/components/base/utils/sizedBox';
import CDivider from '~/src/components/base/utils/divider';
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
import CTextarea from '~/src/components/base/textarea';
import CTag from '~/src/components/base/tag';
import {mapState} from 'vuex';

export default {
  name: `CampaignStep2`,
  components: {
    CForm,
    CFormItem,
    CButton,
    CInput,
    CSelect,
    SizedBox,
    CDivider,
    CRow,
    CCol,
    CTextarea,
    CTag
  },
  data() {
    return {
      rules: {
        "campaign.name": [
          {
            required: true,
            message: `Please input campaign name`,
            trigger: `blur`,
          },
        ],
      },
      tagManager: ``,
      form: {
        campaign: {
          name: ``,
          type: ``,
          measurment: ``,
          description: ``,
          hashtags: [],
        },
        contacts: {
          name: ``,
          publisher: ``,
        },
        brand: {
          name: ``,
          website: ``,
          description: ``,
          product: ``,
          industry: ``,
        },
      },
    };
  },
  computed: mapState({
    typeList: (state) => state.filters.typeList,
    measurmentList: (state) => state.filters.measurmentList,
    industryList: (state) => state.filters.measurmentList,
  }),
  mounted() {
    if (Object.keys(this.$store.state.campaigns.creating.details)) {
      Object.assign(this.form, this.$store.state.campaigns.creating.details);
    }
  },
  methods: {
    submitTag() {
      this.form.campaign.hashtags.push(this.tagManager);
      this.tagManager = ``;
    },
    deleteTag(index) {
      this.form.campaign.hashtags.splice(index, 1);
    },
    cancel() {
      this.$emit(`next`, { page: `step1` });
    },
    submit() {
      this.$refs[`form`].form.validate((valid) => {
        if (valid) this.next();
        return false;
      });
    },
    next() {
      this.$store.dispatch(`campaigns/addDraft`, {
        type: `details`,
        ...this.form,
      });
      this.$emit(`next`, { page: `step3` });
    },
  },
};
</script>
