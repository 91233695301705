<template>
  <div>
    <c-row>
      <c-radio-group v-model="checked">
        <c-radio-button label="latest">
          {{ $t("dashboard.videos.latest") }}
        </c-radio-button>
        <c-radio-button label="popular">
          {{ $t("dashboard.videos.popular") }}
        </c-radio-button>
      </c-radio-group>
    </c-row>
    <videos-table :table-data="tableData"></videos-table>
  </div>
</template>
<script>
import CRadioButton from '~/src/components/base/radio/radioButton';
import CRadioGroup from '~/src/components/base/radio/radioGroup';
import CRow from '~/src/components/base/utils/row';
import videosTable from './../components/videos_table';

export default {
  name: `Videos`,
  components: {
    CRadioButton,
    CRadioGroup,
    CRow,
    videosTable,
  },
  data() {
    return {
      checked: `popular`,
    };
  },
  computed: {
    tableData() {
      return this.$store.state.dashboard.videos;
    },
  },
  watch: {
    checked: function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch(`dashboard/fetchVideo`, {
        id: this.$route.params.uuid,
        type: this.checked
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
