import to from 'await-to-js';
import influencers from '~/src/mocks/influencers.js';

const state = {
  data: [],
};
const getters = {
};
const mutations = {
  SET_DATA(state, data=[]) {
    state.data = data;
  },
  ADD_DATA(state, obj) {
    state.data.push(obj);
  },
  REMOVE_DATA(state, id) {
    const d = state.data.filter(item => parseInt(item.id)!==parseInt(id));
    state.data = [...d];
  }
};
const actions = {
  async fetch({commit}) {
    /* const [err,res] = await to(fetch()); */
    const [err,res] = [null,];
    if(!err) commit(`SET_DATA`, res);
    return [err,res];
  },
  subscribe({commit, state}, id) {
    const d = influencers.filter(item => parseInt(item.id)===parseInt(id));
    if(d.length) {
      commit(`ADD_DATA`, d[0]);
    }
  },
  unsubscribe({commit}, id) {
    commit(`REMOVE_DATA`, id);
  }
};
  
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
  
