<template>
  <c-form ref="form" :model="form" label-position="top">
    <c-row type="flex" :gutter="38" :bottom-offset="0">
      <c-col>
        <c-form-item
          prop="gender"
          :label="$t('FormViews.audience.gender')"
          label-icon="icons/gender.svg"
        >
          <c-select
            v-model="form.gender"
            :options="genderList"
            :placeholder="$t('common.any')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="age"
          :label="$t('FormViews.audience.age')"
          label-icon="icons/sandClock.svg"
        >
          <c-select
            v-model="form.age"
            :options="agesList"
            :placeholder="$t('common.any')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="location"
          :label="$t('FormViews.audience.location')"
          label-icon="icons/location.svg"
        >
          <c-select
            v-model="form.location"
            :options="countryList"
            :placeholder="$t('common.country')"
          >
          </c-select>
          <c-button
            style="margin-top: 10px"
            type="text"
            text-type="pink"
            disabled
          >
            +{{ $t("common.location") }}
          </c-button>
        </c-form-item>
      </c-col>
    </c-row>
  </c-form>
</template>

<script>
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CButton from '~/src/components/base/button';
import CSelect from '~/src/components/base/select';
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
export default {
  name: `AudienceForm`,
  components: {
    CForm,
    CFormItem,
    CButton,
    CSelect,
    CRow,
    CCol
  },
  props: {
    genderList: {
      type: Array,
      default: () => [],
    },
    countryList: {
      type: Array,
      default: () => [],
    },
    agesList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        gender: ``,
        age: ``,
        location: ``,
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(`change`);
      },
    },
  },
};
</script>
