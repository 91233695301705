<template>
  <div class="notification-wrapper">
    <div
      class="cross"
      @click="$store.dispatch('notification/closeNotification')"
    ></div>
    <div class="notification-body">
      <div class="icon">
        <img
          :src="$store.getters.getNotificationIcon"
          alt=""
        />
      </div>
      <div class="notification-text">
        <span class="title">{{ $store.getters['notification/getNotificationTitle'] }}</span>
        <br />
        <span class="description">{{ $store.getters['notification/getNotificationMessage'] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `Notification`
};
</script>
