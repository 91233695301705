const percent = (value, percent) => value * percent;

const getHistory = (length = 7, min = 10, max = 100, round = true) => {
  const value = Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
	    return {
	      date: (new Date(Date.now()-(864e5*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
	      value: round ? Math.round(value + i * value * 0.01) : Number.parseFloat((value + i * value * 0.01).toFixed(2)),
	    };
  });
};

const getAvgHistory = (length = 7, min = 10, max = 100, round = true) => {
  const value = Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    const signed = [-1,1][Math.random()*2|0];
    const step = (Math.random() * (0.5 - 0.01) + 0.01);
    return {
      date: (new Date(Date.now()-(864e5*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ? Math.round(value + (value * step * signed)) : Number.parseFloat((value + (value * step * signed)).toFixed(2)),
    };
  });
};

const getHistoryInterval = (length = 7, interval = 864e5, min = 10, max = 100, round = true) => {
  const value = min;//Math.random() * (max - min) + min;
  return Array.from(new Array(length), (v, i) => {
    return {
      date: (new Date(Date.now()-(interval*(length-i))).toJSON()).split(`T`).shift(), // `2020-01-01`,
      value: round ?
        Math.round(value + i * value * Math.random().toFixed(4) / 1000) :
        Number.parseFloat((value + i * value * Math.random().toFixed(4) / 1000).toFixed(2)),
    };
  });
};

const {default:influencers} = require(`./influencers`);

// baseStat.views[baseStat.length-1].value
module.exports.statistics = {
  // 6830197954876276742: {
  //   hours: {
  //     followers: getHistoryInterval(24, 36e5,2084931, 2087010),
  //     avg_views: getAvgHistory(24, 100000, 5000000),
  //     avg_likes: getAvgHistory(24, 1500, 15000),
  //     avg_comments: getAvgHistory(24, 300, 900),
  //     avg_shares: getAvgHistory(24, 80, 555),
  //
  //   },
  //   week: {
  //     followers: getHistoryInterval(7, 864e5, 2084931, 2087010),
  //     avg_views: getAvgHistory(7, 100000, 5000000),
  //     avg_likes: getAvgHistory(7, 1500, 15000),
  //     avg_comments: getAvgHistory(7, 300, 900),
  //     avg_shares: getAvgHistory(7, 80, 555),
  //   },
  //   month: {
  //     followers: getHistoryInterval(30, 864e5, 2084931, 2087010),
  //     avg_views: getAvgHistory(30, 100000, 5000000),
  //     avg_likes: getAvgHistory(30, 1500, 15000),
  //     avg_comments: getAvgHistory(30, 300, 900),
  //     avg_shares: getAvgHistory(30, 80, 555),
  //   }
  // }
};
module.exports.audit = {};//require(`./dashboard/audit`);
module.exports.video = {};//require(`./dashboard/video`);

const audit = require(`./dashboard/audit`);
const video = require(`./dashboard/video`);

for(const influencer of influencers) {
  // console.log(influencer);
  module.exports.statistics[influencer.id] = {
    hours: {
      followers: getHistoryInterval(24, 36e5,
        influencer.followers - percent(influencer.followers, 36e5/1e10), influencer.followers),
      avg_views: getAvgHistory(24, 100000, 5000000),
      avg_likes: getAvgHistory(24, 1500, 15000),
      avg_comments: getAvgHistory(24, 300, 900),
      avg_shares: getAvgHistory(24, 80, 555),

    },
    week: {
      followers: getHistoryInterval(7, 864e5,
        influencer.followers - percent(influencer.followers, 864e5/1e10), influencer.followers),
      avg_views: getAvgHistory(7, 100000, 5000000),
      avg_likes: getAvgHistory(7, 1500, 15000),
      avg_comments: getAvgHistory(7, 300, 900),
      avg_shares: getAvgHistory(7, 80, 555),
    },
    month: {
      followers: getHistoryInterval(30, 864e5,
        influencer.followers - percent(influencer.followers, 864e5/1e9), influencer.followers),
      avg_views: getAvgHistory(30, 100000, 5000000),
      avg_likes: getAvgHistory(30, 1500, 15000),
      avg_comments: getAvgHistory(30, 300, 900),
      avg_shares: getAvgHistory(30, 80, 555),
    }
  };
  module.exports.audit[influencer.id] = audit;
  module.exports.video[influencer.id] = video;
}
