<template>
  <div class="graph-wrapper">
    <div v-for="n in 24" :key="n * 11 + 'D'" class="row">
      <div class="cell time">
        {{ getTime(n) }}
      </div>
      <div
        v-for="k in 7"
        :key="k * 10 + 'C'"
        :class="[isCellActive(n, k) ? 'pink' : 'gray']"
        class="cell"
      ></div>
    </div>
    <div class="row">
      <div class="cell time"></div>
      <div class="weekday">
        {{ $t("graph.monday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.tuesday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.wednesday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.thursday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.friday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.saturday") }}
      </div>
      <div class="weekday">
        {{ $t("graph.sunday") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `CHeatMap`,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getTime(n) {
      let zero = ``;
      if (n <= 10) {
        zero = `0`;
      }
      return zero + (n - 1) + `:00`;
    },
    isCellActive(time, day) {
      return !!this.data.find((value) => {
        const date = new Date(value.timestamp);
        if (time - 1 === date.getUTCHours()) {
          const weekday = date.getUTCDay();
          switch (true) {
          case weekday === 0 && day === 7:
            return true;
          case weekday > 0:
            return weekday === day;
          default:
            return false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-wrapper {
  width: 100%;
}
.row {
  display: flex;
  margin: 2px 0;
  padding: 0;
}
.cell {
  width: 100%;
  height: 10px;
  margin: 1px;
  border-radius: 2px;
  &.time {
    flex-shrink: 0;
    width: 35px;
    text-align: right;
    color: #b1b8c7;
    font-size: 12px;
    line-height: 1;
    font-family: Muli-Regular, sans-serif;
    margin-right: 5px;
  }
  &.gray {
    background: rgba(161, 170, 189, 0.25);
  }
  &.pink {
    background: rgba(255, 0, 80, .4);
  }
}
.weekday {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 5px;
  font-size: 12px;
}
</style>
