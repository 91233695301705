<template>
  <div class="cr-tabs">
    <el-tabs v-bind="$attrs" :lazy="false" @input="(val) => $emit('input', val)">
      <slot></slot>
    </el-tabs>
  </div>
</template>
<script>
import { Tabs } from 'element-ui';
export default {
  name: `CTabs`,
  components: {
    "el-tabs": Tabs,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-tabs {
  /deep/ .el-tabs {
    &.el-tabs--card {
      .el-tabs {
        &__header {
          border-bottom: 0px;
        }
        &__nav {
          border: 0px;
          .el-tabs {
            &__item {
              border: 1px solid rgba($gray, 0.5);
              font-weight: normal;
              font-size: $fontSize-medium;
              transition: all 0.2s ease-out;
              height: 30px;
              line-height: 30px;
              &.is-active {
                background: rgba(0, 247, 239, 0.15);
                border: 1px solid $mint;
                color: $black;
              }
              &:hover:not(.is-disabled) {
                color: $black;
                border: 1px solid $mint;
              }
            }
          }
        }
      }
    }
    .el-tabs__header {
      .el-tabs__item {
        font-size: 15px;
        font-weight: 800;
        &.is-active,
        &:hover:not(.is-disabled) {
          color: $pink;
        }
      }
      .el-tabs__active-bar {
        background-color: $pink;
      }
    }
    .el-tabs__content {
      overflow: unset;
    }
  }
}
</style>
