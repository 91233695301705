import axios from 'axios';
import store from '~/src/store';
import config from '~/config';
import router from '~/src/router';
const service = axios.create({
  baseURL: config.API() || process.env.VUE_APP_BASE_URL,
  timeout: 5000 * 6
});
service.interceptors.request.use(
  config => {
    config.headers[`Authorization`] = `Bearer ${store.state.auth.token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code > 399) {
      const er = res.error || res.errors;
      return er ? Promise.reject(er) : Promise.reject(`Something went wrong ${res.code}`);
    } else {
      return res;
    }
  },
  error => {
    if (error.name === `Error` && error.message === `Network Error`) {
      store.dispatch(`notification/showNotification`, {
        title: error.name,
        message: error.message,
        icon: `warning`,
        time: 6000
      });
      return Promise.reject(error);
    } else {
      switch (true) {
      case error.response.status === 401:
        store.dispatch(`auth/logout`);
        router.push(`/login`);
        store.dispatch(`notification/showNotification`, {
          title: error.response.data.type,
          message: error.response.data.message,
          icon: `warning`,
          time: 6000
        });
        break;
      case error.response.status === 403:
        store.dispatch(`notification/showNotification`, {
          title: error.response.data.type,
          message: error.response.data.message,
          icon: `warning`,
          time: 6000
        });
        break;
      case error.response.status === 404:
        console.log(error.response);
        break;
      case error.response.status === 422:
        if (error.response.data.type === `VALIDATION_ERROR`) {
          store.dispatch(`notification/showNotification`, {
            title: error.response.data.message,
            message: error.response.data.data[0].message,
            icon: `warning`,
            time: 6000
          });
        } else {
          store.dispatch(`notification/showNotification`, {
            title: error.response.data.type,
            message: error.response.data.message,
            icon: `warning`,
            time: 6000
          });
        }
        break;
      case error.response.status === 500:
        store.dispatch(`notification/showNotification`, {
          title: `Internal server error`,
          message: `Please try later`,
          icon: `warning`,
          time: 6000
        });
      }
      return Promise.reject(error);
    }
  }
);

export default service;
