import { Radar, mixins } from 'vue-chartjs';
import merge from 'lodash.merge';
const { reactiveProp } = mixins;

const defaultOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  scale: {
    gridLines: {
      display: false
    },
    angleLines: {
      display: true
    },
    ticks: {
      beginAtZero: true,
      display: false
    }
  },
  tooltips: {
    displayColors: false,
    mode: `nearest`,
    intersect: false,
    borderColor: `rgba(177, 184, 199, 1)`,
    caretPadding: 10,
    caretSize: 0,
    cornerRadius: 2,
    borderWidth: 1,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    titleFontColor: `rgba(0,0,0,1)`,
    titleFontStyle: `normal`,
    bodyFontColor: `rgba(177, 184, 199, 1)`,
    footerFontColor: `rgba(0,0,0,1)`
  },
  hover: {
    mode: `nearest`,
    intersect: false,
  },
};


export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    render() {
      this.renderChart(this.chartData, merge(this.options, defaultOptions));
    }
  },
  watch: {
    options () {
      this.render();
    }
  },
  mounted () {
    setTimeout(_ => {
      this.render();
    }, 500);
  }
};
