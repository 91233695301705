<template>
  <div class="cr-card">
    <h3 v-if="title" class="cr-card_title">
      <span class="cr-card_title-text">{{ title }}</span><slot name="header"></slot>
    </h3>
    <div v-if="subtitle" class="cr-card_subtitle">
      {{ subtitle }}
    </div>
    <div class="cr-card_body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: `CCard`,
  props: {
    title: {
      type: String,
      default: ``,
    },
    subtitle: {
      type: String,
      default: ``,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-card {
  margin-top: 10px;
  border: 1px solid rgba(161, 170, 189, 0.15);
  border-radius: 5px;
  padding: 10px;
  &_title {
    margin: 0px;
    display: flex;
    align-items: center;
    &-text {
      margin-right: 10px;
    }
  }
  &_subtitle {
    font-size: 15px;
    color: #A1AABD;
  }
  &_body {
    margin-top: 10px;
  }
}
</style>
