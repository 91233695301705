<template>
  <div class="campaign_name_wrapper">
    <div class="campaign_name">
      <div class="campaign_name-title">
        {{ title }}
      </div>
      <div class="campaign_name-date_wrapper">
        <svg-icon src="icons/clock.svg"></svg-icon>
        <span class="campaign_name-date">{{ formatDate(date) }}</span>
      </div>
      <div class="campaign_name_messages">
        <span class="campaign_name_messages-badge">{{ messages }}</span>
        <span class="campaign_name_messages-text">{{
          $t("common.messages")
        }}</span>
      </div>
    </div>
    <campaign-menu></campaign-menu>
  </div>
</template>
<script>
import CampaignMenu from './menu';
import formatDate from '~/src/utils/formatDate';
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `CampaignName`,
  components: {
    SvgIcon,
    CampaignMenu,
  },
  props: {
    title: {
      type: String,
      default: ``,
    },
    date: {
      type: Number,
      default: 0,
    },
    messages: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatDate: formatDate
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.campaign_name {
  &_wrapper {
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.0794089);
    align-items: center;
    padding: 10px 0px;
  }
  &-title {
    font-size: $fontSize-medium;
  }
  &-date {
    font-size: 12px;
    color: $gray;
    margin-left: 5px;
    &_wrapper {
      display: flex;
    }
  }
  &_messages {
    &-badge {
      max-width: 18px;
      max-height: 13px;
      background-color: $pink;
      color: $white;
      border-radius: 3px;
      font-size: 10px;
      padding: 0px 3px;
    }
    &-text {
      font-size: 12px;
    }
  }
}
</style>
