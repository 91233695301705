<template>
  <div>
    404 not found here
  </div>
</template>

<script>
export default {
  name: `NotFound`
};
</script>
