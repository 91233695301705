<template>
  <footer class="footer">
    <c-divider :no-space="true"></c-divider>
    <c-container class="footer-container">
      <c-row type="flex" justify="space-between">
        <div>@2020 <b>TTStats</b></div>
        <c-button type="text" text-type="pink" font-size="13px">
          {{ $t("common.feedback") }}
        </c-button>
      </c-row>
    </c-container>
  </footer>
</template>
<script>
import CContainer from '~/src/components/base/container';
import CRow from '~/src/components/base/utils/row';
import CDivider from '~/src/components/base/utils/divider';
import CButton from '~/src/components/base/button';
export default {
  name: `CampaignName`,
  components: {
    CContainer,
    CRow,
    CDivider,
    CButton,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.footer {
  width: 100%;
  font-size: 13px;
  &-container {
    padding: 15px 0px;
  }
}
</style>
