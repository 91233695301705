<template>
  <div>
    <radar :height="height" :options="options" :chart-data="chartData"></radar>
  </div>
</template>
<script>
import Radar from './../graphs/radar.js';

const commonStyles = {
  borderWidth: 2,
  pointRadius: 0,
};

const styles = [
  {
    ...commonStyles,
    borderColor: `#00F7EF`,
    backgroundColor: `rgba(0, 247, 239, 0.1)`,
  },
  {
    ...commonStyles,
    borderColor: `rgba(255, 0, 80, 1)`,
    backgroundColor: `rgba(255, 0, 80, 0.1)`,
  },
];

export default {
  name: `CRadar`,
  components: {
    Radar,
  },
  props: {
    dataSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    dataTypes: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      options: {
        tooltips: {
          callbacks: {
            title: (value, data) => {
              return ``;
            },
            footer: (values) => {
              return ``;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const dataSet = [];
      const labels = this.labels;
      this.dataSets.forEach((data, idx) => {
        dataSet[idx] = {
          label: this.dataTypes[idx],
          order: idx,
          data: data,
          ...styles[idx],
        };
      });

      return {
        datasets: dataSet,
        labels: labels,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
