<template functional>
  <span class="dash"> — </span>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.dash {
  color: $gray;
  margin: 0px 10px;
}
</style>
