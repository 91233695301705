<template>
  <div class="cr-radio-button">
    <el-radio-button
      :value="value"
      :label="label"
      @input="(label) => this.$emit(`input`, label)"
    >
      <slot></slot>
    </el-radio-button>
  </div>
</template>

<script>
import { RadioButton } from 'element-ui';
export default {
  name: `CRRadioButton`,
  components: {
    "el-radio-button": RadioButton,
  },
  props: {
    label: {
      type: [String, Number],
      default: ``,
    },
    value: {
      type: [String, Number],
      default: ``,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-radio-button {
  position: relative;
  display: inline-block;
  outline: none;
  & /deep/ {
    .el-radio-button {
      &.is-active {
        .el-radio-button__inner {
          background: rgba(0, 247, 239, 0.15);
          border: 1px solid $mint;
          color: $black;
        }
      }
      &:first-child:last-child {
        .el-radio-button {
          &__inner {
            border-radius: 0px;
          }
        }
      }
      &__inner {
        border-radius: 0px;
        border: 1px solid rgba($gray, 0.5);
        font-weight: normal;
        font-size: $fontSize-medium;
        transition: all 0.2s ease-out;
        height: 30px;
        line-height: 30px;
        padding: 0px 15px;
        &:hover {
          color: $black;
          border: 1px solid $mint;
        }
      }
    }
  }
}
</style>
