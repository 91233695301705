<template>
  <div>
    <div>
      <h3>
        <b style="color: #eb5757; margin-right: 10px">{{ viralStatus }}</b>{{ $t("CampaignPages.viral_status") }}
      </h3>
      <div class="text-gray">
        {{ $t("CampaignPages.view.representing") }}
      </div>
    </div>
    <chart-line
      style="width: 100%; height: 250px; margin-top: 20px"
      :dates="dates"
      :data-sets="dataSets"
      :bubbles="bubbles"
    ></chart-line>
  </div>
</template>
<script>
import ChartLine from '~/src/components/charts/lines';
export default {
  name: `ViralStat`,
  components: {
    ChartLine,
  },
  computed: {
    stat() {
      return this.$store.state.campaigns.info.viral;
    },
    viralStatus() {
      return this.stat.total;
    },
    dates() {
      return this.stat.first.map((item) => item.date);
    },
    dataSets() {
      const d = [[], []];
      this.stat.first.forEach((item, idx) => {
        d[0].push(item.value);
        d[1].push(this.stat.second[idx].value);
      });
      return d;
    },
    bubbles() {
      return [this.stat.bubbles];
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
