<template>
  <textarea
    ref="input"
    class="textarea"
    :rows="rows"
    :style="{ maxWidth: maxWidth }"
    :value="value"
    :placeholder="placeholder"
    @focus="changePlaceholder('')"
    @blur="changePlaceholder(placeholder)"
    @input="handleInput"
    @keypress.enter="handleEnter"
  >
  </textarea>
</template>

<script>
export default {
  name: `CRTextArea`,
  props: {
    maxWidth: {
      type: String,
      default: `300px`,
    },
    value: {
      type: [String, Number],
      default: ``,
    },
    placeholder: {
      type: String,
      default: `Input value`,
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    handleInput(val) {
      this.$emit(`input`, val.target.value);
    },
    handleEnter() {
      this.$emit(`enter`);
    },
    changePlaceholder(val) {
      this.$refs[`input`].placeholder = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.textarea {
  position: relative;
  width: 100%;
  max-width: 300px;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 13px 10px;
  background-color: #ffffff;
  outline: none;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $pink;
  }
  &::placeholder {
    color: $gray;
    font-size: 14px;
  }
}
</style>
