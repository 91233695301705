<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        v-if="$store.getters['notification/getPreloaderState']"
        id="loadingProgressG"
        :key="'preloader'"
      >
        <div class="loadingProgressG"></div>
      </div>

      <div v-else>
        <c-container dark>
          <h2 class="text-center">
            {{ $t("dashboard.findDescription") }}
          </h2>
          <c-row type="flex" :bottom-offset="0" style="padding-bottom: 20px">
            <c-input
              style="height: 40px"
              prefix-icon="icons/search.svg"
              :placeholder="$t('dashboard.findPlaceholder')"
              max-width="100%"
              theme="dark"
            ></c-input>
          </c-row>
        </c-container>
        <detailed-table :table-data="tableData" @action="unsubscribe"></detailed-table>
      </div>
    </transition>
  </div>
</template>

<script>
import detailedTable from '~/src/views/Discovery/table';
import CInput from '~/src/components/base/input';
import CRow from '~/src/components/base/utils/row';
import CContainer from '~/src/components/base/container';
export default {
  name: `Index`,
  components: {
    detailedTable,
    CInput,
    CRow,
    CContainer,
  },
  data() {
    return {
      username: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 100,
      },
      chartOptions: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: `index`,
          position: `nearest`,
          xPadding: 4,
          yPadding: 4,
          caretSize: 0,
          caretPadding: 0,
          bodyFontSize: 10,
          displayColors: false,
          intersect: false,
          callbacks: {
            title: function () {
              return ``;
            },
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: false,
            },
          ],
        },
      },
    };
  },
  computed: {
    tableData() {
      return this.$store.state.tracking.data;
    },
  },
  async created() {
    await this.$store.commit(`notification/setPreloader`, true);
    //await this.$store.dispatch(`tracking/fetch`);
    await this.$store.commit(`notification/setPreloader`, false);
  },
  methods: {
    tableHeader() {
      return `table-header`;
    },
    async unsubscribe(row) {
      await this.$store.dispatch(`tracking/unsubscribe`, row.id);
    },
  },
};
</script>
