const random = (min, max, floating = false) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

module.exports.campaignGenerator = ({name, id}) => {
  return {
    id: id,
    type: 1,
    type_text: `Brand Awareness`,
    campaign_name: name,
    campaign_date: new Date().getTime(),
    messages: random(5,20),
    status: 1,
    posted: {
      actual: random(5,10),
      plan: random(15, 20),
      amount: random(10,15),
    },
    viral: {
      actual: random(5,15),
      plan: random(15, 20),
    },
    views: random(50000, 200000),
    prevviews: random(50000, 200000),
    comments: random(1000, 1500),
    prevcomments: random(1000, 1500),
    roi: random(1, 100),
    cpe: random(1, 100),
    total_spend: random(100, 2000),
  };
};

module.exports.createEmptyCampaign = ({name, id}) => {
  return {
    id: id,
    type: 1,
    type_text: `Brand Awareness`,
    campaign_name: name,
    campaign_date: new Date().getTime(),
    messages: 0,
    status: 0,
    posted: {
      actual: 0,
      plan: 0,
      amount: 0,
    },
    viral: {
      actual: 0,
      plan: 0,
    },
    views: 0,
    prevviews: 0,
    comments: 0,
    prevcomments: 0,
    roi: 0,
    cpe: 0,
    total_spend: 0,
  };
};
