const state = {
  agesList: [`18-24`, `25-34`, `35+`],
  genderList: [
    {
      id: 1,
      value: `Male`,
    },
    {
      id: 2,
      value: `Female`,
    }
  ],
  locationList: [
    {
      value: 1,
      label: `Usa`,
    },
    {
      value: 2,
      label: `Japan`,
    },
    {
      value: 3,
      label: `Russia`,
    },
  ],
  countryList: [
    {
      value: 1,
      label: `Usa`,
    },
    {
      value: 2,
      label: `Japan`,
    },
    {
      value: 3,
      label: `Russia`,
    },
  ],
  languageList: [
    {
      value: 1,
      label: `Russian`,
    },
    {
      value: 2,
      label: `English`,
    },
  ],
  rateList: [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
  ],
  typeList: [
    {
      id: 1,
      value: `Coupons & Offers`,
    },
  ],
  measurmentList: [{
    id: 1,
    value: `CPE`,
  }],
  industryList: [
    {
      id: 1,
      value: `Management`,
    },
  ]
};
export default {
  state,
  namespaced: true
};

