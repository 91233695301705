<template>
  <c-progress :percentage="percentage">
    <template #top>
      <div class="info_top">
        <span class="info_top-percent">{{ flooring(percentage, 2) }}%</span>
      </div>
    </template>
    <template #bottom>
      <div class="info_bottom">
        <div class="info_bottom-plan">
          {{ actual }}/{{ plan }}
        </div>
        <div class="info_bottom-amount">
          <svg-icon class="info_bottom-icon" src="icons/play.svg"></svg-icon>
          <span>{{ amount }}</span>
        </div>
      </div>
    </template>
  </c-progress>
</template>

<script>
import CProgress from '~/src/components/base/progress';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import flooring from '~/src/utils/flooring';
const max = 100;

export default {
  name: `ProgressPosted`,
  components: {
    CProgress,
    SvgIcon,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    plan: {
      type: Number,
      default: 0,
    },
    actual: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    flooring: flooring
  }
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.info {
  &_top {
    margin-bottom: 5px;
    &-percent {
      color: $pink-light;
      font-size: 10px;
    }
  }
  &_bottom {
    color: #000000;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    &-plan {
      flex: 1;
    }
    &-amount {
      flex: 1;
      display: flex;
    }
    &-icon {
      margin-right: 5px;
    }
  }
}
</style>
