<template>
  <div class="breadcrumbs">
    <template v-for="(route, index) in path">
      <template v-if="index + 1 === path.length">
        <span :key="index" class="current">
          {{ convertedPath(route) }}
        </span>
      </template>
      <template v-else>
        <router-link
          :key="index"
          class="previous"
          tag="span"
          :to="generateRoute(index, route)"
        >
          <a>
            {{ convertedPath(route) }}
          </a>
        </router-link>
        <span :key="index + 'divider'" class="divider">/</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: `Breadcrumbs`,
  props: {
    route: {
      type: String,
      default: `...`,
    },
  },
  data() {
    return {
      path: [],
    };
  },
  watch: {
    $route: function (val) {
      this.path = this.$route.fullPath.split(`/`);
      this.path = this.path.splice(1);
    },
  },
  mounted() {
    this.path = this.$route.fullPath.split(`/`);
    this.path = this.path.slice(1);
  },
  methods: {
    convertedPath(route) {
      switch (true) {
      case route === `dashboard`:
        return this.$t(`layout.tracking`);
      case route === `find`:
        return this.$t(`layout.selection`);
      case route === `settings`:
        return this.$t(`layout.settings`);
      case /\d/.test(route) && this.$route.name === `User_dashboard`:
        return `@` + this.route;
      case /\d/.test(route) && this.$route.name === `Campaign_view`:
        return `` + this.route;
      default:
        return route;
      }
    },
    generateRoute(index, route) {
      let to = ``;
      switch (true) {
      case route === `dashboard`:
        to = `Tracking`;
        break;
      case route === `campaign`:
        to = `Campaigns`;
        break;
      default:
        return route;
      }
      return {
        name: to,
      };
    },
  },
};
</script>
