<template>
  <el-form-item
    class="cr-form-item"
    v-bind="$attrs"
    :style="{ marginBottom: `${bottomOffset}px` }"
  >
    <template v-if="$attrs.label || $slots.labelPostfix" #label>
      <div class="cr-form_label_wrapper">
        <div class="cr-form_label">
          <img
            v-if="$attrs['label-icon']"
            class="cr-form_label-icon"
            :src="require(`~/src/assets/images/${$attrs['label-icon']}`)"
            alt=""
          />
          <div class="cr-form_label-text">
            {{ $attrs.label }}
          </div>
        </div>
        <div class="cr-form_label_postfix">
          <slot name="labelPostfix"></slot>
        </div>
      </div>
    </template>
    <template #error="scope">
      <div class="cr-form-item_footer">
        <c-error class="el-form-item__error">
          {{ scope.error }}
        </c-error>
      </div>
    </template>
    <slot></slot>
  </el-form-item>
</template>

<script>
import { FormItem } from 'element-ui';
import CError from './../errorText';
export default {
  name: `CRForm`,
  components: {
    "el-form-item": FormItem,
    CError,
  },
  inheritAttrs: true,
  props: {
    bottomOffset: {
      type: Number,
      default: 22,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-form-item {
  &_footer {
    position: relative;
  }
}
.cr-form-item /deep/ .el-form-item {
  &__content {
    position: initial;
    line-height: 30px;
  }
  &__label {
    padding: 0px;
    font-size: $fontSize-medium;
    line-height: 20px;
    margin-bottom: 10px;
    min-height: 20px;
    display: flex;
    align-items: center;
    max-height: 25px;
    .cr-form {
      &_label {
        &_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
        display: flex;
        align-items: center;
        &-text::first-letter {
          text-transform: uppercase;
        }
        &-icon {
          & + .cr-form_label-text {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
