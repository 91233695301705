<template>
  <el-tooltip
    transition=""
    :content="content"
    placement="top"
    effect="light"
    :enterable="false"
  >
    {{ content }}
    <slot></slot>
  </el-tooltip>
</template>
<script>
import { Tooltip } from 'element-ui';
export default {
  name: `Tooltip`,
  components: {
    'el-tooltip': Tooltip,
  },
  props: {
    content: {
      type: String,
      default: ``,
    },
  },
};
</script>
