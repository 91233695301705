<template>
  <c-form ref="form" :model="form" label-position="top">
    <c-row type="flex" :gutter="38" :bottom-offset="0">
      <c-col>
        <c-form-item
          prop="followers"
          :label="$t('common.followers')"
          label-icon="icons/followers.svg"
        >
          <c-row type="flex" :bottom-offset="0">
            <c-input
              v-model="form.followers[0]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
            <c-dash></c-dash>
            <c-input
              v-model="form.followers[1]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
          </c-row>
          <c-slider
            v-model="form.followers"
            :min="limits.followers.min"
            :max="limits.followers.max"
            :range="true"
            max-width="386px"
          ></c-slider>
          <c-checkbox v-model="form.followers_growth">
            {{ $t("FormViews.tiktok.growth.followers") }}
          </c-checkbox>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="views"
          :label="$t('common.views')"
          label-icon="icons/views.svg"
        >
          <c-row type="flex" :bottom-offset="0">
            <c-input
              v-model="form.views[0]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
            <c-dash></c-dash>
            <c-input
              v-model="form.views[1]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
          </c-row>
          <c-slider
            v-model="form.views"
            :min="limits.views.min"
            :max="limits.views.max"
            :range="true"
            max-width="386px"
          ></c-slider>
          <c-checkbox v-model="form.views_growth">
            {{ $t("FormViews.tiktok.growth.views") }}
          </c-checkbox>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="likes"
          :label="$t('common.likes')"
          label-icon="icons/like.svg"
        >
          <c-row type="flex" :bottom-offset="0">
            <c-input
              v-model="form.likes[0]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
            <c-dash></c-dash>
            <c-input
              v-model="form.likes[1]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
          </c-row>
          <c-slider
            v-model="form.likes"
            :min="limits.likes.min"
            :max="limits.likes.max"
            :range="true"
            max-width="386px"
          ></c-slider>
          <c-checkbox v-model="form.likes_growth">
            {{ $t("FormViews.tiktok.growth.likes") }}
          </c-checkbox>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          :label="$t('common.achievements')"
          label-icon="icons/achievements.svg"
        >
          <c-checkbox-tag v-model="form.fast_growth" icon="icons/fire.svg">
            {{ $t("FormViews.tiktok.fast_growth") }}
          </c-checkbox-tag>
          <c-checkbox-tag v-model="form.new" icon="icons/broccoli.svg">
            {{ $t("common.new") }}
          </c-checkbox-tag>
          <c-checkbox-tag v-model="form.recommendations" icon="icons/heart.svg">
            {{ $t("common.recommendations") }}
          </c-checkbox-tag>
        </c-form-item>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="38" :bottom-offset="0">
      <c-col>
        <c-form-item :label="$t('common.shares')" label-icon="icons/share.svg">
          <c-row type="flex" :bottom-offset="0">
            <c-input
              v-model="form.shares[0]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
            <c-dash></c-dash>
            <c-input
              v-model="form.shares[1]"
              max-width="115px"
              :placeholder="$t('common.any')"
            ></c-input>
          </c-row>
          <c-slider
            :range="true"
            max-width="386px"
          ></c-slider>
          <c-checkbox v-model="form.shares_growth">
            {{ $t("FormViews.tiktok.growth.shares") }}
          </c-checkbox>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          :label="$t('common.challenges')"
          label-icon="icons/hashtag.svg"
        >
          <c-input
            v-model="tagManager"
            :placeholder="`#${$t('common.challenges')}`"
            @enter="submitTag"
          ></c-input>
          <div style="margin-top: 10px">
            <c-tag
              v-for="(item, key) in form.challenges"
              :key="key"
              type="pink"
              square
              closable
              @close="deleteTag(key)"
            >
              {{ item }}
            </c-tag>
          </div>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item :label="$t('FormViews.tiktok.enagagement_rate')">
          <c-select
            v-model="form.rate"
            :options="rateList"
            :placeholder="$t('common.rate')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item :label="$t('FormViews.tiktok.similar_as')">
          <c-input
            disabled
            :placeholder="`${$t('common.forms.enter')} ${$t(
              'common.tik_tok_account'
            )}`"
          ></c-input>
        </c-form-item>
      </c-col>
    </c-row>
  </c-form>
</template>

<script>
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CInput from '~/src/components/base/input';
import CSelect from '~/src/components/base/select';
import CCheckbox from '~/src/components/base/checkbox';
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
import CTag from '~/src/components/base/tag';
import CSlider from '~/src/components/base/slider';
import CDash from '~/src/components/base/utils/dash';
import CCheckboxTag from '~/src/components/base/checkbox_tag';
export default {
  name: `TikTokForm`,
  components: {
    CForm,
    CFormItem,
    CInput,
    CSelect,
    CCheckbox,
    CRow,
    CCol,
    CTag,
    CSlider,
    CDash,
    CCheckboxTag,
  },
  props: {
    rateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tagManager: ``,
      form: {
        followers: [0, 1000000],
        views: [0, 1000000],
        likes: [0, 1000000],
        shares: [0, 1000000],
        followers_growth: false,
        views_growth: false,
        likes_growth: false,
        shares_growth: false,
        fast_growth: false,
        new: false,
        recommendations: false,
        rate: ``,
        challenges: []
      },
      limits: {
        followers: {
          min: 0,
          max: 1000000,
        },
        views: {
          min: 0,
          max: 1000000,
        },
        likes: {
          min: 0,
          max: 1000000,
        },
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(`change`);
      },
    },
  },
  methods: {
    submitTag() {
      this.form.challenges.push(this.tagManager);
      this.tagManager = ``;
    },
    deleteTag(index) {
      this.form.challenges.splice(index, 1);
    },
  },
};
</script>
