export default [{
  jwtToken: `jwt_token1`,
  user: {
    'id': 6830197954,
    'email': `user1`,
    'username': `username1`,
    'password': `password1`,
    'role': `influencer`,
    'verified': false
  }
},{
  jwtToken: `jwt_token2`,
  user: {
    'id': 683019795487,
    'email': `user2`,
    'username': `username2`,
    'password': `password2`,
    'role': `business`,
    'verified': false
  }
},{
  jwtToken: `jwt_token3`,
  user: {
    'id': 6830197954871,
    'email': `user3`,
    'username': `username3`,
    'password': `password3`,
    'role': `agent`,
    'verified': false
  }
}];
