<template>
  <img :src="require(`~/src/assets/images/${src}`)" alt="" />
</template>
<script>
export default {
  name: `SvgIcon`,
  props: {
    src: {
      type: String,
      default: ``,
    },
  },
};
</script>
