<template>
  <div>
    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="$store.getters.getPreloaderState"
        id="loadingProgressG"
        key="preloader"
      >
        <div class="loadingProgressG"></div>
      </div>
      <div
        v-else
        key="content"
        class="text-center mt-20"
        @click="toLogin"
        v-html="message"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: `Info`,
  data () {
    return {
      message: ``
    };
  },
  async created () {
    this.$store.commit(`notification/setPreloader`, true);
    if (this.$route.query.recovery) {
      try {
        const resp = await this.axios.get(`/api/auth/recovery/${this.$route.query.recovery}`);
        if (resp.status === 200) {
          this.message = `<span class="font-18">A new password has been sent <br/> to your e-mail address.</span>`;
          this.message += `<button class="btn crimson mt-20" id="back">Back</router-link>`;
          this.$store.commit(`notification/setPreloader`, false);
        }
      } catch (e) {
        this.$router.push({ name: `SignIn` });
        console.log(e);
      }
    } else if (this.$route.query.regconfirm) {
      try {
        const resp = await this.axios.get(`/api/auth/regconfirm/${this.$route.query.regconfirm}`);
        if (resp.status === 200) {
          const token = resp.data.jwtToken;
          localStorage.setItem(`tiktok_highloadzone.token`, token);
          this.$store.commit(`setToken`, resp.data.jwtToken);
          this.axios.defaults.headers.common[`Authorization`] = `Bearer ${token}`;
          this.$store.commit(`notification/setPreloader`, false);
          this.$router.push({ name: `Dashboard` });
        }
      } catch (e) {
        this.$router.push({ name: `SignIn` });
        console.log(e);
      }
    }
  },
  methods: {
    toLogin (event) {
      if (event.target.id === `back`) {
        this.$router.push({ name: `SignIn` });
      }
    }
  }
};
</script>

<style lang="scss">
#back {
  margin: auto;
  width: 150px !important;
  height: 35px;
  font-size: 20px;
}
</style>
