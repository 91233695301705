<template>
  <div class="timeline_info">
    <div class="timeline_info-item">
      <div>{{ $t("common.views") }}/{{ $t("common.likes") }}</div>
      <b>{{ formatAmount(item.views) }}/{{ formatAmount(item.likes) }}</b>
    </div>
    <div class="timeline_info-item">
      <div>{{ $t("CampaignPages.viral_status") }}</div>
      <b>{{ item.viral }}%</b>
    </div>
    <div class="timeline_info-item">
      <div>{{ $t("common.roi") }}</div>
      <b>{{ item.roi }}%</b>
    </div>
    <div class="timeline_info-item">
      <div>{{ $t("common.best") }} / {{ $t("common.worst") }}</div>
    </div>
  </div>
</template>
<script>
import formatAmount from '~/src/utils/formatAmount';

export default {
  name: `TimelineInfo`,
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    formatAmount: formatAmount,
  }
};
</script>
<style lang="scss" scoped>
.timeline {
  &_info {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border: 1px solid #01f8f1;
    border-radius: 1px;
    background: rgba(1, 248, 241, 0.0609976);
    padding: 5px;
    z-index: 1;
    opacity: 0;
    height: 0;
    will-change: height;
    transition: all 0.4s ease-out;
    overflow: hidden;
    &-item {
      padding: 5px 0px;
      border-bottom: 1px solid #02f9f3;
      font-size: 15px;
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}
</style>
