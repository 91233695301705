<template>
  <div class="dropdown_menu">
    <el-dropdown trigger="click">
      <svg-icon src="icons/menu.svg"></svg-icon>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>Action1</el-dropdown-item>
        <el-dropdown-item>Action2</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `CampaignNameMenu`,
  components: {
    SvgIcon,
    "el-dropdown": Dropdown,
    "el-dropdown-menu": DropdownMenu,
    "el-dropdown-item": DropdownItem,
  },
  props: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.dropdown_menu {
  cursor: pointer;
  width: 20px;
  text-align: center;
}
</style>
