<template>
  <c-container>
    <c-container half>
      <h1>
        <!-- TODO make as normal badge for any element -->
        <span
          style="position: relative"
        >{{ $t("WelcomePage.title") }}
          <svg-icon
            style="position: absolute; right: -20px; top: 0px"
            src="icons/handHello.svg"
          ></svg-icon></span>
      </h1>
      <h3>
        {{ $t("WelcomePage.sub_title") }}
      </h3>
      <sized-box height="33px"></sized-box>
      <c-form ref="form" label-position="top" :model="form" :rules="rules">
        <h3>{{ $t("common.account_info") }}</h3>
        <el-row type="flex" :gutter="40">
          <el-col>
            <c-form-item
              :label="$t('common.tik_tok_account')"
              prop="accountName"
            >
              <c-input
                v-model="form.accountName"
                theme="dark"
                max-width="256px"
                :placeholder="`${$t('common.forms.enter')} ${$t(
                  'common.pronounce.your'
                )} ${$t('common.tik_tok_account')}`"
              ></c-input>
            </c-form-item>
          </el-col>
          <el-col>
            <c-form-item :label="$t('common.gender')" prop="gender">
              <c-select
                v-model="form.gender"
                theme="dark"
                max-width="210px"
                :options="genderList"
                :placeholder="`${$t('common.forms.select')} ${$t(
                  'common.pronounce.your'
                )} ${$t('common.gender')}`"
              ></c-select>
            </c-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="40">
          <el-col>
            <c-form-item :label="$t('common.country')" prop="country">
              <c-select
                v-model="form.country"
                theme="dark"
                max-width="256px"
                :options="countryList"
                placeholder="Choose your country"
              ></c-select>
            </c-form-item>
          </el-col>
          <el-col>
            <c-form-item :label="$t('common.birth_date')" prop="birthDate">
              <c-date-picker
                v-model="form.birthDate"
                theme="dark"
                max-width="210px"
                :placeholder="`${$t('common.forms.enter')} ${$t(
                  'common.birth_date'
                )}`"
              ></c-date-picker>
            </c-form-item>
          </el-col>
        </el-row>
        <sized-box height="50px"></sized-box>
        <h3>{{ $t("WelcomePage.describe_yourself_title") }}</h3>
        <div>
          {{ $t("WelcomePage.describe_yourself_sub_title") }}
        </div>
      </c-form>
      <tag-manager @change="changeTags"></tag-manager>
      <el-row type="flex" :gutter="40" align="middle">
        <el-col>
          <c-error> {{ $t("WelcomePage.hasntVerified") }} </c-error>
        </el-col>
        <el-col>
          <c-button :loading="loading" max-width="246px" @click="verify">
            {{ $t("WelcomePage.verify_btn") }}
          </c-button>
        </el-col>
      </el-row>
      <sized-box height="30px"></sized-box>
      <text-node>{{ $t("WelcomePage.how_work") }}</text-node>
      <text-node>
        {{ $t("WelcomePage.verify_step1")[0] }}
        <a href="@cliprate">@cliprate</a>
        {{ $t("WelcomePage.verify_step1")[1] }}.
      </text-node>
      <text-node>
        {{ $t("WelcomePage.we_will_notify") }}
      </text-node>
      <sized-box height="30px"></sized-box>
      <h3 class="text-gray">
        {{ $t("common.campaigns") }}
      </h3>
      <text-node>
        {{ $t("WelcomePage.campaigns_text") }}
      </text-node>
    </c-container>
  </c-container>
</template>

<script>
import CContainer from '~/src/components/base/container';
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CButton from '~/src/components/base/button';
import CInput from '~/src/components/base/input';
import CSelect from '~/src/components/base/select';
import CDatePicker from '~/src/components/base/datePicker';
import CError from '~/src/components/base/errorText';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import SizedBox from '~/src/components/base/utils/sizedBox';
import TextNode from '~/src/components/base/utils/textNode';
import TagManager from './tagManager';
import { Row, Col } from 'element-ui';

export default {
  name: `WelcomePage`,
  components: {
    CContainer,
    CForm,
    CFormItem,
    CButton,
    CInput,
    CSelect,
    CError,
    SvgIcon,
    "el-row": Row,
    "el-col": Col,
    SvgIcon,
    SizedBox,
    TextNode,
    TagManager,
    CDatePicker,
  },
  data() {
    return {
      loading: false,
      form: {
        accountName: ``,
        gender: ``,
        country: ``,
        birthDate: ``,
        tags: [],
      },
      rules: {
        accountName: [
          { required: true, message: `Please input name`, trigger: `blur` },
          {
            min: 3,
            max: 100,
            message: `Length should be 3 to 100`,
            trigger: `blur`,
          },
        ],
        gender: [
          {
            required: true,
            message: `Please select your gender`,
            trigger: `change`,
          },
        ],
        country: [
          {
            required: true,
            message: `Please select country zone`,
            trigger: `change`,
          },
        ],
      },
    };
  },
  computed: {
    genderList() {
      return [
        {
          id: 1,
          value: `Male`,
        },
        {
          id: 2,
          value: `Female`,
        },
        {
          id: 3,
          value: `Another`,
        },
      ];
    },
    countryList() {
      return [
        {
          value: 1,
          label: `USA`,
        },
        {
          value: 2,
          label: `JAPAN`,
        },
        {
          value: 3,
          label: `RUSSIA`,
        },
      ];
    },
  },
  methods: {
    changeTags(tags) {
      this.form.tags = tags;
    },
    verify() {
      this.$refs[`form`].form.validate((valid) => {
        if (valid) this.submit();
        return false;
      });
    },
    async submit() {
      this.loading = true;
      const [err, res] = await this.$store.dispatch(`auth/verify`, this.form);
      this.loading = false;
      if (!err && res) {
        switch (res.role) {
        case `influencer`: {
          this.$router.push({ name: `Dashboard` });
          break;
        }
        case `business`: {
          this.$router.push({ name: `Dashboard` });
          break;
        }
        case `agent`: {
          this.$router.push({ name: `Tracking` });
          break;
        }
        }
      }
    },
  },
};
</script>
