<template>
  <div>
    <c-container dark>
      <h2 class="text-center">
        {{ $t("DiscoveryPage.title") }}
      </h2>
      <c-row type="flex" :bottom-offset="0" style="padding-bottom: 20px">
        <c-input
          v-model="search"
          style="height: 40px"
          prefix-icon="icons/search.svg"
          :placeholder="$t('DiscoveryPage.search_placeholder')"
          max-width="100%"
          theme="dark"
        ></c-input>
        <c-button
          style="margin-left: 13px"
          type="text"
          icon-src="icons/filter.svg"
          @click="showFilters = !showFilters"
        ></c-button>
      </c-row>
    </c-container>
    <transition name="el-zoom-in-top">
      <div v-if="showFilters">
        <c-container dark>
          <c-divider
            :text="$t('common.influencer')"
            style="margin-top: 0px"
          ></c-divider>
          <influencer-form
            :location-list="locationList"
            :gender-list="genderList"
            :language-list="languageList"
            @change="showTotal = true"
          ></influencer-form>
          <c-divider :text="$t('common.audience')"></c-divider>
          <audience-form
            :ages-list="agesList.map((item,idx)=>{
              return {
                label: item,
                value: idx
              }
            })"
            :gender-list="genderList"
            :country-list="countryList"
            @change="showTotal = true"
          ></audience-form>
          <c-divider text="TikTok"></c-divider>
          <tik-tok-form
            :rate-list="rateList"
            @change="showTotal = true"
          ></tik-tok-form>
        </c-container>
        <c-container :full-width="true">
          <c-divider :no-space="true"></c-divider>
        </c-container>
        <c-container dark :full-width="true" padding="17px 10px">
          <c-row type="flex" justify="end" align="center" :bottom-offset="0">
            <c-button
              type="text"
              text-type="dark"
              size="large"
              max-width="200px"
            >
              {{ $t("common.cancel") }}
            </c-button>
            <c-button size="large" max-width="200px" @click="submit">
              <span v-if="totalResults">{{ totalResults }}</span>
              {{ $t("common.show") }} {{ $t("common.results") }}
            </c-button>
          </c-row>
        </c-container>
        <c-container>
          <div class="corner_box"></div>
          <h3 class="text-center">
            <b>{{ tableData.length }}</b>
            {{ $t("DiscoveryPage.influencers_filter") }}
          </h3>
        </c-container>
      </div>
    </transition>
    <table-detailed :table-data="tableData" @action="hide">
    </table-detailed>
  </div>
</template>

<script>
import CContainer from '~/src/components/base/container';
import CButton from '~/src/components/base/button';
import CInput from '~/src/components/base/input';
import CDivider from '~/src/components/base/utils/divider';
import CRow from '~/src/components/base/utils/row';
import InfluencerForm from '~/src/views/Forms_folder/Influencer_form';
import AudienceForm from '~/src/views/Forms_folder/Audience_form';
import TikTokForm from '~/src/views/Forms_folder/TikTok_form';
import tableDetailed from './table';
import { mapState } from 'vuex';

export default {
  name: `WelcomePage`,
  components: {
    CContainer,
    CButton,
    CInput,
    CDivider,
    CRow,
    InfluencerForm,
    AudienceForm,
    TikTokForm,
    tableDetailed,
  },
  data() {
    return {
      showFilters: false,
      search: ``,
      hided: [],
      showTotal: false,
    };
  },
  computed: {
    totalResults() {
      return (
        this.showTotal &&
        this.$store.state.influencers.hidden.filter((item) => {
          if (this.search) {
            return (
              item.username.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
          } else {
            return true;
          }
        }).length
      );
    },
    tableData() {
      return this.$store.state.influencers.data.filter((item) => {
        if (this.search) {
          return (
            !this.hided.includes(item.id) &&
            (item.username.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
              item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          );
        } else {
          return !this.hided.includes(item.id);
        }
      });
    },
    ...mapState({
      locationList: (state) => state.filters.locationList,
      agesList: (state) => state.filters.agesList,
      genderList: (state) => state.filters.genderList,
      countryList: (state) => state.filters.countryList,
      languageList: (state) => state.filters.languageList,
      rateList: (state) => state.filters.rateList,
    }),
  },
  async created() {
    await this.$store.dispatch(`influencers/hiddenData`);
  },
  methods: {
    async fetchData() {
      return this.$store.dispatch(`influencers/fetch`);
    },
    submit() {
      this.fetchData();
    },
    hide(row) {
      this.hided.push(row.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.corner_box {
  height: 20px;
  width: 100%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    left: 0px;
    right: 0px;
    margin: auto;
    border-style: solid;
    border-width: 12px 8px 0 8px;
    border-color: rgba($gray, 0.15) transparent transparent transparent;
  }
}
</style>
