<template>
  <c-form ref="form" :model="form" label-position="top">
    <c-row type="flex" :gutter="38" :bottom-offset="0">
      <c-col>
        <c-form-item
          prop="gender"
          :label="$t('common.gender')"
          label-icon="icons/gender.svg"
        >
          <c-select
            v-model="form.gender"
            :options="genderList"
            :placeholder="$t('common.any')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="location"
          :label="$t('common.location')"
          label-icon="icons/location.svg"
        >
          <c-select
            v-model="form.location"
            :options="locationList"
            :placeholder="$t('common.country')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="language"
          :label="$t('common.language')"
          label-icon="icons/language.svg"
        >
          <c-select
            v-model="form.language"
            :options="languageList"
            :placeholder="$t('common.country')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="interests"
          :label="$t('FormViews.influencer.interests_area')"
          label-icon="icons/interestsArea.svg"
          :bottom-offset="0"
        >
          <c-input
            v-model="tagManager"
            style="width: 360px"
            :placeholder="`#${$t('common.challenges')}`"
            @enter="submitTag"
          ></c-input>
          <div>
            <c-tag
              v-for="(item, key) in form.interests"
              :key="key"
              closable
              size="small"
              @close="deleteTag(key)"
            >
              {{ item }}
            </c-tag>
          </div>
        </c-form-item>
      </c-col>
    </c-row>
    <div style="display: flex">
      <div style="display: flex; flex-direction: column">
        <c-checkbox v-model="form.only_verified">
          {{ $t("FormViews.influencer.only_verified") }}
        </c-checkbox>
        <c-checkbox v-model="form.kyc_approved">
          {{ $t("FormViews.influencer.kyc_approved") }}
        </c-checkbox>
      </div>
      <div style="display: flex; flex-direction: column">
        <c-checkbox v-model="form.has_contacts">
          {{ $t("FormViews.influencer.has_email_phone") }}
        </c-checkbox>
        <c-checkbox v-model="form.clip_rate_customer">
          {{ $t("FormViews.influencer.clip_rate_customer") }}
        </c-checkbox>
      </div>
    </div>
  </c-form>
</template>

<script>
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CInput from '~/src/components/base/input';
import CSelect from '~/src/components/base/select';
import CCheckbox from '~/src/components/base/checkbox';
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
import CTag from '~/src/components/base/tag';
export default {
  name: `InfluencerForm`,
  components: {
    CForm,
    CFormItem,
    CInput,
    CSelect,
    CCheckbox,
    CRow,
    CCol,
    CTag,
  },
  props: {
    locationList: {
      type: Array,
      default: () => [],
    },
    genderList: {
      type: Array,
      default: () => [],
    },
    languageList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tagManager: ``,
      form: {
        gender: ``,
        location: ``,
        language: ``,
        interests: [],
        only_verified: false,
        kyc_approved: false,
        has_contacts: false,
        clip_rate_customer: false,
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(`change`);
      },
    },
  },
  methods: {
    submitTag() {
      this.form.interests.push(this.tagManager);
      this.tagManager = ``;
    },
    deleteTag(index) {
      this.form.interests.splice(index, 1);
    },
  },
};
</script>
