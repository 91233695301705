<template>
  <div class="cr-slider" :style="{ maxWidth: maxWidth }">
    <el-slider
      :value="value"
      :disabled="disabled"
      :range="range"
      :min="min"
      :max="max"
      :step="step"
      :show-stops="showStops"
      :marks="marks"
      @input="emit"
    ></el-slider>
  </div>
</template>
<script>
import { Slider } from 'element-ui';
export default {
  name: `CRSlider`,
  components: {
    "el-slider": Slider,
  },
  props: {
    value: {
      type: [Number, Array],
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    maxWidth: {
      type: String,
      default: `300px`,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    showStops: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    marks: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    emit(val) {
      this.$emit(`input`, val);
    },
  }
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-slider {
  width: 100%;
  max-width: 300px;
  & /deep/ {
    .el-slider {
      &__runway {
        background-color: rgba($gray, 0.5);
        height: 5px;
        &.disabled {
          .el-slider__button {
            background-color: $gray;
          }
        }
      }
      &__button {
        width: 20px;
        height: 20px;
        background-color: $pink;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        &-wrapper {
          top: -16px;
          outline: none;
        }
      }
      &__marks {
        &-stop {
          width: 1px;
          background-color: rgba($gray, 0.5);
          height: 10px;
          top: 5px;
        }
        &-text {
          color: $gray;
        }
      }
    }
  }
}
</style>
