<template>
  <c-form ref="form" :model="form" label-position="top">
    <c-row type="flex" :gutter="38">
      <c-col>
        <c-form-item
          prop="date"
          :label="$t('CampaignPages.step3.collaboration_form.release_data')"
        >
          <c-date-picker
            v-model="form.date"
            format="dd.MM.yyyy"
            type="daterange"
          >
          </c-date-picker>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="type"
          :label="$t('CampaignPages.step3.collaboration_form.type')"
        >
          <c-select
            v-model="form.type"
            style="width: 350px"
            min-width="400px"
            :options="typeList"
            :placeholder="$t('common.type')"
          >
          </c-select>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="type"
          :label="$t('CampaignPages.step3.collaboration_form.measurement')"
        >
          <c-select
            v-model="form.measurment"
            :options="measurmentList"
            :placeholder="$t('common.measurment')"
          >
          </c-select>
        </c-form-item>
      </c-col>
    </c-row>
    <c-row type="flex" :gutter="38">
      <c-col>
        <c-form-item
          prop="message"
          :label="$t('CampaignPages.step3.collaboration_form.message')"
        >
          <template #labelPostfix>
            <c-button type="text" text-type="pink" font-size="12px" disabled>
              {{ $t("CampaignPages.step3.use_template") }}
            </c-button>
          </template>
          <c-textarea
            v-model="form.message"
            style="width: 450px"
            max-width="450px"
            :placeholder="$t('common.any')"
          >
          </c-textarea>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item
          prop="hashtag"
          :label="$t('CampaignPages.step3.collaboration_form.hashtag')"
        >
          <c-input
            v-model="tagManager"
            style="width: 360px"
            :placeholder="`#${$t('common.challenges')}`"
            @enter="submitTag"
          ></c-input>
          <div>
            <c-tag
              v-for="(item, key) in form.hashtags"
              :key="key"
              closable
              size="small"
              @close="deleteTag(key)"
            >
              {{ item }}
            </c-tag>
          </div>
        </c-form-item>
      </c-col>
      <c-col>
        <c-form-item label=" ">
          <c-button
            type="pink"
            text-type="dark"
            plain
            icon-src="icons/point.svg"
            max-width="150px"
            style="width: 150px"
            disabled
          >
            {{ $t("CampaignPages.step3.collaboration_form.addpol") }}
          </c-button>
        </c-form-item>
      </c-col>
    </c-row>
    <c-row>
      <c-col>
        <c-form-item
          :label="$t('CampaignPages.step3.collaboration_form.suggested')"
        >
          <c-radio-group v-model="form.suggested_fee">
            <c-radio label="1" style="margin-bottom: 10px">
              {{ $t("CampaignPages.step3.collaboration_form.negotiated") }}
            </c-radio>
            <c-radio label="2">
              {{ $t("CampaignPages.step3.collaboration_form.initial_fee") }}
            </c-radio>
          </c-radio-group>
          <transition name="el-zoom-in-top">
            <div v-if="form.suggested_fee === '2'" class="fee_wrapper">
              <c-row type="flex" :gutter="9" :bottom-offset="0">
                <c-col>
                  <c-input
                    v-model="form.initial_fee"
                    max-width="100px"
                    placeholder="$"
                  >
                  </c-input>
                </c-col>
                <c-col>
                  <c-select
                    v-model="form.fee_type"
                    max-width="100px"
                    :options="fee_type"
                    :placeholder="$t('common.any')"
                  >
                  </c-select>
                </c-col>
              </c-row>
              <c-row type="flex" :bottom-offset="0">
                <span>
                  {{ $t("CampaignPages.step3.approval_required") }}
                </span>
                <c-button type="text" text-type="pink">
                  {{ $t("CampaignPages.step3.ask_approval") }}
                </c-button>
              </c-row>
            </div>
          </transition>
        </c-form-item>
      </c-col>
    </c-row>
  </c-form>
</template>

<script>
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CButton from '~/src/components/base/button';
import CInput from '~/src/components/base/input';
import CSelect from '~/src/components/base/select';
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
import CTextarea from '~/src/components/base/textarea';
import CRadio from '~/src/components/base/radio/';
import CRadioGroup from '~/src/components/base/radio/radioGroup';
import CDatePicker from '~/src/components/base/datePicker';
import CTag from '~/src/components/base/tag';

export default {
  name: `CollaborationForm`,
  components: {
    CForm,
    CFormItem,
    CButton,
    CInput,
    CSelect,
    CRow,
    CCol,
    CTextarea,
    CRadioGroup,
    CRadio,
    CDatePicker,
    CTag
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    measurmentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tagManager: ``,
      form: {
        date: ``,
        type: ``,
        measurment: ``,
        message: ``,
        hashtags: [],
        suggested_fee: `1`,
        initial_fee: 0,
        fee_type: `USD`,
      },
    };
  },
  computed: {
    fee_type() {
      return [
        {
          id: 1,
          value: `USD`,
        },
        {
          id: 2,
          value: `RUB`,
        },
      ];
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(`change`);
      },
    },
  },
  methods: {
    submitTag() {
      this.form.hashtags.push(this.tagManager);
      this.tagManager = ``;
    },
    deleteTag(index) {
      this.form.hashtags.splice(index, 1);
    },
  },
};
</script>
<style scoped lang="scss">
.fee {
  &_wrapper {
    padding: 15px 0px;
  }
}
</style>
