<template>
  <c-line
    ref="chart"
    :height="height"
    width="100%"
    :options="options"
    :chart-data="chartData"
  ></c-line>
</template>
<script>
import CLine from './../graphs/line.js';

const pointStyle = {
  pointRadius: `0`,
  pointHoverRadius: `6`,
  pointHoverBorderWidth: 2,
  pointHoverBorderColor: `rgb(0, 0, 0)`,
  pointHoverBackgroundColor: `rgb(255, 255, 255)`,
};

const lineStyles = [
  {
    borderColor: `#00F7EF`,
    backgroundColor: `rgba(0, 247, 239, 0.1)`,
    borderWidth: 2,
    ...pointStyle,
  },
  {
    borderColor: `rgba(255, 0, 80, 1)`,
    backgroundColor: `rgba(255, 0, 80, 0.1)`,
    borderWidth: 2,
    ...pointStyle,
  },
];

const bubbleStyles = [
  {
    backgroundColor: `#E8A37D`,
  },
];

export default {
  name: `CChartLine`,
  components: {
    CLine,
  },
  props: {
    dates: {
      type: Array,
      default: () => [],
    },
    dataSets: {
      type: Array,
      default: () => [],
    },
    bubbles: {
      type: Array,
      default: () => [],
    },
    smooth: {
      type: Boolean,
      default: false,
    },
    background: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    ticks: {
      type: Boolean,
      default: true,
    },
    grid: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 300,
    },
    tooltips: {
      type: Boolean,
      default: true,
    },
    tooltipsSettings: {
      type: Object,
      default: () => {
        return {
          title: true,
          label: true,
          footer: true,
        };
      },
    },
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                display: this.grid,
              },
              ticks: {
                display: this.ticks,
                callback: (value, index, array) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: this.grid,
              },
              ticks: {
                display: this.ticks,
                callback: (value, index, array) => {
                  return (
                    new Date(value).getDate() + ` ` + this.getWeekDay(value)
                  );
                },
              },
            },
          ],
        },
        tooltips: {
          enabled: this.tooltips,
          callbacks: {
            title: (value, data) => {
              if (!this.tooltipsSettings.title) return;
              const label = data.labels[value[0].index];
              if (
                (new Date(data.labels[data.labels.length - 1]) -
                  new Date(data.labels[0])) /
                  1000 /
                  60 /
                  60 <
                24
              ) {
                return [
                  this.getDay(label) + ` ` + this.getMonth(label),
                  new Date(label).toLocaleTimeString(),
                ];
              } else {
                return this.getDay(label) + ` ` + this.getMonth(label);
              }
            },
            label: (label) => {
              if (!this.tooltipsSettings.label) return;
              return this.labels[label.datasetIndex] || ``;
            },
            footer: (values) => {
              if (!this.tooltipsSettings.footer) return;
              return Number(values[0].value).toLocaleString();
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const dataSet = [];
      const labels = this.dates.map((date) => date);
      let lastId = 0;
      this.dataSets.forEach((data, idx) => {
        dataSet[idx] = {
          order: idx,
          data: data,
          ...lineStyles[idx],
          lineTension: this.smooth ? 0.5 : 0,
        };
        if (!this.background) {
          dataSet[idx].backgroundColor = `transparent`;
        }
      });
      lastId += this.dataSets.length;

      this.bubbles.forEach((data, idx) => {
        const id = lastId + idx;
        dataSet[id] = {
          data: data,
          order: idx,
          type: `bubble`,
          ...bubbleStyles[idx],
        };
      });
      lastId += this.bubbles.length;
      return {
        datasets: dataSet,
        labels: labels,
      };
    },
  },
  methods: {
    getMonth(date) {
      const month = new Date(date).getMonth();
      switch (true) {
      case month === 0:
        return this.$t(`graph.january`);
      case month === 1:
        return this.$t(`graph.february`);
      case month === 2:
        return this.$t(`graph.march`);
      case month === 3:
        return this.$t(`graph.april`);
      case month === 4:
        return this.$t(`graph.may`);
      case month === 5:
        return this.$t(`graph.june`);
      case month === 6:
        return this.$t(`graph.july`);
      case month === 7:
        return this.$t(`graph.august`);
      case month === 8:
        return this.$t(`graph.september`);
      case month === 9:
        return this.$t(`graph.october`);
      case month === 10:
        return this.$t(`graph.november`);
      default:
        return this.$t(`graph.december`);
      }
    },
    getDay(date) {
      return new Date(date).getDate();
    },
    getWeekDay(date) {
      date = new Date(date).getDay();
      switch (true) {
      case date === 0:
        return this.$t(`graph.sunday`);
      case date === 1:
        return this.$t(`graph.monday`);
      case date === 2:
        return this.$t(`graph.tuesday`);
      case date === 3:
        return this.$t(`graph.wednesday`);
      case date === 4:
        return this.$t(`graph.thursday`);
      case date === 5:
        return this.$t(`graph.friday`);
      default:
        return this.$t(`graph.saturday`);
      }
    },
  },
};
</script>
