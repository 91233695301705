<template functional>
  <div class="text-node">
    <slot>
      <slot> </slot>
    </slot>
  </div>
</template>
<script>
export default {
  name: `TextNode`,
};
</script>
<style lang="scss" scoped>
.text-node {
  margin-bottom: 12px;
}
</style>
