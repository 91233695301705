<template>
  <button class="campaign_type" @click="$emit('click')">
    <svg-icon v-if="iconSrc" :src="iconSrc"></svg-icon>
    <div class="campaign_type-text">
      <slot></slot>
    </div>
  </button>
</template>
<script>
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `TheCampaignType`,
  components: {
    SvgIcon,
  },
  props: {
    iconSrc: {
      type: String,
      default: ``,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.campaign_type {
  border: 1px solid $gray;
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0px 18px;
  outline: none;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    outline: none;
    background-color: rgba(0, 247, 239, 0.15);
    border-color: $mint;
  }
  &:active {
    outline: none;
  }
  &-text {
      margin-left: 30px;
      h3 {
          margin-bottom: 0px;
      }
  }
}
</style>
