<template>
  <el-table
    class="cr-table"
    style="width: 100%"
    :data="tableData"
    header-row-class-name="cr-table-header"
  >
    <el-table-column prop="time" :label="$t('common.date')" width="250">
      <template slot-scope="data">
        <div class="user_container">
          <img :src="data.row.ava" alt="" class="user-img" />
          <div class="user-date">
            {{ formatDate(data.row.date) }}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.views')">
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ formatAmount(data.row.views) }}</span>
          <svg-icon
            style="margin-left: 10px"
            :src="getArrow(data.row.prevviews, data.row.views)"
          ></svg-icon>
          {{ getValuedifference(data.row.prevviews, data.row.views) }}
          %
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.likes')">
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ formatAmount(data.row.likes) }}</span>
          <svg-icon
            style="margin-left: 10px"
            :src="getArrow(data.row.prevlikes, data.row.likes)"
          ></svg-icon>
          {{ getValuedifference(data.row.prevlikes, data.row.likes) }}
          %
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.comments')">
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ formatAmount(data.row.comments) }}</span>
          <svg-icon
            style="margin-left: 10px"
            :src="getArrow(data.row.prevcomments, data.row.comments)"
          ></svg-icon>
          {{ getValuedifference(data.row.prevcomments, data.row.comments) }}
          %
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.duets')">
      <template slot-scope="data">
        <duets-list :data="data.row.duets" :total="data.row.totalDuets"></duets-list>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.link')" width="100">
      <template slot-scope="data">
        <a :href="data.row.link" target="_blank">
          <svg-icon src="theme/user_href.svg"></svg-icon>
        </a>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import CRow from '~/src/components/base/utils/row';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import duetsList from './duets_list';

import getValuedifference from '~/src/utils/getValueDifference';
import formatAmount from '~/src/utils/formatAmount';
import formatDate from '~/src/utils/formatDate';
export default {
  name: `StatTable`,
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    SvgIcon,
    duetsList,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getValuedifference: getValuedifference,
    formatAmount: formatAmount,
    formatDate: formatDate,
    getArrow(prev, current) {
      const direction =
        this.getValuedifference(prev, current) < 0 ? `down` : `up`;
      return `theme/arrow_${direction}.svg`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.user {
  &_container {
    display: flex;
    align-items: center;
  }
  &-img {
    max-width: 60px;
    max-height: 60px;
  }
  &-date {
    margin-left: 20px;
  }
}
.cr-table {
  min-height: 135px;
  overflow: auto;
  margin-bottom: 20px;
  &::before {
    background: none;
  }
  .user-href {
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  .percent-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: unset;
    img {
      width: 100%;
      max-width: 10px;
      margin-right: 5px;
    }
    .up {
      color: $green;
    }
    .down {
      color: $pink-light;
    }
    .up,
    .down {
      margin-left: 10px;
      text-align: right;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      .popup {
        position: absolute;
        display: none;
        padding: 4px;
        width: 170px;
        color: $black;
        word-break: keep-all;
        border: 1px solid $gray;
        border-radius: 4px;
        background: $white;
        white-space: normal;
        text-align: center;
        z-index: 10;
      }
      &:hover {
        .popup {
          display: block;
          top: -22px;
          left: 120%;
        }
      }
    }
  }
  /deep/ {
    .cr-table {
      &-header {
        th {
          padding: 3px 0;
          font-size: 15px;
          font-weight: normal;
          color: $gray;
          div.cell {
            white-space: nowrap;
            line-height: 1;
          }
        }
        td {
          font-size: 12px;
        }
      }
      &-row {
        td {
          position: initial;
        }
        &:hover {
          td {
            background-color: rgba($gray, 0.15);
          }
        }
      }
    }
    .el-table {
      &__row {
        td {
          padding: 5px 0px;
        }
      }
    }
  }
}
</style>
