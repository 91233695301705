<template>
  <div
    class="header-highlight"
    :style="{ width: `${width}px`, transform: `translateX(${offsetX}px)` }"
  ></div>
</template>
<script>
export default {
  name: `TheHeaderHighlight`,
  props: {
    width: {
      type: Number,
      default: 50,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.header-highlight {
  will-change: transform;
  height: 2px;
  background-color: $pink;
  width: 60px;
  position: absolute;
  bottom: 0px;
  transition: .2s transform ease-out;
}
</style>
