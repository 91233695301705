<template>
  <c-progress :percentage="percentage" color="#F36500">
    <template #top>
      <div class="info_top">
        <div class="info_top-percent">
          <span>{{ flooring(percentage, 2) }}%</span>
          <svg-icon src="theme/arrow_up.svg" class="info_top-arrow">
            {{ flooring(percentage, 2) }}%
          </svg-icon>
        </div>
        <div class="info_top-percentLeft">
          {{ flooring(percentLeft, 2) }}%
        </div>
      </div>
    </template>
  </c-progress>
</template>

<script>
import CProgress from '~/src/components/base/progress';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import flooring from '~/src/utils/flooring';
const max = 100;

export default {
  name: `ProgressViral`,
  components: {
    CProgress,
    SvgIcon,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percentLeft() {
      return max - this.percentage;
    },
  },
  methods: {
    flooring: flooring,
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.info {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 10px;
    padding: 0px 5px;
    &-percent {
      color: $pink-light;
      display: flex;
    }
    &-arrow {
      margin-left: 5px;
    }
  }
}
</style>
