<template>
  <doughnut
    :height="height"
    :options="options"
    :chart-data="chartData"
  ></doughnut>
</template>
<script>
import doughnut from './../graphs/doughnut.js';
export default {
  name: `CDoughnut`,
  components: {
    doughnut,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    height: {
      type: Number,
      default: 300,
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        legend: false,
        cutoutPercentage: 40,
      },
    };
  },
  computed: {
    chartData() {
      const data = [];
      const backgroundColor = this.colors;
      const labels = [];
      this.data.forEach((item) => {
        data.push(item.value);
        labels.push(item.label);
      });
      return {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderWidth: 0,
          },
        ],
        labels: labels,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
