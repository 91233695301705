<template>
  <el-form
    ref="form"
    class="cr-form"
    :model="model"
    v-bind="$attrs"
    @submit.prevent.native
  >
    <slot></slot>
  </el-form>
</template>

<script>
import { Form } from 'element-ui';
export default {
  name: `CRForm`,
  components: {
    "el-form": Form,
  },
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: null,
    };
  },
  mounted() {
    this.form = this.$refs[`form`];
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
</style>
