<template>
  <button
    :style="{
      'font-weight': fontWeight,
      maxWidth: maxWidth,
      fontSize: fontSize,
    }"
    :class="classes"
    :disabled="disabled"
    @click="handleClick"
  >
    <transition
      :duration="{ enter: 150, leave: 200 }"
      name="el-zoom-in-center"
      @afterLeave="afterHidingLoadingTransition"
    >
      <loader v-if="showLoading" :width="20" :height="20"></loader>
    </transition>
    <transition
      :duration="{ enter: 150, leave: 200 }"
      name="el-zoom-in-center"
      @afterLeave="afterHidingTextTransition"
    >
      <div v-if="showText" class="cr-button_inner">
        <img
          v-if="iconSrc"
          class="cr-button-icon"
          :src="require(`~/src/assets/images/${iconSrc}`)"
          alt=""
        />
        <span
          v-if="$slots.default && $slots.default.length"
          :class="textClasses"
        >
          <slot></slot>
        </span>
        <span v-if="$slots.postfix" class="cr-button-postfix">
          <slot name="postfix"></slot>
        </span>
      </div>
    </transition>
  </button>
</template>

<script>
import loader from './utils/loader';
export default {
  name: `CRButton`,
  components: {
    loader,
  },
  props: {
    maxWidth: {
      type: String,
      default: `300px`,
    },
    type: {
      type: String,
      default: `pink`,
    },
    iconSrc: {
      type: String,
      default: ``,
    },
    fontWeight: {
      type: String,
      default: `400`,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: `default`,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textType: {
      type: String,
      default: `light`,
    },
    fontSize: {
      type: String,
      default: `15px`,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showText: true,
      showLoading: false,
    };
  },
  computed: {
    textClasses() {
      return [
        `cr-button-text`,
        this.uppercase && `uppercase`,
        `cr-button-text-${this.textType}`,
      ];
    },
    classes() {
      return [
        `cr-button`,
        `cr-button-${this.type}`,
        this.circle ? `is-circle` : ``,
        `cr-button-${this.size}`,
        this.hoverable ? `cr-button-hoverable` : ``,
        this.plain ? `is-plain` : ``,
      ];
    },
  },
  watch: {
    loading: function () {
      if (this.loading) {
        this.showText = false;
      } else {
        this.showLoading = false;
      }
    },
  },
  methods: {
    handleClick() {
      if (this.disabled || this.loading) return;
      this.$emit(`click`);
    },
    afterHidingTextTransition() {
      this.showLoading = true;
    },
    afterHidingLoadingTransition() {
      this.showText = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-button {
  transition: all linear 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 35px;
  outline: none;
  background: $white;
  border: none;
  border-radius: 2px;
  position: relative;
  &-postfix {
    position: absolute;
    right: 10px;
  }
  &[disabled] {
    cursor: default;
    .cr-button-icon {
      opacity: .6;
    }
    .cr-button-text {
      &.cr-button-text-light {
        color: $white;
      }
      &.cr-button-text-dark {
        color: rgba($black, .6);
      }
      &.cr-button-text-pink {
        color: rgba($pink, .6);
      }
    }
  }
  &-text {
    &.cr-button-text-light {
      color: $white;
    }
    &.cr-button-text-dark {
      color: $black;
    }
    &.cr-button-text-pink {
      color: $pink;
    }
  }
  &_inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-icon {
    & + .cr-button-text {
      margin-left: 10px;
    }
  }
  &.is-circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  & + .cr-button {
    margin-left: 10px;
  }
  .button-icon {
    margin-right: 10px;
    max-width: 20px;
    width: 100%;
  }
  &-primary {
    background: $mint;
    border: 1px solid $mint;
    &[disabled] {
      background: rgba($mint, 0.35);
    }
    &:not([disabled]) {
      &.cr-button-hoverable:hover {
        box-shadow: 5px 5px 5px rgba($mint, 0.5);
      }
      &.cr-button-hoverable:active {
        background: $mint;
        box-shadow: 4px 4px 4px rgba($mint, 0.5);
      }
    }
  }
  &-secondary {
    border: 1px solid $gray;
    &:not([disabled]) {
      &.cr-button-hoverable:hover {
        box-shadow: 5px 5px 5px rgba($gray, 0.5);
      }
      &.cr-button-hoverable:active {
        background: $gray;
        box-shadow: 4px 4px 4px rgba($gray, 0.5);
      }
    }
    &[disabled] {
      background: rgba($gray, 0.35);
    }
    & /deep/ .cr-cube {
      background-color: $gray;
    }
  }
  &-pink {
    background: $pink;
    color: $white;
    border: none;
    &.transparent {
      background: $white;
      border: 1px solid $pink;
      color: $pink;
    }
    &[disabled] {
      background-color: rgba($pink, 0.35);
    }
    &:not([disabled]) {
      &.cr-button-hoverable:hover {
        box-shadow: 5px 5px 5px rgba($pink, 0.5);
      }
      &.cr-button-hoverable:active {
        box-shadow: 4px 4px 4px rgba($pink, 0.5);
      }
    }
    &.is-plain {
      background-color: transparent;
      border: 1px solid $pink;
      color: $black;
      &[disabled] {
        opacity: .6;
      }
    }
    & /deep/ .cr-cube {
      background-color: #fff;
    }
  }
  &-text {
    width: auto;
    padding: 0px 5px;
    color: $pink;
    height: auto;
    line-height: auto;
    background-color: transparent;
    &:not([disabled]) {
      &.cr-button-hoverable:hover {
        color: darken($pink, 10%);
      }
      &.cr-button-hoverable:active {
        color: darken($pink, 10%);
      }
    }
    & /deep/ .cr-cube {
      background-color: $pink;
    }
  }

  &:not(.is-circle).cr-button-small {
    height: 25px;
  }
  &:not(.is-circle).cr-button-large {
    height: 52px;
  }
}
</style>
