<template>
  <div class="image_wrapper">
    <div class="image_container">
      <div
        v-for="(img, mkey) in images"
        :key="mkey"
        class="image_block"
        :style="getImgStyle(mkey, images.length)"
      >
        <img
          class="image-item"
          :class="
            mkey === 0
              ? 'image-item-best'
              : mkey === images.length - 1
                ? 'image-item-worst'
                : ''
          "
          :src="img.url"
        />
        <svg-icon
          v-if="mkey === 0"
          class="image-item-badge"
          :src="`icons/fire.svg`"
        ></svg-icon>
        <svg-icon
          v-if="mkey === images.length - 1"
          class="image-item-badge"
          src="icons/dislike.svg"
        ></svg-icon>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `ImageWrapper`,
  components: {
    SvgIcon,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getImgStyle(key, length) {
      //Stupid calculations
      const imgWidth = 57;
      const imagesWidth = imgWidth * length;
      const containerWidth = 169;
      const diff = imagesWidth - containerWidth;
      const offset = (imagesWidth / length - diff / (length - 1)) * key;
      return {
        left: `${offset}px`,
        zIndex: key + 2,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.image {
  &_container {
    position: relative;
    height: 141px;
    overflow: hidden;
  }
  &_wrapper {
    position: relative;
    padding: 0px 3px 8px 3px;
    border-top: 1px solid $gray-1;
    border-bottom: 1px solid $gray-1;
    &:hover {
      /deep/ .timeline_info {
        opacity: 1;
        height: 270px;
      }
      .image {
        &-item {
          &:not(.image-item-best):not(.image-item-worst) {
            transform: translateY(100%);
          }
          &-best {
            transform: translate(10px, -10px);
            & + .image-item-badge {
              transform: translate(5px, -5px);
            }
          }
          &-worst {
            transform: translate(-10px, -10px);
            & + .image-item-badge {
              transform: translate(-15px, -5px);
            }
          }
          &-badge {
            display: block;
          }
        }
      }
    }
  }
  &_block {
    position: absolute;
    width: 57px;
    height: 76px;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
  }
  &-item {
    width: 100%;
    transition: transform 0.2s ease-in;
    &-badge {
      transition: transform 0.2s ease-in;
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
  }
}
</style>
