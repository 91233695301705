<template>
  <div>
    <c-row type="flex" justify="end">
      <c-button
        type="text"
        text-type="dark"
        plain
        icon-src="icons/addSquare.svg"
        max-width="220px"
        disabled
      >
        {{ $t("CampaignPages.view.add_post") }}
      </c-button>
    </c-row>
    <c-select
      v-model="selectedTime"
      :options="times"
      icon-src="icons/calendar.svg"
      max-width="140px"
    ></c-select>
    <c-timeline :data="timelines"></c-timeline>
    <c-row type="flex" justify="end" style="font-size: 10px;">
      {{ lastUpdate }}
    </c-row>
  </div>
</template>
<script>
import CButton from '~/src/components/base/button';
import CRow from '~/src/components/base/utils/row';
import CSelect from '~/src/components/base/select';
import CTimeline from '~/src/views/Campaign/TheView/performance_timeline/index';

export default {
  name: `TimelineStat`,
  components: {
    CButton,
    CRow,
    CSelect,
    CTimeline,
  },
  data() {
    return {
      selectedTime: 1,
    };
  },
  computed: {
    lastUpdate() {
      return `Last update 15min ago`;
    },
    timelines() {
      return this.$store.state.campaigns.info.timeline;
    },
    times() {
      return [
        {
          value: 1,
          label: `1 week`,
        },
        {
          value: 2,
          label: `6 weeks`,
        },
        {
          value: 3,
          label: `12 weeks`,
        },
      ];
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
