var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"cr-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-row-class-name":"cr-table-header"}},[_c('el-table-column',{attrs:{"prop":"time","label":_vm.$t('common.date'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.row.time))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"followers","label":_vm.$t('common.followers')},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('div',{staticClass:"percent-wrapper cr-table-cell"},[_c('span',[_vm._v(_vm._s(Number(data.row.followers).toLocaleString() || ""))]),_c('div',{class:[
            _vm.getValuedifference(
              _vm.getPrevData(data.$index, 'followers'),
              data.row.followers
            ) < 0
              ? 'down'
              : 'up' ]},[_c('svg-icon',{attrs:{"src":_vm.getArrow(
                _vm.getPrevData(data.$index, 'followers'),
                data.row.followers
              )}}),_vm._v(" "+_vm._s(_vm.flooring( _vm.getValuedifference( _vm.getPrevData(data.$index, "followers"), data.row.followers ), 2 ))+" % ")],1)])]}}])}),_c('el-table-column',{attrs:{"prop":"avg_views","label":((_vm.$t('common.average')) + " " + (_vm.$t('common.views')))},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('div',{staticClass:"percent-wrapper cr-table-cell"},[_c('span',[_vm._v(_vm._s(Number(data.row.avg_views).toLocaleString() || ""))]),_c('div',{class:[
            _vm.getValuedifference(
              _vm.getPrevData(data.$index, 'avg_views'),
              data.row.avg_views
            ) < 0
              ? 'down'
              : 'up' ]},[_c('svg-icon',{attrs:{"src":_vm.getArrow(
                _vm.getPrevData(data.$index, 'avg_views'),
                data.row.avg_views
              )}}),_vm._v(" "+_vm._s(_vm.flooring( _vm.getValuedifference( _vm.getPrevData(data.$index, "avg_views"), data.row.avg_views ), 2 ))+" % ")],1)])]}}])}),_c('el-table-column',{attrs:{"prop":"avg_likes","label":((_vm.$t('common.average')) + " " + (_vm.$t('common.likes')))},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('div',{staticClass:"percent-wrapper cr-table-cell"},[_c('span',[_vm._v(_vm._s(Number(data.row.avg_likes).toLocaleString() || ""))]),_c('div',{class:[
            _vm.getValuedifference(
              _vm.getPrevData(data.$index, 'avg_likes'),
              data.row.avg_likes
            ) < 0
              ? 'down'
              : 'up' ]},[_c('svg-icon',{attrs:{"src":_vm.getArrow(
                _vm.getPrevData(data.$index, 'avg_likes'),
                data.row.avg_likes
              )}}),_vm._v(" "+_vm._s(_vm.flooring( _vm.getValuedifference( _vm.getPrevData(data.$index, "avg_likes"), data.row.avg_likes ), 2 ))+" % ")],1)])]}}])}),_c('el-table-column',{attrs:{"prop":"avg_comments","label":((_vm.$t('common.average')) + " " + (_vm.$t('common.comments')))},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('div',{staticClass:"percent-wrapper cr-table-cell"},[_c('span',[_vm._v(_vm._s(Number(data.row.avg_comments).toLocaleString() || ""))]),_c('div',{class:[
            _vm.getValuedifference(
              _vm.getPrevData(data.$index, 'avg_comments'),
              data.row.avg_comments
            ) < 0
              ? 'down'
              : 'up' ]},[_c('svg-icon',{attrs:{"src":_vm.getArrow(
                _vm.getPrevData(data.$index, 'avg_comments'),
                data.row.avg_comments
              )}}),_vm._v(" "+_vm._s(_vm.flooring( _vm.getValuedifference( _vm.getPrevData(data.$index, "avg_comments"), data.row.avg_comments ), 2 ))+" % ")],1)])]}}])}),_c('el-table-column',{attrs:{"prop":"avg_shares","label":((_vm.$t('common.average')) + " " + (_vm.$t('common.shares')))},scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('div',{staticClass:"percent-wrapper cr-table-cell"},[_c('span',[_vm._v(_vm._s(Number(data.row.avg_shares).toLocaleString() || ""))]),_c('div',{class:[
            _vm.getValuedifference(
              _vm.getPrevData(data.$index, 'avg_shares'),
              data.row.avg_shares
            ) < 0
              ? 'down'
              : 'up' ]},[_c('svg-icon',{attrs:{"src":_vm.getArrow(
                _vm.getPrevData(data.$index, 'avg_shares'),
                data.row.avg_shares
              )}}),_vm._v(" "+_vm._s(_vm.flooring( _vm.getValuedifference( _vm.getPrevData(data.$index, "avg_shares"), data.row.avg_shares ), 2 ))+" % ")],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }