<template>
  <div class="status" :class="classes">
    <span v-if="score >= 75">{{ $t("dashboard.excellent") }}</span>
    <span v-else-if="score > 50">{{ $t("dashboard.good") }}</span>
    <span v-else-if="score > 25">{{ $t("dashboard.poor") }}</span>
    <span v-else>{{ $t("dashboard.bad") }}</span>
  </div>
</template>
<script>
export default {
  name: `CardStatus`,
  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    classes() {
      const { score } = this;
      let status = `bad`;
      if (score >= 75) {
        status = `excellent`;
      } else if (score > 50) {
        status = `good`;
      } else if (score > 25) {
        status = `poor`;
      } else {
        status = `bad`;
      }
      const classes = [`status-${status}`];
      return classes;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.status {
  height: 24px;
  line-height: 24px;
  background-color: #f5fafe;
  font-size: 15px;
  border-radius: 24px;
  padding: 0px 8px;
  font-weight: 700;
  &-excellent {
    color: #0ee865;
  }
  &-good {
    color: #0ee865;
  }
  &-poor {
    color: #e8ad0e;
  }
  &-bad {
    color: $pink;
  }
}
</style>
