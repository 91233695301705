<template>
  <el-table
    class="cr-table"
    style="width: 100%"
    :data="tableData"
    header-row-class-name="cr-table-header"
  >
    <el-table-column prop="time" :label="$t('common.date')" width="150">
      <template slot-scope="data">
        {{ formatDate(data.row.time) }}
      </template>
    </el-table-column>
    <el-table-column prop="followers" :label="$t('common.followers')">
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ Number(data.row.followers).toLocaleString() || "" }}</span>
          <div
            :class="[
              getValuedifference(
                getPrevData(data.$index, 'followers'),
                data.row.followers
              ) < 0
                ? 'down'
                : 'up',
            ]"
          >
            <svg-icon
              :src="
                getArrow(
                  getPrevData(data.$index, 'followers'),
                  data.row.followers
                )
              "
            ></svg-icon>
            {{
              flooring(
                getValuedifference(
                  getPrevData(data.$index, "followers"),
                  data.row.followers
                ), 2
              )
            }}
            %
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="avg_views"
      :label="`${$t('common.average')} ${$t('common.views')}`"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ Number(data.row.avg_views).toLocaleString() || "" }}</span>
          <div
            :class="[
              getValuedifference(
                getPrevData(data.$index, 'avg_views'),
                data.row.avg_views
              ) < 0
                ? 'down'
                : 'up',
            ]"
          >
            <svg-icon
              :src="
                getArrow(
                  getPrevData(data.$index, 'avg_views'),
                  data.row.avg_views
                )
              "
            ></svg-icon>
            {{
              flooring(
                getValuedifference(
                  getPrevData(data.$index, "avg_views"),
                  data.row.avg_views
                ), 2
              )
            }}
            %
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="avg_likes"
      :label="`${$t('common.average')} ${$t('common.likes')}`"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ Number(data.row.avg_likes).toLocaleString() || "" }}</span>
          <div
            :class="[
              getValuedifference(
                getPrevData(data.$index, 'avg_likes'),
                data.row.avg_likes
              ) < 0
                ? 'down'
                : 'up',
            ]"
          >
            <svg-icon
              :src="
                getArrow(
                  getPrevData(data.$index, 'avg_likes'),
                  data.row.avg_likes
                )
              "
            ></svg-icon>
            {{
              flooring(
                getValuedifference(
                  getPrevData(data.$index, "avg_likes"),
                  data.row.avg_likes
                ), 2
              )
            }}
            %
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="avg_comments"
      :label="`${$t('common.average')} ${$t('common.comments')}`"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ Number(data.row.avg_comments).toLocaleString() || "" }}</span>
          <div
            :class="[
              getValuedifference(
                getPrevData(data.$index, 'avg_comments'),
                data.row.avg_comments
              ) < 0
                ? 'down'
                : 'up',
            ]"
          >
            <svg-icon
              :src="
                getArrow(
                  getPrevData(data.$index, 'avg_comments'),
                  data.row.avg_comments
                )
              "
            ></svg-icon>
            {{
              flooring(
                getValuedifference(
                  getPrevData(data.$index, "avg_comments"),
                  data.row.avg_comments
                ), 2
              )
            }}
            %
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="avg_shares"
      :label="`${$t('common.average')} ${$t('common.shares')}`"
    >
      <template slot-scope="data">
        <div class="percent-wrapper cr-table-cell">
          <span>{{ Number(data.row.avg_shares).toLocaleString() || "" }}</span>
          <div
            :class="[
              getValuedifference(
                getPrevData(data.$index, 'avg_shares'),
                data.row.avg_shares
              ) < 0
                ? 'down'
                : 'up',
            ]"
          >
            <svg-icon
              :src="
                getArrow(
                  getPrevData(data.$index, 'avg_shares'),
                  data.row.avg_shares
                )
              "
            ></svg-icon>
            {{
              flooring(
                getValuedifference(
                  getPrevData(data.$index, "avg_shares"),
                  data.row.avg_shares
                ), 2
              )
            }}
            %
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import getValuedifference from '~/src/utils/getValueDifference';
import formatDate from '~/src/utils/formatDate';
import SvgIcon from '~/src/components/base/utils/svgIcon';
import flooring from '~/src/utils/flooring';
export default {
  name: `StatTable`,
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    SvgIcon,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getPrevData(index, field) {
      if (index < this.tableData.length - 1) {
        return this.tableData[index + 1][field];
      }
      return 0;
    },
    flooring: flooring,
    getValuedifference: getValuedifference,
    formatDate: formatDate,
    getArrow(prev, current) {
      const diff = this.getValuedifference(prev, current);
      const direction = diff >= 0 ? `up` : `down`;
      return `theme/arrow_${direction}.svg`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-table {
  min-height: 135px;
  overflow: auto;
  margin-bottom: 20px;
  &::before {
    background: none;
  }
  .user-href {
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  .percent-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: unset;
    img {
      width: 100%;
      max-width: 10px;
      margin-right: 5px;
    }
    .up {
      color: $green;
    }
    .down {
      color: $pink-light;
    }
    .up,
    .down {
      display: flex;
      margin-left: 10px;
      text-align: right;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
    }
  }
  /deep/ {
    .cr-table {
      &-header {
        th {
          padding: 3px 0;
          font-size: 15px;
          font-weight: normal;
          color: $gray;
          div.cell {
            white-space: nowrap;
            line-height: 1;
          }
        }
        td {
          font-size: 12px;
        }
      }
      &-row {
        td {
          position: initial;
        }
        &:hover {
          td {
            background-color: rgba($gray, 0.15);
          }
        }
      }
    }
    .el-table {
      &__row {
        td {
          padding: 5px 0px;
        }
      }
    }
  }
}
</style>
