const state = {
  email: ``,
  language: ``,
  role: ``,
  id: ``,
  username: ``
};
const getters = {
  getUserEmail: state => state.email,
  getUserLanguage: state => state.language
};
const mutations = {
  SET_USER (state, user) {
    state.email = user.email;
    state.role = user.role;
    state.id = user.id;
    state.username = user.username;
  },
  setUserLanguage (state, language) {
    localStorage.setItem(`tiktok_highloadzone.language`, language);
    state.language = language;
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
