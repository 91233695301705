import { Scatter, mixins } from 'vue-chartjs';
import merge from 'lodash.merge';
const { reactiveProp } = mixins;

const defaultOptions = {
  animation: {
    duration: 2000,
    easing: `easeOutQuart`,
  },
  legend: {
    display: true,
    align: `end`,
    position: `top`,
    labels: {
      fontSize: 15,
      boxWidth: 15
    }
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      gridLines: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        lineWidth: 1,
        borderDash: [10, 10]
      },
      ticks: {
        fontColor: `#B1B8C7`,
        padding: 20
      }
    }],
    xAxes: [{
      gridLines: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false
      },
      ticks: {
        fontColor: `#B1B8C7`,
        maxTicksLimit: 10,
        maxRotation: 0,
        autoSkip: true,
        padding: 10
      }
    }]
  },
  tooltips: {
    displayColors: false,
    mode: `nearest`,
    intersect: false,
    borderColor: `rgba(177, 184, 199, 1)`,
    caretPadding: 10,
    caretSize: 0,
    cornerRadius: 2,
    borderWidth: 1,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    titleFontColor: `rgba(0,0,0,1)`,
    titleFontStyle: `normal`,
    bodyFontColor: `rgba(177, 184, 199, 1)`,
    footerFontColor: `rgba(0,0,0,1)`
  }
};

export default {
  extends: Scatter,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    render() {
      this.renderChart(this.chartData, merge(this.options, defaultOptions));
    }
  },
  watch: {
    options () {
      this.render();
    }
  },
  mounted () {
    this.render();
  }
};
