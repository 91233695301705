<template>
  <div class="modal-wrapper">
    <template v-if="$store.getters.getModalMode === 'bindTwofa'">
      <div class="panel">
        <form @submit.prevent="enableTwoAuth">
          <div class="pb-10 border-bottom font-18 d-flex">
            <span>Configure 2FA</span>
            <div
              class="modal-cross"
              @click="closeModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.414"
                height="17.414"
                viewBox="0 0 17.414 17.414"
              >
                <g transform="translate(-3634.793 -991.793)">
                  <line
                    x2="16"
                    y2="16"
                    transform="translate(3635.5 992.5)"
                    fill="none"
                    stroke="#a1a0ae"
                    stroke-width="2"
                  />
                  <line
                    x1="16"
                    y2="16"
                    transform="translate(3635.5 992.5)"
                    fill="none"
                    stroke="#a1a0ae"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-10 mb-10 font-12 text-justify">
            Applications for generating two-factor authentication codes
            allow you to receive codes even without a network connection
            and cellular connection. Use any application to generate two-
            step verification codes. For example, Google Authenticator for
            iPhone, Android, Authenticator for Windows Phone.
            <br />
            <br />
            Scan the QR code in the app or enter the secret key below. Then,
            to confirm that the app is set up correctly, enter the verification
            code from the app.
          </div>
          <div class="text-center">
            <img
              :src="$store.getters.getModalPayload.qr"
              alt=""
            />
          </div>
          <div class="input mb-20">
            <input
              v-model="code"
              class="text-center"
              type="text"
              required
              onblur="this.placeholder='2FA-code'"
              onfocus="this.placeholder=''"
              placeholder="2FA-code"
            />
            <img
              class="error"
              src="../assets/images/theme/alert.svg"
              alt=""
            />
          </div>
          <button
            class="btn crimson"
            type="submit"
          >
            Confirm
          </button>
        </form>
      </div>
    </template>
    <template v-else-if="$store.getters.getModalMode === 'changeWallets'">
      <div class="panel">
        <form @submit.prevent="changeWallets">
          <div class="pb-10 border-bottom font-18 d-flex">
            <span>Confirm action</span>
            <div
              class="modal-cross"
              @click="closeModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.414"
                height="17.414"
                viewBox="0 0 17.414 17.414"
              >
                <g transform="translate(-3634.793 -991.793)">
                  <line
                    x2="16"
                    y2="16"
                    transform="translate(3635.5 992.5)"
                    fill="none"
                    stroke="#a1a0ae"
                    stroke-width="2"
                  />
                  <line
                    x1="16"
                    y2="16"
                    transform="translate(3635.5 992.5)"
                    fill="none"
                    stroke="#a1a0ae"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-10 mb-10 text-center">
            To confirm the action, enter the code from your authenticator app.
          </div>
          <div class="input mt-20 mb-20">
            <input
              v-model="code"
              class="text-center"
              type="text"
              required
              onblur="this.placeholder='2FA-code'"
              onfocus="this.placeholder=''"
              placeholder="2FA-code"
            />
            <img
              class="error"
              src="../assets/images/theme/alert.svg"
              alt=""
            />
          </div>
          <button
            class="btn crimson"
            type="submit"
          >
            Confirm
          </button>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: `Modal`,
  data () {
    return {
      code: ``
    };
  },
  methods: {
    closeModal () {
      this.$store.commit(`closeModal`);
    },
    async enableTwoAuth () {
      try {
        const resp = await this.axios.post(`/api/user/settings/2fa/confirm`, {
          token: this.code
        });
        if (resp.status === 200) {
          this.$store.dispatch(`showNotification`, {
            title: `Success`,
            message: `Data was changed`,
            icon: `warning`,
            time: 4000
          });
          this.closeModal();
        }
      } catch (e) {
        // TODO: add showNotification or not?
      }
    },
    async changeWallets () {
      try {
        const wallets = this.$store.getters.getWallets;
        wallets.token = this.code;
        const resp = await this.axios.put(`/api/user/settings`, wallets);
        if (resp.status === 200) {
          this.$store.dispatch(`showNotification`, {
            title: `Success`,
            message: `Data was changed`,
            icon: `warning`,
            time: 4000
          });
          this.closeModal();
        }
      } catch (e) {
        console.log(e);
        this.closeModal();
      }
    }
  }
};
</script>
