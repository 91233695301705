<template>
  <div class="cr-progress" :style="{ maxWidth: maxWidth }">
    <div class="cr-progress-top">
      <slot name="top"></slot>
    </div>
    <el-progress
      ref="progress"
      :percentage="percentage"
      :color="color"
      :format="format"
      :show-text="showValue"
    ></el-progress>
    <div class="cr-progress-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import { Progress } from 'element-ui';
export default {
  name: `CRProgress`,
  components: {
    "el-progress": Progress,
  },
  props: {
    maxWidth: {
      type: String,
      default: `300px`,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: `#FF0050`,
    },
    secondaryColor: {
      type: String,
      default: `#EBEEF5`,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$refs[`progress`].$el.querySelector(
      `.el-progress-bar__outer`
    ).style.backgroundColor = this.secondaryColor;
  },
  methods: {
    format(percentage) {
      return percentage + `%`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-progress {
  width: 100%;
  & + .cr-progress {
    margin-top: 20px;
  }
  /deep/ .el-progress {
    &__text {
      font-size: $fontSize-medium;
      color: #a1aabd;
    }
  }
}
</style>
