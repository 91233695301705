<template>
  <div>
    <h2>
      <c-row type="flex" justify="space-between">
        <span>{{ $t("CampaignPages.title") }}</span>
      </c-row>
    </h2>
    <the-steps
      :steps="[
        $t('CampaignPages.steps')[
          campaignType === 'favourites' ? 2 : campaignType === 'search' ? 1 : 0
        ],
        ...$t('CampaignPages.steps').slice(3),
      ]"
      :current="current"
    ></the-steps>
    <transition name="fade" mode="out-in">
      <component :is="activeComponent" @next="changePage"></component>
    </transition>
  </div>
</template>
<script>
import TheSteps from './components/steps';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step3FromList from './step3_fromList';
import CRow from '~/src/components/base/utils/row';
import CDivider from '~/src/components/base/utils/divider';
import CButton from '~/src/components/base/button';
export default {
  name: `TheNewCampaign`,
  components: {
    TheSteps,
    CDivider,
    CButton,
    CRow,
    Step1,
    Step2,
    Step3,
    Step3FromList,
  },
  data() {
    return {
      activeComponent: `step1`,
      current: 1,
      campaignType: ``,
    };
  },
  methods: {
    changePage({ page, type }) {
      if (
        this.campaignType === `favourites` &&
        this.current === 2 &&
        page === `step3`
      ) {
        page = `Step3FromList`;
      }
      if (type) this.campaignType = type;
      this.activeComponent = page;
      this.current = parseInt(page.replace(/\D/gi, ``));
    },
  },
};
</script>
