<template>
  <div class="cr-divider_wrapper" :class="{ 'cr-divider_no-space': noSpace }">
    <div v-if="text" class="cr-divider-text">
      {{ text }}
    </div>
    <div class="cr-divider-line"></div>
  </div>
</template>
<script>
export default {
  name: `CRDivider`,
  props: {
    text: {
      type: String,
      default: ``,
    },
    height: {
      type: String,
      default: `0px`,
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-divider {
  &_wrapper {
    display: flex;
    align-items: flex-end;
    &:not(.cr-divider_no-space) {
      margin: 20px 0px;
    }
  }
  &-text {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: $black;
    white-space: nowrap;
    & + .cr-divider-line {
      margin-left: 10px;
      margin-bottom: 1px;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &-line {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: rgba($gray, 0.35);
  }
}
</style>
