<template>
  <div
    :class="[`cr-select`, `cr-select-${theme}`]"
    :style="{ maxWidth: maxWidth }"
  >
    <el-select
      ref="select"
      :value="value"
      :placeholder="placeholder"
      :popper-append-to-body="false"
      @input.native="handleInput"
      @change="handleChange"
    >
      <template v-if="iconSrc" slot="prefix">
        <span style="display: flex; align-items: center; height: 100%; cursor: pointer;">
          <svg-icon :src="iconSrc"></svg-icon>
        </span>
      </template>
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `CRSelect`,
  components: {
    "el-select": Select,
    "el-option": Option,
    SvgIcon,
  },
  props: {
    maxWidth: {
      type: String,
      default: `300px`,
    },
    theme: {
      type: String,
      default: `default`,
    },
    value: {
      type: [String, Number],
      default: ``,
    },
    labelKey: {
      type: String,
      default: `label`,
    },
    valueKey: {
      type: String,
      default: `value`,
    },
    placeholder: {
      type: String,
      default: `Select value`,
    },
    iconSrc: {
      type: String,
      default: ``,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleChange(val) {
      this.$emit(`change`, val);
      this.$emit(`input`, val);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-select {
  &-dark /deep/ .el-select {
    .el-input {
      &__inner {
        border-radius: 0px;
        border-color: $black;
        &:focus {
          border-color: $black;
        }
      }
      .el-select__caret {
        color: $black;
      }
    }
  }
  &-default /deep/ .el-select {
    .el-input {
      &__inner {
        border-radius: 2px;
        border-color: $gray;
        &:focus {
          border-color: $gray;
        }
      }
      .el-select__caret {
        color: $gray;
      }
    }
  }
}
.cr-select /deep/ .el-select {
  width: 100%;
  max-width: 300px;
  .el-input {
    &__icon {
      line-height: 30px;
    }
    &__inner {
      height: 30px;
      line-height: 30px;
    }
    &.is-focus {
      box-shadow: 2px 2px 0px 0 $mint;
    }
  }
  &-dropdown {
    margin: 0px;
    border-radius: 0px;
    box-shadow: 2px 2px 0px 0 $mint;
    .popper__arrow {
      display: none;
    }
    &__item {
      &.hover {
        font-weight: initial;
        color: inherit;
        background-color: rgba($mint, 0.25);
      }
      &.selected {
        color: #565656;
        background: #f3f3f375;
        &.hover {
          color: inherit;
          background-color: rgba($mint, 0.25);
        }
      }
    }
  }
}
</style>
