import { Bar, mixins } from 'vue-chartjs';
import merge from 'lodash.merge';
const { reactiveProp } = mixins;

const defaultOptions = {
  animation: {
    duration: 2000,
    easing: `easeOutQuart`,
  },
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      id: `left-y-axis`,
      type: `linear`,
      position: `left`,
      gridLines: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        lineWidth: 1,
        borderDash: [8, 4]
      },
      ticks: {
        display: false,
        beginAtZero: true,
        callback: function (value, index, array) {
          return Number(value).toLocaleString();
        }
      }
    }],
    xAxes: [{
      gridLines: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false
      },
      ticks: {
        maxTicksLimit: 10,
        maxRotation: 0,
        autoSkip: true
      }
    }]
  },
  tooltips: {
    displayColors: false,
    mode: `nearest`,
    intersect: false,
    borderColor: `rgba(177, 184, 199, 1)`,
    caretPadding: 10,
    caretSize: 0,
    cornerRadius: 2,
    borderWidth: 1,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    titleFontColor: `rgba(0,0,0,1)`,
    titleFontStyle: `normal`,
    bodyFontColor: `rgba(177, 184, 199, 1)`,
    footerFontColor: `rgba(0,0,0,1)`
  }
};


export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    render() {
      this.renderChart(this.chartData, merge(this.options, defaultOptions));
    }
  },
  watch: {
    options () {
      this.render();
    },
    chartData() {
      this.render();
    }
  },
  mounted() {
    this.render();
  }
};
