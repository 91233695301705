<template>
  <div>
    <c-form ref="form" label-position="top">
      <c-row type="flex" :gutter="38" :bottom-offset="0">
        <c-col :flex="1">
          <c-form-item
            prop="gender"
            :label="$t('CampaignPages.step3.creatorsList')"
          >
            <c-input
              v-model="search"
              style="height: 40px"
              prefix-icon="icons/search.svg"
              :placeholder="$t('common.name')"
              max-width="370px"
            ></c-input>
            <c-table
              ref="startList"
              :table-data="tableData"
              @handleSelectionChange="(val) => (selected = val)"
            ></c-table>
            <c-row type="flex" justify="end">
              <c-button
                type="pink"
                text-type="dark"
                plain
                icon-src="icons/addSquare.svg"
                max-width="150px"
                @click="addToList"
              >
                {{ $t("CampaignPages.step3.add") }}
              </c-button>
            </c-row>
          </c-form-item>
        </c-col>
        <c-col>
          <div class="divider-horizontal"></div>
        </c-col>
        <c-col :flex="1">
          <c-form-item
            prop="gender"
            :label="$t('CampaignPages.step3.selected')"
          >
            <c-table
              ref="endList"
              :show-badges="true"
              :table-data="selected"
            ></c-table>
            <c-row v-if="selected.length" type="flex" justify="end">
              <c-button
                type="text"
                text-type="dark"
                max-width="150px"
                @click="removeFromList"
              >
                {{ $t("CampaignPages.step3.remove") }}
              </c-button>
              <c-button
                type="text"
                text-type="pink"
                icon-src="campaign/eye.svg"
                max-width="200px"
                @click="sendToReview"
              >
                {{ $t("CampaignPages.step3.review") }}
              </c-button>
            </c-row>
          </c-form-item>
        </c-col>
      </c-row>
    </c-form>
  </div>
</template>
<script>
import cTable from '~/src/views/Campaign/components/table';
import CRow from '~/src/components/base/utils/row';
import CCol from '~/src/components/base/utils/column';
import CButton from '~/src/components/base/button';
import CForm from '~/src/components/base/form/form';
import CFormItem from '~/src/components/base/form/form-item';
import CInput from '~/src/components/base/input';
export default {
  name: `InfluencerMatch`,
  components: {
    cTable,
    CCol,
    CRow,
    CButton,
    CForm,
    CFormItem,
    CInput,
  },
  data() {
    return {
      selected: [],
      search: ``,
    };
  },
  computed: {
    tableData() {
      const initialData = [
        {
          id: `6715668410656687109`,
          username: `dbunbich 4`,
          ava: `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1653381580583942~c5_1080x1080.jpeg`,
          name: `User 1`,
        },
        {
          id: `6715668410656687110`,
          username: `dbunbich 3`,
          ava: `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1653381580583942~c5_1080x1080.jpeg`,
          name: `User 2`,
        },
        {
          id: `6715668410656687111`,
          username: `dbunbich 2`,
          ava: `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1653381580583942~c5_1080x1080.jpeg`,
          name: `User 3`,
        },
        {
          id: `6715668410656687112`,
          username: `dbunbich 1`,
          ava: `https://p16-amd-va.tiktokcdn.com/img/musically-maliva-obj/1653381580583942~c5_1080x1080.jpeg`,
          name: `User 4`,
        },
      ];
      const data = initialData.filter((item) => {
        let needFilter = false;
        this.selected.forEach((itm) => {
          if (!needFilter && item.id === itm.id) needFilter = true;
        });
        return !needFilter;
      });
      return data.filter((item) => {
        let needShow = !(this.search.length > 2);
        if (
          this.search.length > 2 &&
          (item.username.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1 ||
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        ) {
          needShow = true;
        }
        return needShow;
      });
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(`change`);
      },
    },
  },
  methods: {
    addToList() {
      if (!this.$refs[`startList`].selected.length) return;
      this.selected = [...this.selected, ...this.$refs[`startList`].selected];
      this.$refs[`startList`].$refs[`table`].clearSelection();
    },
    removeFromList() {
      const selected = this.$refs[`endList`].selected;
      if (!selected.length) return;
      const idxToRemove = [];
      this.selected.forEach((item, idx) => {
        selected.forEach((itm) => {
          if (item.id === itm.id) idxToRemove.push(idx);
        });
      });
      idxToRemove.forEach((idx) => {
        this.selected.splice(idx, 1);
      });
    },
    sendToReview() {},
  },
};
</script>
<style lang="scss" scoped>
.divider {
  &-horizontal {
    height: 100%;
    width: 1px;
    background-color: #e4e4e4;
  }
}
</style>
