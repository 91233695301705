<template>
  <c-row type="flex" :gutter="54">
    <c-col :flex="1">
      <campaign-type-btn icon-src="campaign/tracking.svg" @click="$emit('next', {page: 'step2', type: 'tracking'})">
        <h3>{{ $t('CampaignPages.step1.tracking') }}</h3>
      </campaign-type-btn>
    </c-col>
    <c-col :flex="1">
      <campaign-type-btn icon-src="campaign/favourites.svg" @click="$emit('next', {page: 'step2', type: 'favourites'})">
        <h3>{{ $t('CampaignPages.step1.favourites') }}</h3>
      </campaign-type-btn>
    </c-col>
    <c-col :flex="1">
      <campaign-type-btn icon-src="campaign/searchList.svg" @click="$emit('next', {page: 'step2', type: 'search'})">
        <h3>{{ $t('CampaignPages.step1.search') }}</h3>
      </campaign-type-btn>
    </c-col>
  </c-row>
</template>

<script>
import CCol from '~/src/components/base/utils/column';
import CRow from '~/src/components/base/utils/row';
import CampaignTypeBtn from './components/TheCampaignType';
export default {
  name: `CampaignStep3`,
  components: {
    CampaignTypeBtn,
    CCol,
    CRow,
  },
};
</script>
