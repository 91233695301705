<template>
  <div>
    <the-header></the-header>
    <workflow
      :class="[stub ? 'stub-wrapper' : '', $store.getters.getUserLanguage === 'ru' ? 'ru' : 'en']"
      class="workflow"
    ></workflow>
    <transition
      name="fade"
      mode="out-in"
    >
      <modal v-if="$store.getters.isModalOpen"></modal>
    </transition>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '~/src/components/common/header';
import TheFooter from '~/src/components/common/TheFooter';
import workflow from './workflow';
import modal from '../../components/modal';

export default {
  name: `UserLayout`,
  components: {
    TheHeader,
    TheFooter,
    workflow,
    modal
  },
  data () {
    return {
      stub: false
    };
  },
  // TODO: remove when add this part of site
  watch: {
    '$route' () {
      const name = this.$route.name;
      this.stub = name === `Battle` || name === `Settings` || name === `Showcase` || name === `Find` || name === `Messages` || name === `Upgrade`;
    }
  },
  mounted () {
    // TODO: remove when add this part of site
    const name = this.$route.name;
    this.stub = name === `Battle` || name === `Settings` || name === `Showcase` || name === `Messages` || name === `Upgrade`;
    document.querySelector(`#app`).classList.remove(`unauth`);
  },
  methods: {
    async handleDropdownCommand (command) {
      switch (true) {
      case command === `settings`:
        this.$router.push({ name: `Settings` });
        break;
      case command === `logout`:
        try {
          await this.$store.dispatch(`auth/logout`);
          this.$router.push({ name: `SignIn` });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
};
</script>
