<template>
  <div id="app">
    <transition
      name="fade"
      mode="out-in"
    >
      <notification v-if="isNotificationOpen"></notification>
    </transition>
    <transition
      name="fade"
      mode="out-in"
    >
      <auth-layout v-if="isAuthenticated"></auth-layout>
      <unauth-layout v-else></unauth-layout>
    </transition>
  </div>
</template>

<script>
import authLayout from './views/layout/authLayout';
import unauthLayout from './views/layout/unauthLayout';
import notification from './components/notification';
import {mapState} from 'vuex';

export default {
  name: `App`,
  components: {
    unauthLayout,
    authLayout,
    notification,
  },
  computed: mapState({
    isNotificationOpen: state => state.notification.open,
    isAuthenticated: state => state.auth.isAuthenticated
  }),
  async created() {
    if (this.$route.query.hasOwnProperty(`jwtToken`)) {
      this.axios.defaults.headers.common[
        `Authorization`
      ] = `Bearer ${this.$route.query.jwtToken}`;
      try {
        const resp = await this.axios.post(`/api/auth/token`, {
          token: this.$route.query.jwtToken,
        });
        localStorage.setItem(
          `tiktok_highloadzone.token`,
          this.$route.query.jwtToken
        );

        this.$store.commit(`setToken`, this.$route.query.jwtToken);
        this.$store.commit(`setUser`, resp.data.user);
        this.$router.push(`/dashboard`);
      } catch (e) {
        console.log(`jwt token app error =>`, e);
        this.axios.defaults.headers.common[`Authorization`] = ``;
      }
    }
  },
};
</script>
