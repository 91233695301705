<template>
  <div class="plainList">
    <h3 class="plainList-title">
      {{ title }}
    </h3>
    <div class="plainList_body">
      <div v-for="(item, key) in data" :key="key" class="plainList_item">
        <div class="plainList_item-label">
          {{ item.label }}
        </div>
        <div class="plainList_item-data">
          <b>{{ item.value }}%</b>
          <c-tag
            class="plainList_item-tag"
            style="padding: 0px 10px"
            size="small"
            :icon="getArrow(item.diff)"
            :type="item.diff > 0 ? 'success' : 'warning'"
          >
            <b>{{ Math.abs(item.diff) }}</b>
          </c-tag>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CTag from '~/src/components/base/tag';
export default {
  name: `CTopList`,
  components: {
    CTag,
  },
  props: {
    title: {
      type: String,
      default: ``,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getArrow(diff) {
      const direction = diff > 0 ? `up` : `down`;
      return `theme/arrow_${direction}-short.svg`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.plainList {
  max-width: 250px;
  width: 100%;
  &-title {
    margin-bottom: 10px;
  }
  &_item {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 2px solid rgba($gray, 0.15);
    &-tag {
      margin-left: 10px;
    }
    &-label {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-data {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  /deep/ {
    .el-tag-prefix {
      margin-right: 0px;
      height: 10px;
      width: 10px;
    }
  }
}
</style>
