import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import auth from './modules/auth';
import modal from './modules/modal';
import notification from './modules/notification';
import tracking from './modules/tracking';
import dashboard from './modules/dashboard';
import influencers from './modules/influencers';
import campaigns from './modules/campaigns';
import filters from './modules/filters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    modal,
    notification,
    auth,
    tracking,
    dashboard,
    influencers,
    campaigns,
    filters
  }
});
