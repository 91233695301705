<template>
  <div class="timeline">
    <div
      v-for="(item, key) in data"
      :key="key"
      class="timeline_block"
      :class="{ 'timeline_block-even': key % 2 !== 0 }"
    >
      <div class="timeline_controls-top"></div>
      <image-wrapper :images="item.images">
        <timeline-info :item="item"></timeline-info>
      </image-wrapper>
      <div class="timeline_controls-bottom">
        {{ item.period }}
      </div>
    </div>
  </div>
</template>
<script>
import timelineInfo from './timelineInfo';
import imageWrapper from './imageWrapper';
export default {
  name: `PerformanceTimeline`,
  components: {
    timelineInfo,
    imageWrapper,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.timeline {
  width: 100%;
  height: 231px;
  display: flex;
  justify-content: center;
  &_block {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 176px;
    border-right: 1px solid $gray-1;
    &-even {
      .image_wrapper {
        background-color: rgba(216, 216, 216, 0.2089);
      }
    }
  }
  &_controls {
    &-top {
      flex: 1;
    }
    &-bottom {
      flex: 1;
      color: $gray-2;
      font-size: 12px;
      display: flex;
      align-items: flex-end;
      padding: 0px 10px;
    }
  }
}
</style>
