<template>
  <div>
    <form @submit.prevent="login">
      <div class="mb-20">
        <cinput v-model="email" placeholder="Email"></cinput>
      </div>
      <div class="mb-20">
        <cinput
          v-model="password"
          placeholder="Password"
          type="password"
        ></cinput>
      </div>
      <cbutton>
        {{ $t("login.enter") }}
      </cbutton>
    </form>
  </div>
</template>

<script>
import cbutton from '~/src/components/base/button';
import cinput from '~/src/components/base/input';
export default {
  name: `Login`,
  components: {
    cbutton,
    cinput,
  },
  data() {
    return {
      selected: ``,
      email: ``,
      password: ``,
    };
  },
  methods: {
    async login() {
      const [err, res] = await this.$store.dispatch(`auth/authentication`, {
        email: this.email.toLowerCase(),
        password: this.password,
      });
      if (!err) {
        if (localStorage.getItem(`verified`)) {
          this.$router.push({ name: `Dashboard` });
        } else {
          this.$router.push({ name: `WelcomePage` });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  max-width: 500px;
  text-align: left;
}
.button {
  width: 100%;
  max-width: 250px;
}
</style>
