<template>
  <div :style="{height: height}"></div>
</template>
<script>
export default {
  name: `SizedBox`,
  props: {
    height: {
      type: String,
      default: `0px`,
    },
  },
};
</script>
