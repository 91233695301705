<template>
  <div :style="{paddingLeft: `${$parent.gutter / 2}px`, paddingRight: `${$parent.gutter / 2}px`, flex: flex, maxWidth: maxWidth}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: `CRCol`,
  props: {
    height: {
      type: String,
      default: `0px`,
    },
    flex: {
      type: [Boolean, Number],
      default: false
    },
    maxWidth: {
      type: String,
      default: `100%`
    }
  }
};
</script>
