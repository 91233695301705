<template>
  <div>
    <c-divider
      :text="$t('CampaignPages.step3.collaboration_descr')"
    ></c-divider>
    <collaboration-form
      ref="form"
      :type-list="typeList"
      :measurment-list="measurmentList"
    ></collaboration-form>
    <c-divider :text="$t('CampaignPages.step3.creators_info')"></c-divider>
    <influencer-match-form></influencer-match-form>
    <sized-box height="100"></sized-box>
    <c-row type="flex" justify="end" align="center" :bottom-offset="0">
      <c-button type="text" text-type="dark" max-width="120px" @click="cancel">
        {{ $t("common.cancel") }}
      </c-button>
      <c-button max-width="110px" @click="submit">
        {{ $t("common.publish") }}
      </c-button>
    </c-row>
  </div>
</template>

<script>
import CButton from '~/src/components/base/button';
import SizedBox from '~/src/components/base/utils/sizedBox';
import CDivider from '~/src/components/base/utils/divider';
import CRow from '~/src/components/base/utils/row';
import CollaborationForm from '~/src/views/Forms_folder/Collaboration_form';
import InfluencerMatchForm from '~/src/views/Forms_folder/InfluencerMatch_form';
import isValidForms from '~/src/utils/isValidForms';
export default {
  name: `CampaignStep3`,
  components: {
    CButton,
    SizedBox,
    CDivider,
    CRow,
    CollaborationForm,
    InfluencerMatchForm,
  },
  computed: {
    typeList() {
      return [
        {
          id: 1,
          value: `Coupons & Offers`,
        },
      ];
    },
    measurmentList() {
      return [
        {
          id: 1,
          value: `CPE`,
        },
      ];
    },
  },
  methods: {
    cancel() {
      this.$emit(`next`, { page: `step2` });
    },
    async submit() {
      const isValid = await isValidForms(Object.values(this.$refs));
      if (!isValid) return;
      const data = {};
      Object.keys(this.$refs).forEach((key) => {
        data[key] = this.$refs[key].form;
      });
      this.$store.dispatch(`campaigns/addDraft`, {
        type: `creators`,
        ...data,
      });
      this.$nextTick(async _ => {
        await this.$store.dispatch(`campaigns/publish`);
        this.$router.push({name: `Campaigns`});
      });
    },
  },
};
</script>
