<template>
  <div class="cr-radio">
    <el-radio
      :value="value"
      :label="label"
      @input="(label) => this.$emit(`input`, label)"
    >
      <slot></slot>
    </el-radio>
  </div>
</template>

<script>
import { Radio } from 'element-ui';
export default {
  name: `CRRadio`,
  components: {
    "el-radio": Radio,
  },
  props: {
    label: {
      type: [String, Number],
      default: ``,
    },
    value: {
      type: [String, Number],
      default: ``,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr-radio {
  & /deep/ {
    .el-radio {
      display: flex;
      align-items: center;
      &__input.is-checked {
        .el-radio__inner {
          background-color: transparent;
          border-color: $gray;
          &::after {
            width: 10px;
            height: 10px;
            background-color: $pink;
          }
        }
      }
      &__inner {
        width: 16px;
        height: 16px;
        background-color: transparent;
        border-color: $gray;
      }
      &__label {
        font-size: $fontSize-medium;
      }
    }
  }
}
</style>
