<template>
  <div class="cr_error">
    <img
      class="cr_error-icon"
      src="./../../assets/images/components/utils/cross.svg"
      alt=""
    />
    <div class="cr_error-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: `CRPlainError`,
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.cr_error {
  display: flex;
  align-items: center;
  color: $black;
  font-size: $fontSize-medium;
  &-text {
    margin-left: 5px;
  }
  &-icon {
  }
}
/* .cr-form-item /deep/ .el-form-item {
  &__label {
    padding: 0px;
    font-size: $fontSize-medium;
    line-height: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .cr-form-label {
      display: flex;
      align-items: center;
      &-text {
        margin-left: 5px;
      }
    }
  }
} */
</style>
