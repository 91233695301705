import flooring from './flooring';

/**
 * Generator for postfix
 */
function* generateAmountPostrix() {
  yield ``;
  yield `K`;
  yield `M`;
  yield `B`;
}

export default (amount, precision = 3) => {
  const gen = generateAmountPostrix();
  const t = (d) => {
    const postfix = gen.next();
    if (d / 1000 > 1) {
      return t(d / 1000);
    } else {
      return `${flooring(d, precision)}${postfix.value}`;
    }
  };
  const output = t(amount);
  return output;
};
