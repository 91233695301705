import login from './login';
import layout from './layout';
import dashboard from './dashboard';
import tracking from './trackingUser';
import graph from './graph';
import settings from './settings';
import common from './common';
import WelcomePage from './WelcomePage';
import DiscoveryPage from './DiscoveryPage';
export default {
  dashboard,
  tracking,
  graph,
  login,
  settings,
  layout,
  common,
  WelcomePage,
  DiscoveryPage
};
