<template>
  <div>
    <h2>
      <c-row type="flex" justify="space-between">
        <span>{{ $t("CampaignPages.campaigns") }}</span>
        <router-link
          :to="{ name: 'Campaign_create' }"
          tag="div"
          style="width: 180px"
        >
          <c-button
            type="pink"
            text-type="dark"
            plain
            icon-src="icons/addSquare.svg"
            max-width="180px"
          >
            {{ $t("CampaignPages.newCampaign") }}
          </c-button>
        </router-link>
      </c-row>
    </h2>
    <c-row type="flex" justify="space-between">
      <c-input
        v-model="search"
        style="height: 40px"
        prefix-icon="icons/search.svg"
        :placeholder="$t('CampaignPages.search')"
        max-width="400px"
      ></c-input>
      <c-button
        style="margin-left: 13px; cursor: initial; opacity: .6"
        type="text"
        icon-src="icons/filter.svg"
      ></c-button>
    </c-row>
    <list-table :table-data="tableData">
    </list-table>
  </div>
</template>
<script>
import CRow from '~/src/components/base/utils/row';
import CButton from '~/src/components/base/button';
import ListTable from './components/table_list';
import CInput from '~/src/components/base/input';
export default {
  name: `TheListCampaign`,
  components: {
    CButton,
    ListTable,
    CRow,
    CInput,
  },
  data() {
    return {
      search: ``,
    };
  },
  computed: {
    tableData() {
      return this.$store.state.campaigns.data.filter((item) => {
        if (this.search) {
          return (
            item.type_text.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.campaign_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        } else {
          return true;
        }
      });
    },
  },
  async created() {
    await this.$store.dispatch(`campaigns/fetch`);
  },
};
</script>
