var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audience_stat"},[_c('c-row',{attrs:{"type":"flex","justify":"space-around","gutter":50}},[_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("CampaignPages.view.audience_type")))]),_c('c-doughnut',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"height":220,"colors":_vm.audienceColor,"data":_vm.stat.spread}})],1),_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('c-top-list',{attrs:{"title":_vm.$t('CampaignPages.view.top_countries'),"data":_vm.stat.topCountries}})],1),_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("common.gender")))]),_c('c-pie',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"height":250,"colors":["rgba(255, 0, 80, .6)", "rgba(64, 201, 245, .6)"],"data":[
          {
            label: ("Male (" + (_vm.stat.gender) + "%)"),
            value: _vm.stat.gender,
          },
          {
            label: ("Female (" + (100 - _vm.stat.gender) + "%)"),
            value: 100 - _vm.stat.gender,
          } ]}})],1)],1),_c('c-row',{attrs:{"type":"flex","justify":"space-around","gutter":50}},[_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('c-simple-list',{attrs:{"colors":_vm.audienceColor,"data":_vm.stat.spread}})],1),_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('c-top-list',{attrs:{"title":_vm.$t('CampaignPages.view.top_cities'),"data":_vm.stat.topCities}})],1),_c('c-col',{attrs:{"flex":1,"max-width":"350px"}},[_c('c-top-list',{attrs:{"title":_vm.$t('CampaignPages.view.age'),"data":_vm.stat.ages}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }