<template>
  <div
    :class="[
      `input_wrapper`,
      `input_wrapper-${theme}`,
      disabled && `input-disabled`,
    ]"
    :style="{ maxWidth: maxWidth }"
  >
    <svg-icon v-if="prefixIcon" class="input-icon" :src="prefixIcon"></svg-icon>
    <input
      ref="input"
      :value="value"
      :class="classes"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      @focus="changePlaceholder('')"
      @blur="changePlaceholder(placeholder)"
      @input="handleInput"
      @keypress.enter="handleEnter"
    />
    <!-- <slot name="postfix"></slot> -->
  </div>
</template>

<script>
import SvgIcon from './utils/svgIcon';
export default {
  name: `CRInput`,
  components: {
    SvgIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: `300px`,
    },
    type: {
      type: String,
      default: `text`,
    },
    value: {
      type: [String, Number],
      default: ``,
    },
    placeholder: {
      type: String,
      default: `Input value`,
    },
    square: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: `default`,
    },
    prefixIcon: {
      type: String,
      default: ``,
    },
  },
  computed: {
    classes() {
      return [`input_inner`, this.square && `input_inner-square`];
    },
  },
  methods: {
    handleInput(val) {
      this.$emit(`input`, val.target.value);
    },
    handleEnter() {
      this.$emit(`enter`);
    },
    changePlaceholder(val) {
      if (this.$refs[`input`]) {
        this.$refs[`input`].placeholder = val;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.input_wrapper {
  position: relative;
  width: 100%;
  max-width: 300px;
  border: 1px solid;
  border-radius: $border-radius;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 30px;
  &.input {
    &-disabled {
      opacity: .6;
      border-color: rgba($gray, .6);
      input {
        user-select: none;
        background-color: transparent;
      }
    }
  }
  &-dark {
    border-color: $black;
  }
  &-default {
    border-color: $gray;
  }
  &:hover:not(.input-disabled),
  &:active:not(.input-disabled),
  &:focus:not(.input-disabled) {
    border: 1px solid $pink;
  }
  &::placeholder {
    color: $gray;
    font-size: 14px;
  }
  &-square {
    border-radius: 0px;
  }
  .description {
    margin-bottom: 10px;
    font-size: $fontSize-medium;
  }
  .input {
    &_inner {
      outline: none;
      border: 0px;
      width: 100%;
    }
    &-icon {
      max-width: 20px;
      max-height: 20px;
      margin-right: 10px;
    }
  }
}
</style>
