//import axios from 'axios';
import {signIn, checkToken} from '~/api/auth';
import to from 'await-to-js';
import mockUsers from '~/src/mocks/user';
import pause from '~/src/utils/pause';

const findUserByToken = (token) => {
  return mockUsers.find(item => {
    return (item.jwtToken === token);
  });
};

const findUserByCredential = ({email, password}) => {
  return mockUsers.find(item => {
    return (item.user.email === email && item.user.password === password);
  });
};


const state = {
  token: localStorage.getItem(`tiktok_highloadzone.token`) || ``,
  isAuthenticated: localStorage.getItem(`tiktok_highloadzone.token`) ? true : false,
  verified: localStorage.getItem(`verified`) || false
};
const getters = {
  getToken: state => state.token
};
const mutations = {
  setToken(state, token) {
    state.isAuthenticated = true;
    state.token = token;
  },
  logout(state) {
    state.isAuthenticated = false;
    state.token = ``;
    localStorage.removeItem(`tiktok_highloadzone.token`);
  },
  SET_VERIFY(state, boolean) {
    state.verified = boolean;
  }
};
const actions = {
  async checkToken({commit, dispatch, state}) {
    let err = false;
    const res = findUserByToken(state.token);
    if(!res) err = true;

    /*     if(!state.token) return;
    const [err,res] = await to(checkToken({token: state.token}));
 */
    if(err) {
      return dispatch(`logout`);
    }
    commit(`user/SET_USER`, res.user, {root:true});
  },
  async authentication({ commit }, {email, password}) {
    /* const [err,res] = await to(signIn({
      email,
      password
    })); */

    let err = false;
    const res = findUserByCredential({email, password});

    if(!res) err = true;
    if(err) {
      commit(`logout`);
      localStorage.removeItem(`tiktok_highloadzone.token`);
      return [err, res];
    }
    const token = res.jwtToken;
    localStorage.setItem(`tiktok_highloadzone.token`, token);
    commit(`setToken`, token);
    commit(`user/SET_USER`, res.user, {root: true});
    return [err,res];
  },
  logout({ commit }) {
    commit(`logout`);
  },
  async verify({commit, rootState}, data) {
    const [err,res] = [false, true];
    await pause(1500);
    commit(`SET_VERIFY`, true);
    localStorage.setItem(`verified`, true);
    return [err, {
      user: rootState.user.email,
      role: rootState.user.role
    }];
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
