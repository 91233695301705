import {campaignGenerator, createEmptyCampaign} from '~/src/mocks/campaigns.js';
import {campaigns_viewdata, create_campaign_empty_view} from '~/src/mocks/campaigns_viewdata.js';

const state = {
  creating: {
    details: {},
    creators: {}
  },
  data: [],
  info: {
    title: ``,
    campaign: {},
    audience: {},
    timeline: {},
    viral: {}
  }
};

const mutations = {
  SET_DATA(state, arr) {
    state.data = arr;
  },
  ADD_DATA(state, obj) {
    state.data.push(obj);
  },
  SET_INFO(state, obj) {
    state.info = obj;
  },
  ADD_DRAFT(state, {type, ...data}) {
    state.creating[type] = data;
  }
};
const actions = {
  async fetch({commit, state}) {
    if(!state.data.length) commit(`ADD_DATA`, campaignGenerator({name: `Black Friday Campaign`,id: state.data.length}));
    //commit(`SET_DATA`, campaigns);
  },
  async fetchInfo({commit, state}, id) {
    const [err,res] = [false, campaigns_viewdata[id]];
    if(!err && res) {
      commit(`SET_INFO`, res);
    } else {
      const d = state.data.filter(item => item.id === id);
      let name = ``;
      if(d.length) name = d[0].campaign_name;
      commit(`SET_INFO`, create_campaign_empty_view(name));
    }
  },
  addDraft({commit}, data) {
    commit(`ADD_DRAFT`, data);
  },
  publish({commit, state}) {
    const name = state.creating.details.campaign.name;
    commit(`ADD_DATA`, createEmptyCampaign({
      name: name,
      id: state.data.length
    }));
  }
};
  
export default {
  state,
  mutations,
  actions,
  namespaced: true
};
  
