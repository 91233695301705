<template>
  <div class="audience_stat">
    <c-row type="flex" justify="space-around" :gutter="50">
      <c-col :flex="1" max-width="350px">
        <h3>{{ $t("CampaignPages.view.audience_type") }}</h3>
        <c-doughnut
          :height="220"
          :colors="audienceColor"
          style="display: flex; justify-content: center"
          :data="stat.spread"
        ></c-doughnut>
      </c-col>
      <c-col :flex="1" max-width="350px">
        <c-top-list
          :title="$t('CampaignPages.view.top_countries')"
          :data="stat.topCountries"
        ></c-top-list>
      </c-col>
      <c-col :flex="1" max-width="350px">
        <h3>{{ $t("common.gender") }}</h3>
        <c-pie
          :height="250"
          :colors="[`rgba(255, 0, 80, .6)`, `rgba(64, 201, 245, .6)`]"
          style="display: flex; justify-content: center"
          :data="[
            {
              label: `Male (${stat.gender}%)`,
              value: stat.gender,
            },
            {
              label: `Female (${100 - stat.gender}%)`,
              value: 100 - stat.gender,
            },
          ]"
        ></c-pie>
      </c-col>
    </c-row>
    <c-row type="flex" justify="space-around" :gutter="50">
      <c-col :flex="1" max-width="350px">
        <c-simple-list
          :colors="audienceColor"
          :data="stat.spread"
        ></c-simple-list>
      </c-col>
      <c-col :flex="1" max-width="350px">
        <c-top-list
          :title="$t('CampaignPages.view.top_cities')"
          :data="stat.topCities"
        ></c-top-list>
      </c-col>
      <c-col :flex="1" max-width="350px">
        <c-top-list
          :title="$t('CampaignPages.view.age')"
          :data="stat.ages"
        ></c-top-list>
      </c-col>
    </c-row>
  </div>
</template>
<script>
import CRow from '~/src/components/base/utils/row';
import CCol from '~/src/components/base/utils/column';
import CDoughnut from '~/src/components/charts/doughnut';
import CPie from '~/src/components/charts/pie';
import CSimpleList from '~/src/views/Campaign/components/simpleList';
import CTopList from '~/src/views/Campaign/components/topList';
export default {
  name: `TheViewCampaign`,
  components: {
    CRow,
    CDoughnut,
    CSimpleList,
    CTopList,
    CCol,
    CPie,
  },
  data() {
    return {
      audienceColor: [`rgba(255, 0, 80, .6)`, `rgba(89, 134, 249, .6)`, `rgba(255, 209, 85, .6)`, `rgba(0, 247, 239, .6)`],
    };
  },
  computed: {
    stat() {
      return this.$store.state.campaigns.info.audience;
    },
  },
};
</script>
<style lang="scss" scoped>
.audience_stat {
  margin-top: 20px;
}
</style>
