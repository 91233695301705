import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import store from '~/src/store/index';
locale.use(lang);

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '~/src/store/localization/index';
Vue.use(VueI18n);

let language = `en` || localStorage.getItem(`tiktok_highloadzone.language`) || window.navigator.language;
if (language === `ru-RU` || language === `ru`) {
  language = `ru`;
} else {
  language = `en`;
}
store.commit(`user/setUserLanguage`, language);

export default new VueI18n({
  locale: store.state.user.language,
  fallbackLocale: `en`,
  silentFallbackWarn: false,
  silentTranslationWarn: false,
  messages
});
