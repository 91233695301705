<template>
  <el-table
    class="cr-table pt-20"
    style="width: 100%"
    :data="tableData"
    :default-sort="{ prop: '', order: '' }"
    :row-class-name="tableRowClass"
    header-row-class-name="cr-table-header"
  >
    <el-table-column :label="$t('common.user')" min-width="180">
      <template slot-scope="data">
        <div class="cr-table-cell">
          <router-link
            :to="{ name: 'User_dashboard', params: { uuid: data.row.id } }"
          >
            <avatar-table-cell
              :src="data.row.ava"
              :name="data.row.username"
            ></avatar-table-cell>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.name')" prop="name" min-width="150">
      <template slot-scope="data">
        <div class="cr-table-cell">
          {{ data.row.name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('common.country')"
      prop="country"
      min-width="50"
    >
      <template slot-scope="data">
        <div class="cr-table-cell">
          {{ (data.row.location || "").slice(0, 2).toUpperCase() }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      sortable
      prop="fans"
      :label="$t('common.followers')"
      min-width="180"
    >
      <template slot-scope="data">
        <c-tooltip :content="$t('TableLocales.last_week')">
          <div class="percent-wrapper cr-table-cell">
            <span>{{ Number(data.row.followers).toLocaleString() || "" }}</span>
            <div
              :class="[
                getValuedifference(data.row.prevfollowers, data.row.followers) <
                  0
                  ? 'down'
                  : 'up',
              ]"
            >
              |
              {{
                getValuedifference(data.row.prevfollowers, data.row.followers)
              }}
              %
            </div>
          </div>
        </c-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="heart"
      :label="$t('common.likes')"
      width="180"
      align="center"
      header-align="center"
    >
      <template slot-scope="data">
        <c-tooltip :content="$t('TableLocales.last_week')">
          <div class="percent-wrapper cr-table-cell">
            <span>{{ Number(data.row.views).toLocaleString() || "" }}</span>
            <div
              :class="[
                getValuedifference(data.row.prevviews, data.row.views) < 0
                  ? 'down'
                  : 'up',
              ]"
            >
              |
              {{ getValuedifference(data.row.prevviews, data.row.views) }}
              %
            </div>
          </div>
        </c-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      prop="views"
      align="center"
      header-align="center"
      :label="$t('common.avg') + ' ' + $t('common.views')"
      min-width="100"
    >
      <template slot-scope="data">
        <div class="cr-table-cell">
          <bar-graph
            :height="40"
            style="width: 40px; display: inline-block"
            :chart-data="
              data.row.graphs.avg_views ? chartData(data.row.graphs.avg_views) : {}
            "
            :options="chartOptions"
          ></bar-graph>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="video"
      :label="$t('common.videos')"
      width="100"
      align="center"
      header-align="center"
    >
      <template slot-scope="data">
        <div v-if="!data.row.hidden" class="cr-table-cell">
          <bar-graph
            :height="40"
            style="width: 40px; display: inline-block"
            :chart-data="
              data.row.graphs.videos ? chartData(data.row.graphs.videos) : {}
            "
            :options="chartOptions"
          ></bar-graph>
        </div>
        <div v-else>
          {{ $t("TableLocales.hidden") }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="er" :label="$t('common.er')" min-width="50">
    </el-table-column>
    <el-table-column prop="gr" :label="$t('common.gr')" min-width="50">
    </el-table-column>
    <el-table-column
      :label="$t('common.tikTok')"
      min-width="80"
      align="center"
      header-align="center"
    >
      <template slot-scope="data">
        <div class="cr-table-cell">
          <a :href="'https://tiktok.com/@' + data.row.username" target="_blank">
            <img
              class="user-href"
              src="../../assets/images/theme/user_href.svg"
              alt=""
            />
          </a>
        </div>
        <div class="cr-table_blocking">
          <div class="cr-table_blocking-info">
            <div class="cr-table_blocking-text">
              {{ $t("TableLocales.dontShow") }}
            </div>
            <div class="cr-table_blocking_hideBtn">
              <cr-button
                type="secondary"
                text-type="dark"
                font-size="12px"
                :hoverable="false"
                size="small"
                @click="$emit('action', data.row)"
              >
                {{ $t("common.hide") }}
                <template #postfix>
                  <img :src="require(`~/src/assets/images/icons/close.svg`)" />
                </template>
              </cr-button>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import barGraph from '~/src/components/graphs/bar';
import avatarTableCell from '~/src/components/base/utils/avatarTableCell';
import CrButton from '~/src/components/base/button';
import getValuedifference from '~/src/utils/getValueDifference';
import cTooltip from '~/src/components/base/utils/tooltip';
const barStyles = {
  backgroundColor: `rgba(255, 0, 80, 0.4)`,
  barThickness: 4,
};

export default {
  name: `TableDetailed`,
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    cTooltip,
    barGraph,
    avatarTableCell,
    CrButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: `index`,
          position: `nearest`,
          xPadding: 4,
          yPadding: 4,
          caretSize: 0,
          caretPadding: 0,
          bodyFontSize: 10,
          displayColors: false,
          intersect: false,
          callbacks: {
            title: function () {
              return ``;
            },
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: false,
            },
          ],
        },
      },
    };
  },
  methods: {
    chartData(data) {
      return {
        datasets: [
          {
            data: data,
            ...barStyles,
          },
        ],
        labels: data.map((item, idx) => idx + 1),
      };
    },
    tableRowClass({ row, rowIndex }) {
      const classes = [`cr-table-row`];
      if (row.hidden) classes.push(`cr-table-row-hidden`);
      return classes;
    },
    getValuedifference: getValuedifference,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-table {
  min-height: 135px;
  overflow: auto;
  &::before {
    background: none;
  }
  .user-href {
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  .percent-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: unset;
    img {
      width: 100%;
      max-width: 10px;
      margin-right: 5px;
    }
    .up {
      color: $green;
    }
    .down {
      color: $orange;
    }
    .up,
    .down {
      margin-left: 10px;
      text-align: right;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      .popup {
        position: absolute;
        display: none;
        padding: 4px;
        width: 170px;
        color: $black;
        word-break: keep-all;
        border: 1px solid $gray;
        border-radius: 4px;
        background: $white;
        white-space: normal;
        text-align: center;
        z-index: 10;
      }
      &:hover {
        .popup {
          display: block;
          top: -22px;
          left: 120%;
        }
      }
    }
  }
  /deep/ {
    .cr-table {
      &-header {
        th {
          padding: 3px 0;
          font-size: 15px;
          line-height: 2;
          font-weight: normal;
          color: $gray;
          div.cell {
            white-space: nowrap;
            line-height: 1;
          }
        }
        td {
          font-size: 12px;
        }
      }
      &-row {
        td {
          position: initial;
          padding: 2px 0px;
        }
        &-hidden {
          background-color: rgba($gray, 0.1);
          .cr-table-cell {
            opacity: 0.1;
          }
        }
        &:hover {
          td {
            background-color: rgba($gray, 0.15);
          }
          .cr-table_blocking {
            display: flex;
            transform: translateX(80px);
            &:hover {
              width: 100%;
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
  &_blocking {
    padding: 0px 15px;
    display: none;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    width: 100px;
    transition: transform 0.2s ease-in, width 0.2s ease-in;
    transform: translateX(100%);
    background-color: rgba($pink, 0.85);
    will-change: width;
    //temp
    margin-top: -37px;
    height: 52px;
    &:hover {
      .cr-table_blocking {
        &-info {
          display: flex;
        }
      }
    }
    &_hideBtn {
      min-width: 81px;
    }
    &-info {
      display: none;
      align-items: center;
    }
    &-text {
      font-weight: 800;
      color: $white;
      margin-right: 15px;
      font-size: 15px;
    }
  }
}
</style>
