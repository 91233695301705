<template>
  <div>
    <c-row type="flex" justify="space-between" :bottom-offset="0" style="margin-top: -20px;">
      <span></span>
      <c-button
        type="secondary"
        text-type="dark"
        plain
        icon-src="icons/download.svg"
        font-size="12px"
        max-width="160px"
        disabled
      >
        {{ $t("CampaignPages.view.download") }}
      </c-button>
    </c-row>
    <c-row type="flex" align="center">
      <h2>
        {{ title }}
      </h2>
      <div style="margin-left: 40px;">
        <c-tag size="small" icon="icons/fire.svg" transparent>
          Running
        </c-tag>
      </div>
    </c-row>
  </div>
</template>
<script>
import CRow from '~/src/components/base/utils/row';
import CButton from '~/src/components/base/button';
import CTag from '~/src/components/base/tag';
export default {
  name: `TheViewCampaign`,
  components: {
    CButton,
    CRow,
    CTag
  },
  props: {
    title: {
      type: String,
      default: ``
    }
  }
};
</script>
