<template>
  <div>
    <breadcrumbs :route="additionalRoute"></breadcrumbs>
    <transition
      name="fade"
      appear
      mode="out-in"
    >
      <router-view @update:throwRouteName="setAdditionalRoute"></router-view>
    </transition>
  </div>
</template>

<script>
import breadcrumbs from '../../components/breadcrumbs';

export default {
  name: `Workflow`,
  components: {
    breadcrumbs
  },
  data () {
    return {
      additionalRoute: `...`
    };
  },
  watch: {
    '$route': function () {
      this.setAdditionalRoute(`...`);
    }
  },
  methods: {
    setAdditionalRoute(route) {
      this.additionalRoute = route;
    }
  }
};
</script>
