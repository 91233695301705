<template>
  <el-table
    ref="table"
    class="cr-table pt-20"
    style="width: 100%"
    :data="tableData"
    stripe
    :default-sort="{ prop: 'fans', order: 'descending' }"
    row-class-name="cr-table-row"
    header-row-class-name="cr-table-header"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55">
    </el-table-column>
    <el-table-column :label="$t('common.user')" min-width="180">
      <template slot-scope="data">
        <div class="cr-table-cell">
          <router-link
            :to="{ name: 'UserRead', params: { uuid: data.row.id } }"
          >
            <avatar-table-cell
              :src="data.row.ava"
              :name="data.row.username"
            ></avatar-table-cell>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('common.name')" prop="name" min-width="150">
      <template slot-scope="data">
        <div class="cr-table-cell">
          {{ data.row.name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="showBadges" min-width="100">
      <template>
        <c-row type="flex" align="middle" :bottom-offset="0">
          <svg-icon src="campaign/message.svg"></svg-icon>
          <svg-icon src="campaign/money.svg" style="margin-left: 10px;"></svg-icon>
        </c-row>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import avatarTableCell from '~/src/components/base/utils/avatarTableCell';
import CRow from '~/src/components/base/utils/row';
import SvgIcon from '~/src/components/base/utils/svgIcon';
export default {
  name: `TableShort`,
  components: {
    "el-table": Table,
    "el-table-column": TableColumn,
    avatarTableCell,
    CRow,
    SvgIcon
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    showBadges: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selected = val;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.cr-table {
  min-height: 135px;
  overflow: auto;
  margin-bottom: 20px;
  &::before {
    background: none;
  }
  .user-href {
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  .percent-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: unset;
    img {
      width: 100%;
      max-width: 10px;
      margin-right: 5px;
    }
    .up {
      color: $green;
    }
    .down {
      color: $pink-light;
    }
    .up,
    .down {
      margin-left: 10px;
      text-align: right;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      .popup {
        position: absolute;
        display: none;
        padding: 4px;
        width: 170px;
        color: $black;
        word-break: keep-all;
        border: 1px solid $gray;
        border-radius: 4px;
        background: $white;
        white-space: normal;
        text-align: center;
        z-index: 10;
      }
      &:hover {
        .popup {
          display: block;
          top: -22px;
          left: 120%;
        }
      }
    }
  }
  /deep/ {
    .cr-table {
      &-header {
        th {
          padding: 3px 0;
          font-size: 15px;
          font-weight: normal;
          color: $gray;
          div.cell {
            white-space: nowrap;
            line-height: 1;
          }
        }
        td {
          font-size: 12px;
        }
      }
      &-row {
        td {
          position: initial;
        }
        &-hidden {
          background-color: rgba($gray, 0.1);
          .cr-table-cell {
            opacity: 0.1;
          }
        }
        &:hover {
          td {
            background-color: rgba($gray, 0.15);
          }
          .cr-table_blocking {
            transform: translateX(80px);
            &:hover {
              width: 100%;
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
  &_blocking {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    width: 100px;
    transition: transform 0.2s ease-in, width 0.2s ease-in;
    transform: translateX(100%);
    background-color: rgba($pink, 0.85);
    will-change: width;
    //temp
    margin-top: -37px;
    height: 52px;
    &:hover {
      .cr-table_blocking {
        &-info {
          display: flex;
        }
      }
    }
    &_hideBtn {
      min-width: 81px;
    }
    &-info {
      display: none;
      align-items: center;
    }
    &-text {
      font-weight: 800;
      color: $white;
      margin-right: 15px;
      font-size: 15px;
    }
  }
}
</style>
