const getImg = (id) => `https://i.pravatar.cc/54?u=${id}`;

module.exports.campaigns_viewdata = {
  0: {
    title: `Demo campaign`,
    campaign: {
      overview: {
        started: new Date(),
        budget: 4000,
        posted_plan: 9,
        posted_actual: 7,
        total_media: 21,
      },
      audience: {
        quality: 1457000,
      },
      engagement: {
        likes: 155800,
        messages: 3200,
        shares: 2200,
      },
      spend: {
        cpe: 0.59,
        total: 93700,
      },
    },
    audience: {
      spread: [
        {
          label: `Real people`,
          value: 77.74,
        },
        {
          label: `Influencers`,
          value: 1.7,
        },
        {
          label: `Mass followers`,
          value: 7.26,
        },
        {
          label: `Suspicious`,
          value: 13.1,
        },
      ],
      topCountries: [
        {
          label: `United States`,
          value: 17.8,
          diff: 1.5,
          oldValue: 16.3,
        },
        {
          label: `Japan`,
          value: 11.3,
          diff: -1.1,
          oldValue: 12.4,
        },
        {
          label: `South Korea`,
          value: 8.4,
          diff: -1.1,
          oldValue: 9.5,
        },
      ],
      topCities: [
        {
          label: `New York`,
          value: 17.8,
          diff: 1.5,
          oldValue: 16.3,
        },
        {
          label: `Los Angeles`,
          value: 11.3,
          diff: 1.5,
          oldValue: 9.8,
        },
        {
          label: `Moscow`,
          value: 8.4,
          diff: -1.1,
          oldValue: 9.5,
        },
      ],
      ages: [
        {
          label: `18-24`,
          value: 17.8,
          diff: 1.5,
          oldValue: 16.3,
        },
        {
          label: `25-34`,
          value: 11.3,
          diff: 1.5,
          oldValue: 9.8,
        },
        {
          label: `35+`,
          value: 8.4,
          diff: -1.1,
          oldValue: 9.5,
        },
      ],
      gender: 40
    },
    timeline: [
      {
        id: 1,
        period: `4-10/11`,
        images: [
          {
            url: getImg(1),
            likes: 10,
          },
          {
            url: getImg(2),
            likes: 8,
          },
          {
            url: getImg(3),
            likes: 6,
          },
          {
            url: getImg(4),
            likes: 4,
          },
        ],
        views: 145700000,
        likes: 12300000,
        viral: 30,
        roi: 1.5,
      },
      {
        id: 2,
        period: `11-17/11`,
        images: [
          {
            url: getImg(1),
            likes: 10,
          },
          {
            url: getImg(2),
            likes: 8,
          },
          {
            url: getImg(3),
            likes: 6,
          },
          {
            url: getImg(4),
            likes: 4,
          },
        ],
        views: 145700000,
        likes: 12300000,
        viral: 30,
        roi: 1.5,
      },
      {
        id: 3,
        period: `18-24/11`,
        images: [
          {
            url: getImg(1),
            likes: 10,
          },
          {
            url: getImg(2),
            likes: 8,
          },
          {
            url: getImg(3),
            likes: 6,
          },
          {
            url: getImg(4),
            likes: 4,
          },
        ],
        views: 145700000,
        likes: 12300000,
        viral: 30,
        roi: 1.5,
      },
      {
        id: 4,
        period: `25-31/11`,
        images: [
          {
            url: getImg(1),
            likes: 10,
          },
          {
            url: getImg(2),
            likes: 8,
          },
          {
            url: getImg(3),
            likes: 6,
          },
          {
            url: getImg(4),
            likes: 4,
          },
        ],
        views: 145700000,
        likes: 12300000,
        viral: 30,
        roi: 1.5,
      },
    ],
    viral: {
      total: 0.38,
      first: [{
        date: `2020-01-01`,
        value: 0.32
      },{
        date: `2020-01-02`,
        value: 0.42
      },{
        date: `2020-01-03`,
        value: 0.28
      },{
        date: `2020-01-04`,
        value: 0.32
      },{
        date: `2020-01-05`,
        value: 0.42
      },{
        date: `2020-01-06`,
        value: 0.23
      }],
      second: [{
        date: `2020-01-01`,
        value: 0.42
      },{
        date: `2020-01-02`,
        value: 0.12
      },{
        date: `2020-01-03`,
        value: 0.44
      },{
        date: `2020-01-04`,
        value: 0.38
      },{
        date: `2020-01-05`,
        value: 0.46
      },{
        date: `2020-01-06`,
        value: 0.33
      }],
      bubbles: [
        {
          x: `2020-01-01`,
          y: 0.3,
          r: Math.random() * (20 - 10) + 10,
        },
        {
          x: `2020-01-02`,
          y: 0.4,
          r: Math.random() * (20 - 10) + 10,
        },
        {
          x: `2020-01-03`,
          y: 0.2,
          r: Math.random() * (20 - 10) + 10,
        },
      ]
    }
  }
};


module.exports.create_campaign_empty_view = (name) => {
  return {
    title: name,
    campaign: {
      overview: {
        started: new Date(),
        budget: 0,
        posted_plan: 0,
        posted_actual: 0,
        total_media: 0,
      },
      audience: {
        quality:0,
      },
      engagement: {
        likes:0,
        messages:0,
        shares:0,
      },
      spend: {
        cpe:0,
        total:0,
      },
    },
    audience: {
      spread: [
        {
          label: `Real people`,
          value:0,
        },
        {
          label: `Influencers`,
          value:0,
        },
        {
          label: `Mass followers`,
          value:0,
        },
        {
          label: `Suspicious`,
          value:0,
        },
      ],
      topCountries: [
        {
          label: `United States`,
          value:0,
          diff:0,
          oldValue:0,
        },
        {
          label: `Japan`,
          value:0,
          diff:0,
          oldValue:0,
        },
        {
          label: `South Korea`,
          value:0,
          diff:0,
          oldValue:0,
        },
      ],
      topCities: [
        {
          label: `New York`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
        {
          label: `Los Angeles`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
        {
          label: `Moscow`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
      ],
      ages: [
        {
          label: `18-24`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
        {
          label: `25-34`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
        {
          label: `35+`,
          value: 0,
          diff: 0,
          oldValue: 0,
        },
      ],
      gender: 0
    },
    timeline: [
      {
        id: 1,
        period: ``,
        images: [],
        views: 0,
        likes: 0,
        viral: 300,
        roi: 0,
      },
      {
        id: 2,
        period: ``,
        images: [],
        views: 0,
        likes: 0,
        viral: 0,
        roi: 0,
      },
      {
        id: 3,
        period: ``,
        images: [],
        views: 0,
        likes: 0,
        viral: 0,
        roi: 0,
      },
      {
        id: 4,
        period: ``,
        images: [],
        views: 0,
        likes: 0,
        viral: 0,
        roi: 0,
      },
    ],
    viral: {
      total: 0,
      first: [{
        date: `2020-01-01`,
        value: 0
      },{
        date: `2020-01-02`,
        value: 0
      },{
        date: `2020-01-03`,
        value: 0
      },{
        date: `2020-01-04`,
        value: 0
      },{
        date: `2020-01-05`,
        value: 0
      },{
        date: `2020-01-06`,
        value: 0
      }],
      second: [{
        date: `2020-01-01`,
        value: 0
      },{
        date: `2020-01-02`,
        value: 0
      },{
        date: `2020-01-03`,
        value: 0
      },{
        date: `2020-01-04`,
        value: 0
      },{
        date: `2020-01-05`,
        value: 0
      },{
        date: `2020-01-06`,
        value: 0
      }],
      bubbles: [
        {
          x: `2020-01-01`,
          y: 0,
          r: 0,
        },
        {
          x: `2020-01-02`,
          y: 0,
          r: 0,
        },
        {
          x: `2020-01-03`,
          y: 0,
          r: 0,
        },
      ]
    }
  };
};
