<template>
  <div>
    <scatter :height="height" :options="options" :chart-data="chartData"></scatter>
  </div>
</template>
<script>
import Scatter from './../graphs/scatter.js';
import formatAmount from '~/src/utils/formatAmount';

const commonStyles = {
  borderWidth: 2,
  pointRadius: 10,
  pointHoverRadius: 12
};

const styles = [
  {
    ...commonStyles,
    backgroundColor: `rgba(255, 0, 80, 0.5)`,
  },
  {
    ...commonStyles,
    backgroundColor: `#4CF6F0`,
  },
  {
    ...commonStyles,
    backgroundColor: `#2BEA78`,
  },
];

export default {
  name: `CScatter`,
  components: {
    Scatter,
  },
  props: {
    dataSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    dataTypes: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, array) => {
                  return formatAmount(value);
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                callback: (value, index, array) => {
                  return formatAmount(value);
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: (value, data) => {
              return ``;
            },
            label: (label) => {
              return label.value;
            },
            footer: (values) => {
              return ``;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const dataSet = [];
      const labels = this.labels;
      this.dataSets.forEach((data, idx) => {
        dataSet[idx] = {
          label: this.labels[idx],
          order: idx,
          data: data,
          ...styles[idx],
        };
      });

      return {
        datasets: dataSet,
        labels: labels,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
