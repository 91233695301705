<template>
  <div class="steps">
    <div
      v-for="(item, key) in steps"
      :key="key"
      class="steps_item"
      :class="passed(key)"
    >
      <div class="steps_badge">
        <span class="steps_badge-text">{{ key + 1 }}</span>
      </div>
      <h3 class="steps-text">
        {{ item }}
      </h3>
      <div v-if="key < steps.length - 1" class="steps-divider"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: `TheSteps`,
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    passed(key, current = this.current) {
      if (key + 1 < current) {
        return `steps_item-passed`;
      } else if (key + 1 === current) {
        return `steps_item-active`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.steps {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  &_item {
    display: flex;
    align-items: center;
    &-passed {
      .steps {
        &_badge {
          background-color: $pink-light;
        }
        &-text {
          color: $black;
        }
      }
    }
    &-active {
      .steps {
        &_badge {
          background-color: $pink;
        }
        &-text {
          color: $black;
        }
      }
    }
  }
  &-divider {
    height: 1px;
    width: 32px;
    background-color: #979797;
    margin: 0px 10px;
  }
  &-text {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    color: #c9c9c9;
  }
  &_badge {
    height: 27px;
    width: 27px;
    background-color: rgba(0, 0, 0, 0.237462);
    color: $white;
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
