<template>
  <div class="cr-radio">
    <el-radio-group
      :value="value"
      @input="(label) => this.$emit(`input`, label)"
    >
      <slot></slot>
    </el-radio-group>
  </div>
</template>

<script>
import { RadioGroup } from 'element-ui';
export default {
  name: `CRRadio`,
  components: {
    "el-radio-group": RadioGroup,
  },
  props: {
    value: {
      type: [String, Number],
      default: ``,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
</style>
