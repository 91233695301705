<template>
  <div class="goal">
    <div class="goal_info">
      <div>
        <span class="goal_info-amount">{{ amounts.finish - followers }}</span>
        <span style="margin-left: 5px; margin-right: 5px">{{
          $t("dashboard.followersLeft")
        }}</span>
        <span class="goal_info-amount">{{ amounts.finish }}</span>
      </div>
      <div class="goal_line">
        <div class="goal_line-skelet"></div>
        <div
          class="goal_line-fact"
          :style="{ width: `${amounts.width}%` }"
        ></div>
      </div>
      <div class="goal_bottom">
        <span class="goal_bottom-info goal_bottom-left">{{
          formatAmount(amounts.start)
        }}</span>
        <span class="goal_bottom-info goal_bottom-right">{{
          formatAmount(amounts.finish)
        }}</span>
      </div>
    </div>
    <div class="goal_image">
      <svg-icon src="dashboard/goal.svg"></svg-icon>
    </div>
  </div>
</template>
<script>
import svgIcon from '~/src/components/base/utils/svgIcon';
import formatAmount from '~/src/utils/formatAmount';
import flooring from '~/src/utils/flooring';

const calcGoals = (amount) => {
  if (amount < 100) {
    return {
      step: 10,
      start: Math.floor(amount / 10) * 10,
      finish: Math.floor(amount / 10) * 10 + 10,
    };
  }
  if (amount < 1000) {
    return {
      step: 100,
      start: Math.floor(amount / 100) * 100,
      finish: Math.floor(amount / 100) * 100 + 100,
    };
  }
  if (amount < 10000) {
    return {
      step: 5000,
      start: Math.floor(amount / 5000) * 5000,
      finish: Math.floor(amount / 5000) * 5000 + 5000,
    };
  }
  if (amount < 500000) {
    return {
      step: 10000,
      start: Math.floor(amount / 10000) * 10000,
      finish: Math.floor(amount / 10000) * 10000 + 10000,
    };
  }
  if (amount >= 500000) {
    return {
      step: 100000,
      start: Math.floor(amount / 100000) * 100000,
      finish: Math.floor(amount / 100000) * 100000 + 100000,
    };
  }
};

export default {
  name: `TheGamefication`,
  components: {
    svgIcon,
  },
  props: {
    followers: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    amounts() {
      const { start, finish, step } = calcGoals(this.followers);
      const width = flooring((this.followers - start) / (finish - start) * 100);
      return {
        start,
        finish,
        width
      };
    },
  },
  methods: {
    formatAmount: formatAmount,
  },
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/styles/variables";
.goal {
  width: 100%;
  display: flex;
  &_bottom {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-info {
      &:before {
        content: "";
        position: absolute;
        height: 35px;
        width: 2px;
        top: -40px;
        background-color: rgba($black, 0.3);
      }
    }
    &-left {
      &:before {
        left: 0px;
      }
    }
    &-right {
      &:before {
        right: 0px;
      }
    }
  }
  &_line {
    position: relative;
    height: 30px;
    &-skelet {
      height: 100%;
      background-color: rgba(#a1aabd, 0.15);
    }
    &-fact {
      position: absolute;
      height: 100%;
      left: 0px;
      top: 0px;
      background-image: linear-gradient(
        270deg,
        #ff0050 0,
        rgba(255, 0, 80, 0.49) 100%
      );
       background-size: 400% 400%;
       animation: gradient 5s linear infinite;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
  &_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 30px;
    &-amount {
      color: $pink;
      font-weight: bold;
    }
  }
  &_image {
    min-width: 88px;
    min-height: 88px;
    border-radius: 50%;
    background-color: rgba(#a1aabd, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
