import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

import Login from './views/auth/login';
import Registration from './views/auth/registration';
import Info from './views/auth/info';

// main view
import Dashboard from './views/Dashboard/index';
import Welcome from './views/Welcome/index';
import Tracking from './views/Tracking/index';
import Discovery from './views/Discovery/index';
import Campaign from './views/Campaign/';
import Campaign_Create from './views/Campaign/TheNew';
import Campaign_View from './views/Campaign/TheView';

import Messages from './views/Messages';
import Upgrade from './views/Upgrade';
import settings from './views/settings/index';
import NotFound from './views/notFound';

const ifNotAuth = (to, from, next) => {
  if (!store.state.auth.isAuthenticated) {
    next();
    return;
  }
  next(`/dashboard`);
};
const ifAuth = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    if(store.state.auth.verified) {
      return next();
    } else {
      return next(`/welcome`);
    }
  }
  next(`/login`);
};

const ifVerified = (to, from, next) => {
  if(store.state.auth.verified) {
    next(`/dashboard`);
    return;
  }
  next();
};

Vue.use(Router);

export default new Router({
  mode: `history`,
  routes: [
    { path: `/login`, name: `SignIn`, component: Login, beforeEnter: ifNotAuth },
    { path: `/registration`, name: `SignUp`, component: Registration, beforeEnter: ifNotAuth },
    { path: `/info`, name: `Info`, component: Info, beforeEnter: ifNotAuth, props: true },
    { path: `/welcome`, name: `WelcomePage`, component: Welcome, beforeEnter: ifVerified },
    { path: `/tracking`, name: `Tracking`, component: Tracking, beforeEnter: ifAuth },
    { path: `/dashboard`, name: `Dashboard`, component: Dashboard, beforeEnter: ifAuth },
    { path: `/dashboard/:uuid`, name: `User_dashboard`, component: Dashboard, beforeEnter: ifAuth },
    { path: `/discovery`, name: `Discovery`, component: Discovery, beforeEnter: ifAuth },
    { path: `/campaign`, name: `Campaigns`, component: Campaign, beforeEnter: ifAuth },
    { path: `/campaign/new`, name: `Campaign_create`, component: Campaign_Create, beforeEnter: ifAuth },
    { path: `/campaign/:uuid`, name: `Campaign_view`, component: Campaign_View, beforeEnter: ifAuth },

    { path: `/upgrade`, name: `Upgrade`, component: Upgrade, beforeEnter: ifAuth },
    { path: `/messages`, name: `Messages`, component: Messages, beforeEnter: ifAuth },
    { path: `/settings`, name: `Settings`, component: settings, beforeEnter: ifAuth },
    { path: `/`, redirect: `/dashboard` },
    { path: `/404`, name: `404`, component: NotFound },
    { path: `*`, redirect: `/404` }
  ],
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});
