const state = {
  open: false,
  message: ``,
  title: ``,
  icon: ``,
  timeOut: ``,
  preloader: false
};
const getters = {
  isNotificationOpen (state) {
    return state.open;
  },
  getNotificationTitle (state) {
    return state.title;
  },
  getNotificationMessage (state) {
    return state.message;
  },
  getNotificationIcon (state) {
    return state.icon;
  },
  getNotificationTime (state) {
    return state.time;
  },
  getPreloaderState (state) {
    return state.preloader;
  }
};
const mutations = {
  showNotification (state) {
    state.open = true;
  },
  setNotificationMessage (state, message) {
    state.message = message;
  },
  setNotificationIcon (state, icon) {
    switch (true) {
    case icon === `warning`:
      state.icon = require(`../../assets/images/theme/alert.svg`);
    }
  },
  setNotificationTitle (state, title) {
    state.title = title;
  },
  setNotificationTimeOut (state, time) {
    setTimeout(() => {
      state.open = false;
      state.title = ``;
      state.message = ``;
      state.icon = ``;
      state.timeOut = ``;
    }, time);
  },
  setPreloader (state, data) {
    state.preloader = data;
  }
};
const actions = {
  showNotification ({ state, commit }, payload) {
    if (state.timeOut) {
      clearTimeout(state.timeOut);
    }
    commit(`setNotificationTitle`, payload.title);
    commit(`setNotificationMessage`, payload.message);
    commit(`setNotificationIcon`, payload.icon);
    commit(`showNotification`);
    commit(`setNotificationTimeOut`, payload.time || 2000);
  },
  closeNotification ({ state, commit }) {
    if (state.timeOut) {
      clearTimeout(state.timeOut);
    }
    commit(`setNotificationTimeOut`, 0);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
