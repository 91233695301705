import {
  statistics,
  audit,
  video
} from '~/src/mocks/dashboard.js';
import influencers from '~/src/mocks/influencers.js';

const state = {
  data: {
  },
  statistics: {
    followers: [],
    avg_views: [],
    avg_likes: [],
    avg_comments: [],
    avg_shares: [],
    stat: []
  },
  videos: [],
  audit: {
    rating: 9.6,
    performanceScore: 7.5,
    engagement: {
      current: 0,
      average: 0
    },
    genders: {
      average: 0,
      male: 0,
      female: 0
    },
    audience: {
      score: 0,
      history: []
    },
    videosFreq: {
      score: 0,
      current: 0,
      average: 0,
      history: []
    },
    inact: {
      labels: [],
      dataSets: [],
      dataTypes: []
    },
    strengthAreas: [],
    challenges: {
      labels: [],
      dataSets: [[]]
    },
    viral: {
      score: 0,
      labels: [],
      dataSets: []
    },
    likes: {
      score: 0,
      labels: [],
      dataSets: [[]]
    },
    ages: [],
    postsPerTime: []
  }
};
const getters = {
  getCommonLineStat(state) {
    const output = {
      labels: [`Followers`],
      dates: [],
      dataSet: [[]]
    };
    state.statistics.followers.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getFollowers(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.statistics.followers.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getAvgViews(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.statistics.avg_views.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getAvgLikes(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.statistics.avg_likes.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getAvgComments(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.statistics.avg_comments.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getAvgShares(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.statistics.avg_shares.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getAudienceGrowth(state) {
    const output = {
      dates: [],
      dataSet: [[]]
    };
    state.audit.audience.history.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  },
  getVideoFreq(state) {
    const output = {
      ...state.audit.videosFreq,
      dates: [],
      dataSet: [[]]
    };
    state.audit.videosFreq.history.forEach(item => {
      output.dates.push(item.date);
      output.dataSet[0].push(item.value);
    });
    return output;
  }
};
const mutations = {
  SET_DATA(state, obj) {
    state.data = obj;
  },
  SET_STAT_DATA(state, obj) {
    state.statistics = obj;
  },
  SET_VIDEO_DATA(state, arr) {
    state.videos = arr;
  },
  SET_AUDIT_DATA(state, obj) {
    state.audit = obj;
  }
};
const actions = {
  async fetch({commit, dispatch,state,rootState}, id) {
    const d = influencers.filter(item => parseInt(item.id)===parseInt(id));
    const [err,res] = [false, d && d.length ? d[0] : false];
    if(!err && res) {
      commit(`SET_DATA`, res);
    }
    return [err,res];
  },
  fetchStat({commit, dispatch, state, rootState}, {id, period}) {
    const [err,res] = [null, statistics[id][period]];
    if(!err && res) {
      commit(`SET_STAT_DATA`, res);
    } else {
      commit(`SET_STAT_DATA`, {
        // views: [],
        followers: [],
        avg_views: [],
        avg_likes: [],
        avg_comments: [],
        avg_shares: []
      });
    }
  },
  fetchAudit({commit, dispatch, state, rootState}, {id, period}) {
    if(!id) return;
    const [err,res] = [null, audit[id][period]];
    if(!err && res) {
      commit(`SET_AUDIT_DATA`, res);
    } else {
      commit(`SET_AUDIT_DATA`, {
        rating: 0,
        performanceScore: 0,
        engagement: {
          current: 0,
          average: 0
        },
        genders: {
          average: 0,
          male: 0,
          female: 0
        },
        audience: {
          score: 0,
          history: []
        },
        videosFreq: {
          score: 0,
          current: 0,
          average: 0,
          history: []
        },
        inact: {
          labels: [],
          dataSets: [],
          dataTypes: []
        },
        strengthAreas: [],
        challenges: {
          labels: [],
          dataSets: []
        },
        viral: {
          score: 0,
          labels: [],
          dataSets: []
        },
        likes: {
          score: 0,
          labels: [],
          dataSets: []
        },
        ages: []
      });
    }
  },
  fetchVideo({commit, dispatch, state, rootState}, {id, type}) {
    const [err,res] = [null, video[id][type]];
    if(!err && res) {
      commit(`SET_VIDEO_DATA`, res);
    } else {
      commit(`SET_VIDEO_DATA`, []);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

