<template>
  <div class="statisticBottle">
    <slot></slot>
    <div
      class="statisticBottle-overlay"
      :style="{ height: `${percentage}%` }"
    ></div>
  </div>
</template>
<script>
export default {
  name: `StatisticBottle`,
  components: {},
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  }
};
</script>
<style lang="scss" scoped>
.statisticBottle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-overlay {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(#ffc7d8, 0.4109);
  }
}
</style>
