import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import Vue2TouchEvents from 'vue2-touch-events';
// internationalization
import i18n from '~/src/plugins/i18n';
import 'normalize.css';
import '../src/assets/styles/main.scss';

import {
  Tabs,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';

Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents);

// tabs
Vue.component(`ElTabs`, Tabs);
Vue.component(`ElTabPane`, TabPane);
// dropdown
Vue.component(`ElDropdownMenu`, DropdownMenu);
Vue.component(`ElDropdown`, Dropdown);
Vue.component(`ElDropdownItem`, DropdownItem);
// select


const init = async () => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount(`#app`);
  await store.dispatch(`auth/checkToken`);

};
init();


