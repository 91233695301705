import influencers from '~/src/mocks/influencers.js';

const state = {
  data: [],
  hidden: []
};

const mutations = {
  SET_DATA(state, arr) {
    state.data = arr;
  },
  SET_HIDDEN_DATA(state, arr) {
    state.hidden = arr;
  }
};
const actions = {
  fetch({commit}) {
    const [err,res] = [false, influencers];
    commit(`SET_DATA`, res);
  },
  hiddenData({commit}) {
    const [err,res] = [false, influencers];
    commit(`SET_HIDDEN_DATA`, res);
  }
};
  
export default {
  state,
  mutations,
  actions,
  namespaced: true
};
  
