import { Doughnut, mixins } from 'vue-chartjs';
import merge from 'lodash.merge';
const { reactiveProp } = mixins;

const defaultOptions = {};

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    render() {
      this.renderChart(this.chartData, merge(this.options, defaultOptions));
    }
  },
  watch: {
    options () {
      this.render();
    }
  },
  mounted () {
    this.render();
  }
};
