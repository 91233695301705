<template>
  <span
    :class="classes"
    :style="{ backgroundColor: color }"
    @click="handleClick"
  >
    <img
      v-if="icon"
      :src="require(`~/src/assets/images/${icon}`)"
      class="el-tag-prefix"
    />
    <slot></slot>
    <img
      v-if="closable"
      :src="require(`~/src/assets/images/icons/${closableIcon}`)"
      class="el-tag-close"
      @click="handleClose"
    />
  </span>
</template>
<script>
export default {
  name: `CRTag`,
  props: {
    text: String,
    closable: Boolean,
    type: String,
    hit: Boolean,
    disableTransitions: Boolean,
    color: String,
    size: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: ``,
    },
    effect: {
      type: String,
      default: `light`,
      validator(val) {
        return [`dark`, `light`, `plain`].indexOf(val) !== -1;
      },
    },
  },
  computed: {
    closableIcon() {
      return this.type===`pink` ? `close-white.svg` : `close.svg`;
    },
    tagSize() {
      return this.size || (this.$ELEMENT || {}).size;
    },
    classes() {
      const {
        type,
        tagSize,
        hit,
        effect,
        square,
        transparent,
        disabled,
        active,
      } = this;
      return [
        `el-tag`,
        type ? `el-tag--${type}` : ``,
        tagSize ? `el-tag--${tagSize}` : ``,
        effect ? `el-tag--${effect}` : ``,
        hit && `is-hit`,
        square && `el-tag--square`,
        transparent && `el-tag--transparent`,
        disabled && `el-tag--disabled`,
        active && `el-tag--active`,
      ];
    },
  },
  methods: {
    handleClose(event) {
      event.stopPropagation();
      this.$emit(`close`, event);
    },
    handleClick(event) {
      this.$emit(`click`, event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~/src/assets/styles/variables";
.el-tag {
  transition: border-color 0.15s ease-in, opacity 0.15s ease-in;
  padding: 0px 20px;
  background-color: rgba($gray, 0.25);
  color: $black;
  border: 0px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
  & + .el-tag {
    margin-left: 10px;
  }
  &--pink {
    background-color: $pink;
    color: $white;
  }
  &--square {
    border-radius: 2px;
  }
  &--small {
    height: 22px;
    line-height: 22px;
  }
  &--success {
    background-color: rgba(32, 150, 83, 0.15);
    color: #68d5b5;
  }
  &--warning {
    background-color: #ffedc5;
    color: #f36500;
  }
  &-prefix {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 14px;
    width: 14px;
    line-height: 16px;
    vertical-align: middle;
    top: -1px;
    left: -5px;
    margin-right: 5px;
  }
  &-close {
    display: inline-block;
    text-align: center;
    position: relative;
    cursor: pointer;
    height: 6px;
    width: 6px;
    line-height: 16px;
    vertical-align: middle;
    top: -1px;
    right: -5px;
  }
  &--disabled {
    opacity: 0.5;
    cursor: initial;
  }
  &--transparent {
    background-color: transparent;
    border: 1px solid rgba($gray, 0.25);
  }
  &--active {
    &.el-tag--transparent {
      border: 1px solid $pink;
    }
  }
}
</style>
