<template>
  <div class="user-wrapper">
    <img class="user-ava" :src="src" alt="" />
    <span class="user-name">{{ name }}</span>
  </div>
</template>
<script>
export default {
  name: `AvatarTable`,
  props: {
    src: {
      type: String,
      default: ``,
    },
    name: {
      type: String,
      default: ``,
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  &-wrapper {
    display: flex;
    align-items: center;
    padding: 5px 0px;
  }
  &-ava {
    margin-right: 10px;
    width: 30px;
    border-radius: 50%;
  }
  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Muli-Regular, "Open Sans", sans-serif;
  }
}
</style>
