<template>
  <c-container>
    <transition mode="out-in" name="fade">
      <div
        v-if="$store.getters.getPreloaderState"
        id="loadingProgressG"
        :key="'preloader'"
      >
        <div class="loadingProgressG"></div>
      </div>
      <div v-else :key="'content'">
        <div class="user-statistic d-flex">
          <div class="avatar">
            <img :src="influencerInfo.ava" alt="" />
          </div>
          <div class="user-info">
            <div class="d-flex">
              <div class="user-name d-flex align-center">
                <span class="mr-10">{{ influencerInfo.name }}</span>
                <a
                  :href="'https://tiktok.com/@' + influencerInfo.username"
                  target="_blank"
                >
                  <img src="../../assets/images/theme/user_href.svg" alt="" />
                </a>
              </div>
              <!-- <div class="badge d-none mr-10">
                <img
                  class="mr-5"
                  src="../../assets/images/theme/fire.svg"
                  alt=""
                />
                <span>Быстрый рост</span>
              </div>
              <div class="badge d-none mr-10">
                <img
                  class="mr-5"
                  src="../../assets/images/theme/eye.svg"
                  alt=""
                />
                <span>Новый пользователь</span>
              </div>
              <div class="badge d-none">
                <img
                  class="mr-5"
                  src="../../assets/images/theme/spark.svg"
                  alt=""
                />
                <span>Рекомендации</span>
              </div> -->
            </div>
            <div class="nickname">
              @{{ influencerInfo.username }}
            </div>
            <div class="d-flex mt-10 mb-10">
              <div class="stat-cell">
                <div class="value">
                  {{ formatAmount(influencerInfo.followers, 1) }}
                </div>
                <div class="description">
                  {{ $t("common.followers") }}
                </div>
                <div class="divider"></div>
              </div>
              <!--               <div class="stat-cell">
                <div class="value">
                  {{ Number(user.following).toLocaleString() }}
                </div>
                <div class="description">
                  подписок
                </div>
                <div class="divider" />
              </div> -->
              <div class="stat-cell">
                <div class="value">
                  {{ formatAmount(influencerInfo.likes, 1) }}
                </div>
                <div class="description">
                  {{ $t("common.likes") }}
                </div>
                <div class="divider"></div>
              </div>
              <div class="stat-cell">
                <div class="value">
                  {{
                    (influencerInfo.location || "").slice(0, 2).toUpperCase()
                  }}
                </div>
                <div class="description d-flex align-center">
                  {{ $t("common.country") }}
                </div>
                <div class="divider"></div>
              </div>
              <div class="stat-cell">
                <div class="value">
                  {{ influencerInfo.er }}
                </div>
                <div class="description d-flex align-center">
                  {{ $t("tracking.er") }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <c-button
                v-if="isSubscribed"
                max-width="160px"
                font-weight="bold"
                icon-src="user-read/common_unsubscribe.svg"
                @click="unsubscribe"
              >
                {{ $t("tracking.unsubscribe") }}
              </c-button>
              <c-button
                v-else
                type="secondary"
                text-type="dark"
                max-width="160px"
                font-weight="bold"
                icon-src="theme/eye.svg"
                @click="subscribe"
              >
                {{ $t("tracking.subscribe") }}
              </c-button>
              <!--               <div class="button pink active mr-20" @click="removeUser">
                <img
                  class="mr-10"
                  src="../../assets/images/user-read/common_unsubscribe.svg"
                  alt=""
                />
                <span>{{ $t("tracking.unsubscribe") }}</span>
              </div> -->
              <div class="button secondary mr-20 d-none">
                <img
                  class="mr-10"
                  src="../../assets/images/theme/comparision.svg"
                  alt=""
                />
                <span>В сравнение</span>
              </div>
              <div class="button secondary small mr-20 d-none">
                <img src="../../assets/images/theme/empty_heart.svg" alt="" />
                <img
                  class="d-none"
                  src="../../assets/images/theme/full_heart.svg"
                  alt=""
                />
              </div>
              <div class="button secondary d-none">
                <img
                  class="mr-10"
                  src="../../assets/images/theme/download.svg"
                  alt=""
                />
                <span>Скачать PDF</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="user-tabs">
            <c-tabs v-model="currentTab">
              <tab-pane :label="$t('tracking.statistics')" name="statistic">
                <stat-tab ref="statistic"></stat-tab>
              </tab-pane>
              <tab-pane :label="$t('tracking.audit')" name="audit">
                <audit-tab ref="audit"></audit-tab>
              </tab-pane>
              <tab-pane :label="$t('tracking.video')" name="videos">
                <videos-tab ref="videos"></videos-tab>
              </tab-pane>
            </c-tabs>
          </div>
        </div>
      </div>
    </transition>
  </c-container>
</template>

<script>
import { mapState } from 'vuex';
import CTabs from '~/src/components/base/tabs/tabs';
import { TabPane } from 'element-ui';
import CButton from '~/src/components/base/button';
import statTab from './tabs/statistics';
import videosTab from './tabs/videos';
import auditTab from './tabs/audit';
import CContainer from '~/src/components/base/container';
import formatAmount from '~/src/utils/formatAmount';

export default {
  name: `Dashboasrd`,
  components: {
    CContainer,
    statTab,
    CTabs,
    TabPane,
    CButton,
    videosTab,
    auditTab,
  },
  data() {
    return {
      currentTab: `statistic`,
    };
  },
  computed: mapState({
    statsData: (state) => state.tracking.statsData.history,
    mintData: (state) => state.tracking.mintData,
    influencerInfo: (state) => state.dashboard.data,
    user: (state) => state.user,
    isSubscribed: function (state) {
      return state.tracking.data.filter(
        (item) => parseInt(item.id) === parseInt(this.influencerInfo.id)
      ).length;
    },
  }),
  watch: {
    $route: function () {
      this.$store.dispatch(`dashboard/fetch`, this.$route.params.uuid);
      this.$emit(`update:throwRouteName`, this.influencerInfo.username);
    },
    currentTab() {
      this.$refs[this.currentTab].fetchData();
    },
  },
  async created() {
    this.$store.commit(`notification/setPreloader`, true);
    if (!this.$route.params.uuid) {
      return this.$router.push({
        name: `User_dashboard`,
        params: { uuid: 6830197954 },
      });
    } else {
      await this.$store.dispatch(`dashboard/fetch`, this.$route.params.uuid);
    }
    this.$emit(`update:throwRouteName`, this.influencerInfo.username);
    //this.$store.commit(`notification/setPreloader`, false);
  },
  methods: {
    formatAmount: formatAmount,
    async subscribe() {
      await this.$store.dispatch(`tracking/subscribe`, this.influencerInfo.id);
    },
    async unsubscribe() {
      await this.$store.dispatch(
        `tracking/unsubscribe`,
        this.influencerInfo.id
      );
    },
  },
};
</script>
